import React, { Fragment } from "react";
import { Document } from "@react-pdf/renderer";
import Page1 from "./pages/page-1.component";
import Page2 from "./pages/page-2.component";
import Page3 from "./pages/page-3.component";
// import Page4 from "./pages/page-4.component";

const PdfDocument = ({
  imgSrc,
  imgPie,
  results,
  summary,
  broker,
  claimant,
}) => {
  return (
    imgSrc !== null && (
      <Document>
        {summary !== undefined && (
          <Fragment>
            <Page1 broker={broker} claimant={claimant} />
            <Page2 broker={broker} claimant={claimant} summary={summary} />
            <Page3
              summary={summary}
              imgSrc={imgSrc}
              imgPie={imgPie}
              broker={broker}
              // claimant={claimant}
            />
            {/* <Page4 results={results} broker={broker} /> */}
          </Fragment>
        )}
      </Document>
    )
  );
};

export default PdfDocument;
