import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";

const TaxesAndFeesParagraph = ({ filingState, filingStatus, fees }) => {
  return (
    <Text
      style={[styles.fontm]}
    >{`During this time as market-based portfolios can incur additional costs, this analysis is also factoring in your state taxes in ${filingState} as well as your federal tax filing status of ${filingStatus}. Additionally, the analysis factors in a money management fee of ${fees}% annually, which is standard for most investments.`}</Text>
  );
};
export default TaxesAndFeesParagraph;
