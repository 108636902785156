import React, { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";

import QuoteDetailsView from "./quote-details-view.component"
import QuoteDetailsError from "./quote-details-error.component"
import { getSessionStorage, setSessionStorage } from "../../utilities/storage";

import {detailInitInputs} from "../../assets/data/details.data";
import {
  changeDetailsInput,  // Used when input is changed, for real time validation
  validateInputs, // Used on an add or save to validate the inputs
  clearAllInputs, // Used to clear all the inputs and enable them, only called if quote not loaded
  prepareDataForRedux, // Used on an add or save to prepare the data for redux
  disableAllInputs, // This either enables or disables all the inputs. 
  updateInputsToQuote,  // Used to set the inputs to the quote, used on init and cancel
} from "./quote-details.helpers";

import {
  addQuote,
  saveQuote,
  editQuote,
  newQuote,
  cancelQuote,
  saveAutomaticBenefits,
  setSavedQuoteLoaded,
  disableBenefitsInPdf,
  enableBenefitsInPdf
} from "../../redux/quote/quote.actions";

const QuoteDetails = ({
  quote,
  broker,
  claimant,
  addQuote,
  saveQuote,
  editQuote,
  newQuote,
  cancelQuote,
  saveAutomaticBenefits,
  setSavedQuoteLoaded,
  disableBenefitsInPdf,
  enableBenefitsInPdf,
}) => {
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [benefitMismatch,setBenefitMismatch]=useState(null)
  const [fieldInputs, setFieldInputs] = useState(detailInitInputs);
  const [isError, setIsError] = useState(false);
  const toast = useRef(null);
  const setStorageFieldInputs = (newState) => {
    clearAllInputs();
    setSessionStorage("ssProposal-details", newState);
    setFieldInputs(newState);
  };

  useEffect(() => {
    initializeState();
    return () => saveInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enableDisableAllInputs = async (areInputsDisabled) => {
    const res = await disableAllInputs(fieldInputs, areInputsDisabled);
    setStorageFieldInputs(res);
  };

  const saveInputs = () => {
    setSavedQuoteLoaded();
  };
  
  const initializeState = async () => {
    let dataStored
    if (!quote.isProposalLoaded && !quote.isSavedProposalLoaded) return;
    if ((!quote.isProposalLoaded&& quote.isSavedProposalLoaded) || (quote.isProposalLoaded && quote.isProposalEdit)) {
      dataStored = await getSessionStorage("ssProposal-details");
      if (!dataStored) return;
    } else
      dataStored = await updateInputsToQuote(prepareInputsToQuote(!quote.isProposalEdit));
    setStorageFieldInputs(dataStored);
  }   

  const prepareInputsToQuote=(disabled) => {
    return {
      quote: quote.proposal,
      isDisabled: disabled,
      brokers: broker.brokers,
      claimants: claimant.claimants,
    };
  }

  const validate = async () => { 
    const validation=await validateInputs(fieldInputs);
    if (!validation.isValid) {
      setIsError(true);
      setStorageFieldInputs(validation.result);
      return false;
    }
    setIsError(false);
    return true
  }

  const isBenefitInDetails=() => {    
      let unselectedBenefits=[]
      let key=1;
      for (const benefit of quote.proposal.benefits) {
        if (!fieldInputs.lifeCompany.value.includes(benefit.lifeCompany)) {
          unselectedBenefits.push({ id: key, val: benefit.lifeCompany });
          key++;
        }
      }
      if (unselectedBenefits.length>=1) {
        let life=fieldInputs.lifeCompany.value.map((val,lc)=>{return {id:lc,val:val}})
        setBenefitMismatch({lifeCompanies: life, benefits:unselectedBenefits })
        setShowErrorDialog(true);
        return false
      } else return true
  };
  
  const handleInputChange = (e) => {
    let newState = changeDetailsInput(fieldInputs, e.target);
    setStorageFieldInputs(newState);
  };

  const handleClear = async () => {
    let clearState = clearAllInputs();
    setStorageFieldInputs(clearState);
  };

  const handleCancel = async () => {
    await disableBenefitsInPdf();
    await cancelQuote();
    const dataFromLoad = await updateInputsToQuote(prepareInputsToQuote(true))
    setIsError(false);
    setStorageFieldInputs(dataFromLoad);
  };

  const handleEdit = async () => {
    await disableBenefitsInPdf();
    await editQuote();
    await enableDisableAllInputs(false)
    await enableBenefitsInPdf();
  };

  const handleNew = async () => {
    await disableBenefitsInPdf();
    await newQuote();
    await enableBenefitsInPdf();
    handleClear();
  };

  const handleAdd = async () => {
    await disableBenefitsInPdf();
    if (! await validate()) return
    const details = await prepareDataForRedux({ fieldInputs: fieldInputs }) 
    await addQuote(details);
    await enableDisableAllInputs(true)
    await enableBenefitsInPdf();
  
  };
  const handleSave = async () => {
    await disableBenefitsInPdf();
    if (! await validate()) return
    if (! await isBenefitInDetails()) return
    await finalSave()
  };
  const finalSave=async () => {
    const details = await prepareDataForRedux({
      fieldInputs: fieldInputs,
      id: quote.proposal._id,
    }); 
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: `The Proposal was saved`,
      life: 3000,
    });
    await saveQuote(details);
    await enableDisableAllInputs(true)
    await enableBenefitsInPdf();
  }

  const buttonHandlers = {
    handleClear,
    handleAdd,
    handleNew,
    handleEdit,
    handleCancel,
    handleSave,
  };
  
  const handleHide=()=> {
    setShowErrorDialog(false)
  }
  const handleErrorFix=async (e) => {
    if (e.target.innerText==='Cancel') {
      disableAllInputs(fieldInputs, false)
      setShowErrorDialog(false)
      return
    }
    if (e.target.innerText==='Proposal') {
      setShowErrorDialog(false)
      let addToLifeCompanies=benefitMismatch.benefits.map(ben=> {return ben.val})
      let additionalLC=[...new Set(addToLifeCompanies)]
      fieldInputs.lifeCompany.value=[...fieldInputs.lifeCompany.value,...additionalLC]
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: `The lifeCompany ${additionalLC.join()} was added to the proposal`,
        life: 3000,
      });
      setBenefitMismatch(null)
      await finalSave()
      return
    }
    setShowErrorDialog(false)
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: `The benefits were saved with the selected life company`,
      life: 3000,
    });
    //Need to fix this. Need to find out which benefits need to be changed. 
    // They have to be in the mismatch but need to send the whole benefit
    const benefitsToEd=benefitMismatch.benefits.filter(ben=>ben.lifeCompany !== e.target.innerText)
    let benefitsLC=[...new Set(benefitsToEd.map(ben=>{return ben.val}))]
    let benefitsToEdit = quote.proposal.benefits.filter((ben) => 
      benefitsLC.includes(ben.lifeCompany)
    );
    await saveAutomaticBenefits(benefitsToEdit, e.target.innerText,quote.proposal._id)
    setBenefitMismatch(null)
    await finalSave()
    return
  }
  
  return (
    <Fragment>
      <Toast ref={toast} />
      <div className='p-shadow-12 p-mt-4'>
        <Card title='Proposal Details'>
        <QuoteDetailsError 
          showErrorDialog={showErrorDialog} 
          handleHide={handleHide} 
          benefitMismatch={benefitMismatch}
          handleErrorFix={handleErrorFix} 
        />
        <QuoteDetailsView  
          quote={quote}
          broker={broker}
          claimant={claimant}
          fieldInputs={fieldInputs}
          isError={isError}
          handleInputChange={handleInputChange}
          buttonHandlers={buttonHandlers} 
        />         
        </Card>
      </div>
    </Fragment>
  );
};;
const mapStateToProps = (state) => ({
  claimant: state.claimant,
  broker: state.broker,
  quote: state.quote,
});
export default connect(mapStateToProps, {
  saveQuote,
  addQuote,
  editQuote,
  newQuote,
  cancelQuote,
  saveAutomaticBenefits,
  setSavedQuoteLoaded,
  disableBenefitsInPdf,
  enableBenefitsInPdf
})(QuoteDetails);
