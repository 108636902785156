import {
  benefitTypeOptions,
  lifeCompanies,
  modePeriodOptions,
} from "../../assets/data/products.data";

import Dates from "../../utilities/dates";

const findOptions = (param, product) => {
  let options = [];
  for (const [index, opt] of param.entries()) {
    if (opt) options.push(product[index]);
  }
  return options;
};

export const enableInputs = (field, params) => {
  if (field.name !== null) delete field.name;
  let res = { ...field };
  params.map(
    (param) =>
      (res = {
        ...res,
        [param]: { ...res[param], disabled: false },
      })
  );
  //res.endDate.disabled = true;
  return res;
};

export const changeInputValue = (field, param) => {
  return {
    ...field,
    [param.name]: {
      ...field[param.name],
      value: param.value,
    },
  };
};

export const guaranteedAdjustments = (field, param) => {
  let newField = { ...field };
  if (newField["benefitType"].value === "PC") {
    field["expectedBenefit"].value = param.value;
  }
  field["guaranteedBenefit"].value = param.value;
  return newField;
};

export const premiumAdjustments = (field, param) => {
  let newField = { ...field };
  if (newField["benefitType"].value === "IR") {
    field["guaranteedBenefit"].value = param.value;
  }
  field["annuityPremium"].value = param.value;
  return newField;
};

export const benefitAdjustments = (field, param) => {
  let newField = { ...field };
  if (
    newField["benefitType"].value === "GLS" ||
    newField["benefitType"].value === "ELS" ||
    newField["benefitType"].value === "UC"
  ) {
    field["guaranteedBenefit"].value = param.value;
    field["expectedBenefit"].value = param.value;
  }
  if (newField["benefitType"].value === "UC")
    field["annuityPremium"].value = param.value;

  field["benefitAmount"].value = param.value;
  return newField;
};

export const lifeCompanyAdjustments = (field, param) => {
  let newField = { ...field };
  let lifeCo = lifeCompanies.find((lc) => param.value === lc.value);
  let optionsBT = findOptions(lifeCo.benefitTypeOptions, benefitTypeOptions);
  let optionsMP = findOptions(lifeCo.modePeriodOptions, modePeriodOptions);
  newField["benefitTypeOptions"] = optionsBT;
  newField["modePeriodOptions"] = optionsMP;
  newField["lifeCompany"].value = param.value;
  newField["benefitType"].value = "";
  newField["modePeriod"].value = "";
  newField["startDate"].value = Dates.today();
  newField["startDate"].disabled = true;
  newField["termMonths"].value = 0;
  newField["termMonths"].disabled = true;
  newField["termYears"].value = 0;
  newField["termYears"].disabled = true;
  newField["colaPercent"].value = 0;
  newField["colaPercent"].disabled = true;
  const res = enableInputs(newField, [
    "benefitType",
    "benefitAmount",
    "modePeriod",
    "annuityPremium",
    "guaranteedBenefit",
    "expectedBenefit",
  ]);
  return res;
};

export const benefitTypeAdjustments = (field, param) => {
  let newState = {
    ...field,
    [param.name]: {
      ...field[param.name],
      value: param.value,
    },
  };
  if (param.value === "GLS" || param.value === "ELS" || param.value === "UC") {
    newState["modePeriodOptions"] = [
      {
        id: 1,
        value: "O",
        name: "O",
        period: "One Time Only",
      },
    ];
    newState["modePeriod"] = {
      value: "O",
      disabled: true,
      showError: false,
    };
    newState["termYears"].disabled = true;
    newState["termMonths"].disabled = true;
    newState["colaPercent"].disabled = true;
  } else {
    let lifeCo = lifeCompanies.find(
      (lc) => field.lifeCompany.value === lc.value
    );
    let optionsMP = findOptions(lifeCo.modePeriodOptions, modePeriodOptions);
    newState["modePeriodOptions"] = optionsMP;
    newState["modePeriod"] = { value: "", showError: false, disabled: false };
    let fields = benefitTypeOptions.find(
      (ben) => ben.value === param.value
    ).fields;
    newState["termYears"].disabled = !fields.years;
    newState["termMonths"].disabled = !fields.months;
    newState["colaPercent"].disabled = !fields.cola;
  }
  newState["startDate"].disabled = false;
  newState["endDate"].disabled = true;
  newState["guaranteedBenefit"].disabled = false;
  newState["expectedBenefit"].disabled = false;
  newState["annuityPremium"].disabled = false;
  if (param.value === "LO" || param.value === "TL") {
    newState["guaranteedBenefit"] = {
      value: 0,
      disabled: true,
      showError: false,
    };
  }
  if (param.value === "PC") {
    newState["expectedBenefit"] = {
      value: field.guaranteedBenefit.value,
      disabled: true,
      showError: false,
    };
  }
  if (param.value === "IR") {
    newState["guaranteedBenefit"] = {
      value: field.annuityPremium.value,
      disabled: true,
      showError: false,
    };
  }
  if (param.value === "GLS" || param.value === "GLS" || param.value === "UC") {
    newState["expectedBenefit"] = {
      value: field.benefitAmount.value,
      disabled: true,
      showError: false,
    };
    newState["guaranteedBenefit"] = {
      value: field.benefitAmount.value,
      disabled: true,
      showError: false,
    };
  }
  if (param.value === "UC") {
    newState["annuityPremium"] = {
      value: field.benefitAmount.value,
      disabled: true,
      showError: false,
    };
  }
  return newState;
};
export const modePeriodAdjustments = (field, param) => {
  let newState = {
    ...field,
    [param.name]: {
      ...field[param.name],
      value: param.value,
    },
  };
  newState.termYears.value = 0;
  newState.termMonths.value = 0;
  newState.termMonths.showError = false;
  newState.endDate.value = newState.startDate.value;
  return newState;
};

const timeIncrementFromMode = (val) => {
  if (val === "A") return 12;
  if (val === "S") return 6;
  if (val === "Q") return 3;
  if (val === "M") return 1;
  return 1; // May want to handle Weeks
};

const calculateEndDate = (field) => {
  let start = field.startDate.value;
  let months = field.termYears.value * 12 + field.termMonths.value;
  const timeIncrement = timeIncrementFromMode(field.modePeriod.value);
  if (months <= timeIncrement) return start;
  return Dates.getEndDateFromString(start, months, timeIncrement);
};

const calculateEndDateByWeek = (field) => {
  let start = field.startDate.value;
  let weeks = field.termYears.value;
  return Dates.getEndDateByWeekFromString(start, weeks);
};

export const startDateAdjustments = (field, param) => {
  let newState = { ...field };
  newState.startDate.value = param.value;
  if (
    !Dates.isStringADateFormat(param.value) ||
    !Dates.isStringAValidDate(param.value)
  ) {
    newState.startDate.showError = true;
    return newState;
  } else {
    newState.startDate.showError = false;
    if (field.modePeriod.value === "W") {
      let endDateByWeek = calculateEndDateByWeek(newState);
      newState.endDate.value = endDateByWeek;
      return newState;
    } else {
      let endDate = calculateEndDate(newState);
      newState.endDate.value = endDate;
      return newState;
    }
  }
};

export const termTimeAdjustments = (field, param) => {
  let newState = {
    ...field,
    [param.name]: {
      ...field[param.name],
      value: param.value,
    },
  };
  if (newState.startDate.showError === true) {
    newState.startDate = {
      value: Dates.today(),
      disabled: false,
      showError: false,
    };
  }
  if (field.modePeriod.value === "W") {
    let endDateByWeek = calculateEndDateByWeek(newState);
    newState.endDate.value = endDateByWeek;
    return newState;
  } else {
    const timeIncrement = timeIncrementFromMode(field.modePeriod.value);
    if (
      (param.name === "termMonths" && param.value % timeIncrement !== 0) ||
      (param.name === "termYears" &&
        newState.termMonths.value % timeIncrement !== 0)
    ) {
      newState.termMonths.showError = true;
      newState.endDate.value = newState.startDate.value;
      return newState;
    }
    newState.termMonths.showError = false;
    let endDate = calculateEndDate(newState);
    newState.endDate.value = endDate;
    return newState;
  }
};
