import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrency, addPercentSign } from "../../../../utilities/numbers";
import columns from "./analyzer-modal-columns";

const AnalyzerRunModal = ({ show, handleHide, run }) => {
  const bodyTemplate = (rowData, { field }) => {
    if (
      field === "gain" ||
      field === "bondRate" ||
      field === "taxPercent" ||
      field === "stateTaxPercent" ||
      field === "federalTaxPercent"
    )
      return addPercentSign(rowData[field]);
    if (field === "year") return rowData[field];

    return formatCurrency(rowData[field]);
  };

  const [selectedColumns, setSelectedColumns] = useState(
    columns.filter((col) => col.initial === true)
  );

  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={bodyTemplate}
      />
    );
  });
  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const header = (
    <div className='table-header'>
      <div style={{ textAlign: "left" }}>
        <MultiSelect
          value={selectedColumns}
          options={columns}
          optionLabel='header'
          onChange={onColumnToggle}
        />
      </div>
    </div>
  );

  return (
    run !== null && (
      <Dialog
        header={`Detailed Results for Run ${run.runNumber}`}
        visible={show}
        style={{ width: "80vw" }}
        onHide={handleHide}
        baseZIndex={1000}
      >
        <DataTable
          value={run.years}
          className='p-datatable-striped reduced-height-table'
          dataKey='id'
          header={header}
        >
          {columnComponents}
        </DataTable>
      </Dialog>
    )
  );
};

export default AnalyzerRunModal;
