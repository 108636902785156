import React, { useState } from "react";
import SubmitButton from "./submit-button";
import EmailInput from "./email-input";
import PasswordInput from "./password-input";
import Greeting from "./greeting";
import ShowErrors from "./show-errors";
import { handleApiRegister } from "./handle-api";
import { validateInputs } from "./validators";
import { FormContainer, TextButton } from "./login.styles";

const Register = ({ handleClick }) => {
  const [loading, setLoading] = useState(false);
  const [registerFail, setRegisterFail] = useState({
    isFail: false,
    message: "",
  });
  const [userIn, setUserIn] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const [isInputValid, setIsInputValid] = useState({
    isEmailValid: true,
    isPasswordValid: true,
    isPasswordConfirmValid: true,
  });

  const { email, password, passwordConfirm } = userIn;

  const handleRegister = async () => {
    const { emailValid, passwordValid, passwordConfirmValid } =
      validateInputs(userIn);
    setIsInputValid({
      isEmailValid: emailValid,
      isPasswordValid: passwordValid,
      isPasswordConfirmValid: passwordConfirmValid,
    });
    if (!emailValid || !passwordValid || !passwordConfirmValid) {
      return;
    }
    setLoading(true);
    const result = await handleApiRegister({ userIn: userIn });
    setLoading(false);

    if (result.bypass !== undefined)
      setRegisterFail({ isFail: true, message: result.bypass });
    else window.location.href = result.session.url;
  };

  const handleInputChange = (e) => {
    setRegisterFail({
      isFail: false,
      message: "",
    });
    setIsInputValid({
      isEmailValid: true,
      isPasswordValid: true,
      isPasswordConfirmValid: true,
    });
    setUserIn({ ...userIn, [e.target.name]: e.target.value });
  };

  return (
    <FormContainer>
      <div className='p-fluid' id='register-form'>
        <Greeting action='Register' message=' an Account' />
        <EmailInput
          email={email}
          handleChange={handleInputChange}
          isValid={isInputValid.isEmailValid}
        />
        <PasswordInput
          password={password}
          handleChange={handleInputChange}
          isValid={isInputValid.isPasswordValid}
          confirm={false}
        />
        <PasswordInput
          password={passwordConfirm}
          handleChange={handleInputChange}
          isValid={isInputValid.isPasswordConfirmValid}
          confirm={true}
        />
        <SubmitButton
          buttonText='Sign Up'
          loading={loading}
          handleSubmit={handleRegister}
        />
        <div className='k-text-center'>
          <TextButton
            onClick={() => {
              handleClick("login");
            }}
          >
            I already have an Account
          </TextButton>
          <ShowErrors
            emailError={!isInputValid.isEmailValid}
            passwordError={!isInputValid.isPasswordValid}
            passwordConfirmError={!isInputValid.isPasswordConfirmValid}
            registerFail={registerFail}
          />
        </div>
      </div>
    </FormContainer>
  );
};

export default Register;
