import toast from "../../../utilities/toast";
const utils = {
  initializeInputs: () => {
    return {
      startYear: 2023,
      isTaxable: false,
      noLoss: false,
      startBalance: 0.0,
      yearlyFees: 1.5,
      numberOfYears: null,
      numberOfRuns: 1000,
      percentBonds: 0.0,
      bondRate: 0.0,
      stateTax: null,
      taxFiling: "federalSingle",
      singleToMarried: false,
      yearMarried: 2023,
      gainValue: "sp500_adjusted",
    };
  },
  initializeInputDraw: () => {
    return {
      drawAmount: 0.0,
      drawCola: 0.0,
      drawStart: 2023,
      drawEnd: 2062,
    };
  },
  initializeInputIncome: () => {
    return {
      incomeAmount: 0.0,
      incomeStart: 2023,
      incomeEnd: 2062,
    };
  },
  initializeInputBlended: () => {
    return {
      blendedAmount: 0.0,
      blendedCola: 0.0,
      blendedStart: 2023,
      blendedEnd: 2062,
    };
  },
  isInputReady: (inputs) => {
    let isReady = false;
    if (
      inputs.numberOfYears !== null &&
      inputs.stateTax !== null &&
      inputs.stateTax !== undefined &&
      inputs.startBalance >= 0.01 &&
      inputs.yearMarried >= inputs.startYear &&
      inputs.yearMarried < inputs.startYear + inputs.numberOfYears
    )
      isReady = true;
    return isReady;
  },
  inputChange: (e, { inputs, inputDraw, inputIncome, inputBlended }) => {
    let returnInputs = {
      inputs: null,
      inputDraw: null,
      inputIncome: null,
      inputBlended: null,
    };
    if (e.target.name === "isTaxable") {
      returnInputs.inputs = { ...inputs, isTaxable: e.target.checked };
      return returnInputs;
    }
    if (e.target.name === "noLoss") {
      returnInputs.inputs = { ...inputs, noLoss: e.target.checked };
      return returnInputs;
    }
    returnInputs.inputs = { ...inputs, [e.target.name]: e.target.value };
    if (e.target.name === "startYear") {
      returnInputs.inputIncome = {
        ...inputIncome,
        incomeStart: e.target.value,
        incomeEnd: e.target.value + inputs.numberOfYears - 1,
      };
      returnInputs.inputDraw = {
        ...inputDraw,
        drawStart: e.target.value,
        drawEnd: e.target.value + inputs.numberOfYears - 1,
      };
      returnInputs.inputBlended = {
        ...inputBlended,
        blendedStart: e.target.value,
        blendedEnd: e.target.value + inputs.numberOfYears - 1,
      };
    }
    if (e.target.name === "numberOfYears") {
      returnInputs.inputIncome = {
        ...inputIncome,
        incomeEnd: inputs.startYear + e.target.value - 1,
      };
      returnInputs.inputDraw = {
        ...inputDraw,
        drawEnd: inputs.startYear + e.target.value - 1,
      };
      returnInputs.inputBlended = {
        ...inputBlended,
        blendedEnd: inputs.startYear + e.target.value - 1,
      };
    }
    return returnInputs;
  },
  checkDraw: (draw, startYear, numberOfYears) => {
    if (draw.drawAmount < 0.01)
      return { isValid: false, toastMessage: toast.drawAmountFailed };
    if (draw.drawStart > draw.drawEnd)
      return { isValid: false, toastMessage: toast.drawYearFailed };
    if (draw.drawStart < startYear)
      return { isValid: false, toastMessage: toast.drawStartEarlyFailed };
    if (draw.drawStart >= startYear + numberOfYears)
      return { isValid: false, toastMessage: toast.drawStartLateFailed };
    if (draw.drawEnd < startYear)
      return { isValid: false, toastMessage: toast.drawEndEarlyFailed };
    if (draw.drawEnd >= startYear + numberOfYears)
      return { isValid: false, toastMessage: toast.drawEndLateFailed };
    return { isValid: true };
  },
  checkIncome: (income, startYear, numberOfYears) => {
    if (income.incomeAmount < 0.01)
      return { isValid: false, toastMessage: toast.incomeAmountFailed };
    if (income.incomeStart > income.incomeEnd)
      return { isValid: false, toastMessage: toast.incomeYearFailed };
    if (income.incomeStart < startYear)
      return { isValid: false, toastMessage: toast.incomeStartEarlyFailed };
    if (income.incomeStart >= startYear + numberOfYears)
      return { isValid: false, toastMessage: toast.incomeStartLateFailed };
    if (income.incomeEnd < startYear)
      return { isValid: false, toastMessage: toast.incomeEndEarlyFailed };
    if (income.incomeEnd >= startYear + numberOfYears)
      return { isValid: false, toastMessage: toast.incomeEndLateFailed };
    return { isValid: true };
  },
  checkBlended: (blended, startYear, numberOfYears) => {
    if (blended.blendedAmount < 0.01)
      return { isValid: false, toastMessage: toast.incomeAmountFailed };
    if (blended.blendedStart > blended.blendedEnd)
      return { isValid: false, toastMessage: toast.incomeYearFailed };
    if (blended.blendedStart < startYear)
      return { isValid: false, toastMessage: toast.incomeStartEarlyFailed };
    if (blended.blendedStart >= startYear + numberOfYears)
      return { isValid: false, toastMessage: toast.incomeStartLateFailed };
    if (blended.blendedEnd < startYear)
      return { isValid: false, toastMessage: toast.incomeEndEarlyFailed };
    if (blended.blendedEnd >= startYear + numberOfYears)
      return { isValid: false, toastMessage: toast.incomeEndLateFailed };
    return { isValid: true };
  },
  checkDrawsArray: (draws, startYear, numberOfYears) => {
    let isValid = true;
    draws.map((draw) => {
      if (
        draw.drawStart < startYear ||
        draw.drawStart >= startYear + numberOfYears ||
        draw.drawEnd < startYear ||
        draw.drawEnd >= startYear + numberOfYears
      )
        isValid = false;
    });
    return isValid
      ? { isValid }
      : { isValid, toastMessage: toast.drawsNotValid };
  },
  checkIncomesArray: (incomes, startYear, numberOfYears) => {
    let isValid = true;
    incomes.map((income) => {
      if (
        income.incomeStart < startYear ||
        income.incomeStart >= startYear + numberOfYears ||
        income.incomeEnd < startYear ||
        income.incomeEnd >= startYear + numberOfYears
      )
        isValid = false;
    });
    return isValid
      ? { isValid }
      : { isValid, toastMessage: toast.incomesNotValid };
  },
  checkBlendedsArray: (blendeds, startYear, numberOfYears) => {
    let isValid = true;
    blendeds.map((income) => {
      if (
        income.blendedStart < startYear ||
        income.blendedStart >= startYear + numberOfYears ||
        income.blendedEnd < startYear ||
        income.blendedEnd >= startYear + numberOfYears
      )
        isValid = false;
    });
    return isValid
      ? { isValid }
      : { isValid, toastMessage: toast.incomesNotValid };
  },
  drawToAdd: (inputDraw, draws) => {
    const { drawStart, drawEnd, drawCola, drawAmount } = inputDraw;
    return {
      drawStart,
      drawEnd,
      drawCola,
      drawAmount,
      id: draws.length,
    };
  },
  incomeToAdd: (inputIncome, incomes) => {
    const { incomeStart, incomeEnd, incomeAmount } = inputIncome;
    return {
      incomeStart,
      incomeEnd,
      incomeAmount,
      id: incomes.length,
    };
  },
  blendedToAdd: (inputBlended, blendeds) => {
    const { blendedStart, blendedCola, blendedEnd, blendedAmount } =
      inputBlended;
    return {
      blendedStart,
      blendedEnd,
      blendedCola,
      blendedAmount,
      id: blendeds.length,
    };
  },
  initializeDraw: (startYear, numberOfYears) => {
    return {
      drawAmount: 0.0,
      drawCola: 0.0,
      drawStart: startYear,
      drawEnd: startYear + numberOfYears - 1,
    };
  },
  initializeIncome: (startYear, numberOfYears) => {
    return {
      incomeAmount: 0.0,
      incomeStart: startYear,
      incomeEnd: startYear + numberOfYears - 1,
    };
  },
  initializeBlended: (startYear, numberOfYears) => {
    return {
      blendedAmount: 0.0,
      blendedCola: 0.0,
      blendedStart: startYear,
      blendedEnd: startYear + numberOfYears - 1,
    };
  },
  deleteDraw: (draws, id) => {
    let tempDraws = [];
    let del = id;
    draws.forEach((draw) => {
      if (draw.id === del) return;
      if (draw.id > del) return tempDraws.push({ ...draw, id: draw.id - 1 });
      return tempDraws.push(draw);
    });
    return tempDraws;
  },
  deleteIncome: (incomes, id) => {
    let tempIncomes = [];
    let del = id;
    incomes.forEach((income) => {
      if (income.id === del) return;
      if (income.id > del)
        return tempIncomes.push({ ...income, id: income.id - 1 });
      return tempIncomes.push(income);
    });
    return tempIncomes;
  },
  deleteBlended: (blendeds, id) => {
    let tempIncomes = [];
    let del = id;
    blendeds.forEach((income) => {
      if (income.id === del) return;
      if (income.id > del)
        return tempIncomes.push({ ...income, id: income.id - 1 });
      return tempIncomes.push(income);
    });
    return tempIncomes;
  },
};

export default utils;
