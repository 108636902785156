import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";

const Macros = {
  SkipLine: () => {
    return <Text style={[styles.fontm]}> </Text>;
  },
  PrePlural: ({ cond }) => {
    return cond === true ? " " : " a ";
  },
  PostPlural: (cond, partialWord) => {
    return cond === true ? `${partialWord}ies` : `${partialWord}y`;
  },
  FirstName: ({ name }) => {
    return <Text style={[styles.fontm, styles.left]}>{name},</Text>;
  },
  ThankYou: () => {
    return (
      <Text style={[styles.fontm]}>
        Thank you for the opportunity to present this to you,
      </Text>
    );
  },
  BrokerSign: ({ name }) => {
    return <Text style={[styles.fontm]}>{name}</Text>;
  },
};

export default Macros;
