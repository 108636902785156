import React from "react";
import { Password } from "primereact/password";

const PasswordInput = ({ password, handleChange, isValid, confirm }) => {
  return (
    <div className='p-field k-mb-2r'>
      <span className='p-float-label'>
        <Password
          name={confirm ? "passwordConfirm" : "password"}
          value={password}
          onChange={handleChange}
          className={!isValid && "p-invalid"}
          feedback={false}
        />
        <label htmlFor='password'>
          {confirm ? "Confirm Password" : "Password"}
        </label>
      </span>
    </div>
  );
};

export default PasswordInput;
