import React, { Fragment } from "react";
import { InputText } from "primereact/inputtext";

const InputFieldText = ({
  handler,
  variables: { value, disabled, showError },
  constants: { label, medSize, id, textError, name },
}) => {
  return (
    <Fragment>
      <div className={`p-field p-col-4 p-md-${medSize}`}>
        <label htmlFor={id}>{label}</label>
        <InputText
          id={id}
          name={name}
          value={value}
          onChange={handler}
          disabled={disabled}
          aria-describedby={`${id}-help`}
          className={showError && "p-invalid p-d-block"}
        />
        {showError && (
          <small id={`${id}-help`} className='p-invalid p-d-block'>
            {textError}
          </small>
        )}
      </div>
    </Fragment>
  );
};

export default InputFieldText;
