import React, { Fragment, useEffect, useState } from "react";
import { Font, PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "./pdf-document.component";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
Font.registerHyphenationCallback((word) => [word]);

const PdfAnalyzer = ({
  plotUrl,
  pieUrl,
  summary,
  results,
  claimant,
  broker,
}) => {
  const [fn, setFn] = useState({ ready: false, name: "" });
  useEffect(() => {
    if (results.length > 0) initializeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);
  const initializeData = () => {
    let date = new Date().toLocaleDateString().replace(/[^a-z0-9]/gi, "_");
    let qn = `sa_${date}`;
    setFn({ ready: true, name: qn });
  };

  return (
    fn.ready === true && (
      <Fragment>
        <PDFViewer width='80%' height='900px'>
          <PdfDocument
            results={results.slice(0, 100)}
            summary={summary}
            imgSrc={plotUrl}
            imgPie={pieUrl}
            claimant={claimant}
            broker={broker}
          />
        </PDFViewer>
      </Fragment>
    )
  );
};

export default PdfAnalyzer;
