const calculateAge = (dob) => {
  let ageString = Date.now() - Date.parse(dob);
  let age = new Date(ageString);
  return Math.abs(age.getUTCFullYear() - 1970);
};
const isDOBBeforeDate = (dob, compareDate) => {
  return new Date(compareDate) > new Date(dob);
};

export const dbToRedux = (db) => {
  let res = { ...db };
  if (res["caseNumber"] === undefined) res["caseNumber"] = "";
  if (res["middleName"] === undefined) res["middleName"] = "";
  if (res["ageSelector"] === false) res["displayedDOB"] = res["birthDate"];
  else res["displayedDOB"] = "N/A";
  res["name"] = `${res.firstName} ${res.middleName} ${res.lastName}`;
  return res;
};

export const dbToReduxArray = (db) => {
  let res = [...db];
  for (let claim of res) {
    if (claim["caseNumber"] === undefined) claim["caseNumber"] = "";
    if (claim["middleName"] === undefined) claim["middleName"] = "";
    if (claim["ageSelector"] === false) {
      claim["displayedAge"] = calculateAge(claim["birthDate"]);
    } else if (isDOBBeforeDate(claim["birthDate"], "01/01/2020")) {
      claim["displayedAge"] = claim["age"];
    } else {
      claim["displayedAge"] = calculateAge(claim["birthDate"]) + claim["age"];
    }

    claim["name"] = `${claim.firstName} ${claim.middleName} ${claim.lastName}`;
  }
  return res;
};
