import React from "react";
import { ErrorArea, XSmallTextRedBold } from "./login.styles";

const ShowErrors = ({
  emailError,
  passwordError,
  passwordConfirmError,
  registerFail,
  message,
}) => {
  return (
    <ErrorArea>
      {emailError && (
        <XSmallTextRedBold>Please use a valid email address</XSmallTextRedBold>
      )}
      {passwordError && (
        <XSmallTextRedBold>
          Password must be at least 8 characters
        </XSmallTextRedBold>
      )}
      {passwordConfirmError && (
        <XSmallTextRedBold>Passwords do not match</XSmallTextRedBold>
      )}
      {registerFail !== undefined && registerFail.isFail === true && (
        <XSmallTextRedBold>{registerFail.message}</XSmallTextRedBold>
      )}
      {message !== undefined && (
        <XSmallTextRedBold>{message}</XSmallTextRedBold>
      )}
    </ErrorArea>
  );
};

export default ShowErrors;
