import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TabMenuNav from "../../components/tabmenu-nav/tabmenu-nav.component";
import Brokers from "../../components/brokers/brokers.component";
import Claimants from "../../components/claimants/claimants.component";
import SavedQuotes from "../../components/saved-quotes/saved-quotes.component";
import QuoteDesigner from "../../components/quote-designer/quote-designer.component";
import SettlementAnalyzer from "../../components/settlement-analyzer/settlement-analyzer";
import AdminPanel from "../../components/admin-panel/admin-panel.component";
import { CONTAINER_DIV } from "../../assets/styles/containers.syles";
import { logoutUser } from "../../redux/user/user.actions";
import { getFirms, unloadBroker } from "../../redux/broker/broker.actions";
import {
  getClaimants,
  unloadClaimant,
} from "../../redux/claimant/claimant.actions";
import { unloadQuote } from "../../redux/quote/quote.actions";
import { unloadAnalyzer } from "../../redux/analyzer/analyzer.actions";

const MyHomePage = ({
  user: { isLoggedIn, email, role },
  history,
  logoutUser,
  getFirms,
  getClaimants,
  unloadBroker,
  unloadClaimant,
  unloadQuote,
  unloadAnalyzer,
}) => {
  const [activeItem, setActiveItem] = useState(0);

  const userItems = [
    { id: 0, label: "Settlement Analyzer", icon: "pi pi-fw pi-chart-bar" },
    { id: 1, label: "Brokers", icon: "pi pi-fw pi-user-edit" },
    { id: 2, label: "Claimants", icon: "pi pi-fw pi-folder" },
    { id: 3, label: "Saved Proposals", icon: "pi pi-fw pi-ticket" },
    { id: 4, label: "Proposal Designer", icon: "pi pi-fw pi-ticket" },
    { id: 100, label: "Logout", icon: "pi pi-fw pi-sign-out" },
  ];
  const employeeItems = [
    { id: 0, label: "Settlement Analyzer", icon: "pi pi-fw pi-chart-bar" },
    { id: 1, label: "Brokers", icon: "pi pi-fw pi-user-edit" },
    { id: 2, label: "Claimants", icon: "pi pi-fw pi-folder" },
    { id: 3, label: "Saved Proposals", icon: "pi pi-fw pi-ticket" },
    { id: 4, label: "Proposal Designer", icon: "pi pi-fw pi-ticket" },
    { id: 100, label: "Logout", icon: "pi pi-fw pi-sign-out" },
  ];
  const adminItems = [
    { id: 0, label: "Settlement Analyzer", icon: "pi pi-fw pi-chart-bar" },
    { id: 1, label: "Brokers", icon: "pi pi-fw pi-user-edit" },
    { id: 2, label: "Claimants", icon: "pi pi-fw pi-folder" },
    { id: 3, label: "Saved Proposals", icon: "pi pi-fw pi-ticket" },
    { id: 4, label: "Proposal Designer", icon: "pi pi-fw pi-ticket" },
    { id: 5, label: "Admin Panel", icon: "pi pi-fw pi-user-plus" },
    { id: 100, label: "Logout", icon: "pi pi-fw pi-sign-out" },
  ];

  const logout = async () => {
    await logoutUser();
    await unloadBroker();
    await unloadClaimant();
    await unloadQuote();
    await unloadAnalyzer();
    history.push("/");
  };
  useEffect(() => {
    if (isLoggedIn) getUserItems();
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const getUserItems = async () => {
    await getFirms();
    await getClaimants();
  };

  useEffect(() => {
    if (activeItem === 100) logout();
    // eslint-disable-next-line
  }, [activeItem]);
  return (
    <CONTAINER_DIV>
      {isLoggedIn && (
        <Fragment>
          <div className='p-d-flex p-jc-center'>
            <h1>Welcome {email}</h1>
          </div>
        </Fragment>
      )}

      <TabMenuNav
        active={activeItem}
        setActive={setActiveItem}
        menuItems={
          role === "admin"
            ? adminItems
            : role === "employee"
            ? employeeItems
            : userItems
        }
      />
      {role === "admin" ? (
        <Fragment>
          {activeItem === 0 && <SettlementAnalyzer />}
          {activeItem === 1 && <Brokers />}
          {activeItem === 2 && <Claimants />}
          {activeItem === 3 && <SavedQuotes />}
          {activeItem === 4 && <QuoteDesigner />}
          {activeItem === 5 && <AdminPanel />}
        </Fragment>
      ) : role === "employee" ? (
        <Fragment>
          {activeItem === 0 && <SettlementAnalyzer />}
          {activeItem === 1 && <Brokers />}
          {activeItem === 2 && <Claimants />}
          {activeItem === 3 && <SavedQuotes />}
          {activeItem === 4 && <QuoteDesigner />}
        </Fragment>
      ) : (
        <Fragment>
          {activeItem === 0 && <SettlementAnalyzer />}
          {activeItem === 1 && <Brokers />}
          {activeItem === 2 && <Claimants />}
          {activeItem === 3 && <SavedQuotes />}
          {activeItem === 4 && <QuoteDesigner />}
        </Fragment>
      )}
    </CONTAINER_DIV>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    getFirms,
    getClaimants,
    unloadBroker,
    unloadClaimant,
    unloadQuote,
    unloadAnalyzer,
  })(MyHomePage)
);
