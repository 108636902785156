import React, { Fragment } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";

export const TableClaimant = ({
  claimant,
  globalFilter,
  expandedRows,
  handleRowToggle,
  rowExpansionTemplate,
  toast,
  setGlobalFilter,
  expandAll,
  collapseAll,
}) => {
  const header = (
    <div className='saved-proposals-table-header'>
      <div>
        <Button
          icon='pi pi-plus'
          label='Expand All'
          onClick={expandAll}
          className='p-mr-2'
        />
        <Button icon='pi pi-minus' label='Collapse All' onClick={collapseAll} />
      </div>
      <p className='font-title'>View Claimants/Proposals</p>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className='view-saved-proposals-table p-shadow-12 p-mt-4'>
        <DataTable
          value={claimant}
          dataKey='_id'
          className='p-datatable-striped'
          globalFilter={globalFilter}
          header={header}
          expandedRows={expandedRows}
          onRowToggle={handleRowToggle}
          rowExpansionTemplate={rowExpansionTemplate}
          paginator
          paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
          currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          // paginatorLeft={paginatorLeft}
          // paginatorRight={paginatorRight}
        >
          <Column expander style={{ width: "3em" }} />
          <Column
            field='lastName'
            header='Last Name'
            sortable={true}
            // style={{ width: "15%" }}
          ></Column>
          <Column
            field='name'
            header='Full Name'
            sortable={true}
            // style={{ width: "10%" }}
          ></Column>
          <Column
            field='caseNumber'
            header='Case Number'
            sortable={true}
            // style={{ width: "15%" }}
          ></Column>
        </DataTable>
      </div>
    </Fragment>
  );
};

export default TableClaimant;
