import React from 'react';
import {Text, View} from '@react-pdf/renderer';

import styles from '../quote-page.styles';

const P2TableHeader = ({ skipPremium }) => {
  return (
    <View style={styles.table_row}>
      <View style={styles.table_col1_header}></View>
      {skipPremium === false ? (
        <View style={styles.table_col_header}>
          <Text
            style={[
              styles.text10,
              styles.bold,
              styles.underline,
              styles.center,
            ]}
          >
            Annuity Premium
          </Text>
        </View>
      ) : (
        <View style={styles.table_col_header} />
      )}
      <View style={styles.table_col_header}>
        <Text
          style={[styles.text10, styles.bold, styles.underline, styles.center]}
        >
          Guaranteed Benefit
        </Text>
      </View>
      <View style={styles.table_col_header}>
        <Text
          style={[styles.text10, styles.bold, styles.underline, styles.center]}
        >
          Expected Benefit
        </Text>
      </View>
    </View>
  );
};

export default P2TableHeader;
