import React, { Fragment } from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";
import {
  formatCurrency,
  addPercentSign,
} from "../../../../../utilities/numbers";

const TextDraw = ({ draw, type, width }) => {
  return (
    <Fragment>
      <View style={[styles.flex_row_sb]}>
        <Text
          style={[
            styles.fontxs,
            styles.right,
            styles.mleft3,
            { width: width / 10 },
          ]}
        >
          {type}
        </Text>

        <Text
          style={[
            styles.fontxs,
            styles.right,
            styles.mleft3,
            { width: width / 3.4 },
          ]}
        >
          {type === "D" && formatCurrency(draw.drawAmount)}
          {type === "B" && formatCurrency(draw.incomeAmount)}
          {type === "BL" && formatCurrency(draw.blendedAmount)}
        </Text>
        <Text style={[styles.fontxs, styles.right, { width: width / 8 }]}>
          {type === "D" && addPercentSign(draw.drawCola)}
          {type === "B" && addPercentSign(0.0)}
          {type === "BL" && addPercentSign(draw.blendedCola)}
        </Text>
        <Text style={[styles.fontxs, styles.right, { width: width / 10 }]}>
          {type === "D" && draw.drawStart}
          {type === "B" && draw.incomeStart}
          {type === "BL" && draw.blendedStart}
        </Text>
        <Text
          style={[
            styles.fontxs,
            styles.mright3,
            styles.right,
            { width: width / 10 },
          ]}
        >
          {type === "D" && draw.drawEnd}
          {type === "B" && draw.incomeEnd}
          {type === "BL" && draw.blendedEnd}
        </Text>
      </View>
    </Fragment>
  );
};

export default TextDraw;
