import React, { Fragment } from "react";
import { InputNumber } from "primereact/inputnumber";

const InputFieldCurrency = ({
  handler,
  variables: { value, disabled, showError },
  constants: { label, medSize, id, textError, name },
}) => {
  return (
    <Fragment>
      <div className={`p-field p-col-4 p-md-${medSize}`}>
        <label htmlFor={id}>{label}</label>
        <InputNumber
          value={value}
          id={id}
          onValueChange={handler}
          mode='currency'
          currency='USD'
          locale='en-US'
          aria-describedby={`${id}-help`}
          disabled={disabled}
          name={name}
          placeholder='$0.00'
          //className={showError && "p-invalid p-d-block"}
        />
        {showError && (
          <small id={`${id}-help`} className='p-invalid p-d-block'>
            {textError}
          </small>
        )}
      </div>
    </Fragment>
  );
};

export default InputFieldCurrency;
