import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { TabMenu } from "primereact/tabmenu";
import ResultsTab from "./results-tab";
import { deleteRunResults } from "../../../redux/analyzer/analyzer.actions";

const Results = ({ analyzer, deleteRunResults }) => {
  const [active, setActive] = useState(0);
  const [tabs, setTabs] = useState(0);

  const handleDeleteRun = () => {
    let tempActive = active;
    setActive(0);
    deleteRunResults(tempActive);
  };
  useEffect(() => {
    if (analyzer.results !== undefined) {
      if (analyzer.results.length > tabs) {
        setActive(analyzer.results.length - 1);
      }
      setTabs(analyzer.results.length);
    }
  }, [analyzer, tabs]);

  return (
    analyzer.results.length > 0 && (
      <div className='p-shadow-12 p-my-4'>
        {/* <Card className='analyzer-results' title='Analyzer Results'> */}
        <Card className='analyzer-results'>
          <div className='k-results-tab'>
            <TabMenu
              model={analyzer.tabManager}
              activeIndex={active}
              onTabChange={(e) => {
                setActive(
                  analyzer.tabManager.findIndex((ind) => ind.id === e.value.id)
                );
              }}
            />

            <ResultsTab
              key={active}
              result={analyzer.results[active]}
              claimant={analyzer.selectedClaimant}
              broker={analyzer.selectedBroker}
              handleDeleteRun={handleDeleteRun}
            />
          </div>
        </Card>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  analyzer: state.analyzer,
});
export default connect(mapStateToProps, { deleteRunResults })(Results);
