import React, { Fragment } from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";
import TextDraw from "./text-draw.view";

const TextDraws = ({ draws, incomes, blendeds, width }) => {
  return (
    <Fragment>
      <View style={[styles.flex_row_sb, styles.mtop2, styles.borderTop]}>
        <Text
          style={[
            styles.fontxs,
            styles.mrow,
            styles.bold,
            styles.right,
            styles.mleft3,
            { width: width / 10 },
          ]}
        >
          Type{" "}
        </Text>
        <Text
          style={[
            styles.fontxs,
            styles.mrow,
            styles.bold,
            styles.right,
            styles.mleft3,
            { width: width / 3.4 },
          ]}
        >
          Amount{" "}
        </Text>
        <Text
          style={[
            styles.fontxs,
            styles.mrow,
            styles.bold,
            styles.right,
            { width: width / 8 },
          ]}
        >
          COLA%{" "}
        </Text>
        <Text
          style={[
            styles.fontxs,
            styles.mrow,
            styles.bold,
            styles.right,
            { width: width / 10 },
          ]}
        >
          Start
        </Text>
        <Text
          style={[
            styles.fontxs,
            styles.mrow,
            styles.bold,
            styles.right,
            styles.mright3,
            { width: width / 10 },
          ]}
        >
          End
        </Text>
      </View>
      {draws.length > 0 &&
        draws.map((draw) => (
          <TextDraw draw={draw} type='D' key={draw.id} width={width} />
        ))}
      {incomes.length > 0 &&
        incomes.map((draw) => (
          <TextDraw draw={draw} type='B' key={draw.id} width={width} />
        ))}
      {blendeds.length > 0 &&
        blendeds.map((draw) => (
          <TextDraw draw={draw} type='BL' key={draw.id} width={width} />
        ))}
    </Fragment>
  );
};

export default TextDraws;
