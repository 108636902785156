import OneRun from "./one-run";
import Tax from "./tax";
import {
  createAllDraws,
  createAllIncomes,
  createAllBlendeds,
  createYearlyAmounts,
} from "./misc";
import { federalSingle, federalMarried } from "../assets/data/taxes.data";
import stateTaxList from "../assets/data/stateTaxList.json";

export default class AllRuns {
  constructor(analyzerInputs) {
    this.analyzerInputs = analyzerInputs;
    this.inputs = analyzerInputs.inputs;
    this.draws = analyzerInputs.draws;
    this.incomes = analyzerInputs.incomes;
    this.blendeds = analyzerInputs.blendeds;
    this.inputs.settlement = analyzerInputs.inputs.startBalance;
    this.changeMarried = this.calculateChangeMarried();
    this.yearlyDraws = this.calculateYearlyDraws();
    this.yearlyIncomes = this.calculateYearlyIncomes();
    this.yearlyBlendeds = this.calculateYearlyBlendeds();
    if (this.inputs.isTaxable) this.calculateIsTaxableStart();
    this.startAllRuns();
  }

  calculateYearlyDraws() {
    const { numberOfYears, startYear } = this.inputs;
    const allDraws = createAllDraws(this.draws, startYear, numberOfYears);
    return createYearlyAmounts(allDraws, numberOfYears);
  }
  calculateYearlyIncomes() {
    const { numberOfYears, startYear } = this.inputs;
    const allIncomes = createAllIncomes(this.incomes, startYear, numberOfYears);
    return createYearlyAmounts(allIncomes, numberOfYears);
  }
  calculateYearlyBlendeds() {
    const { numberOfYears, startYear } = this.inputs;
    const allBlendeds = createAllBlendeds(
      this.blendeds,
      startYear,
      numberOfYears
    );
    return createYearlyAmounts(allBlendeds, numberOfYears);
  }
  calculateChangeMarried() {
    let changeMarried = 9999;
    if (this.inputs.taxFiling.endsWith("Single") && this.inputs.singleToMarried)
      changeMarried = this.inputs.yearMarried;
    return changeMarried;
  }
  calculateIsTaxableStart() {
    // Tax Federal and state tax out of each draw if Taxable is selected and tax initial startBalance
    let drawFederalTax =
      this.inputs.taxFiling.endsWith("Single") &&
      this.changeMarried > this.startYear
        ? federalSingle
        : federalMarried;

    let drawStateTax =
      this.inputs.taxFiling.endsWith("Single") &&
      this.changeMarried > this.startYear
        ? stateTaxList.find((s) => this.inputs.stateTax === s.state)["Single"]
        : stateTaxList.find((s) => this.inputs.stateTax === s.state)["Married"];

    let fedTax = new Tax(this.inputs.startBalance, drawFederalTax);
    let stateTax = new Tax(this.inputs.startBalance, drawStateTax);
    this.inputs.startBalance =
      this.inputs.startBalance - fedTax.tax - stateTax.tax;
    for (let i = 0; i < this.inputs.numberOfYears; i++) {
      if (this.changeMarried === this.inputs.startYear + i) {
        drawFederalTax = federalMarried;
        drawStateTax = stateTaxList.find(
          (s) => this.inputs.stateTax === s.state
        )["Married"];
      }
      let tempFederalTax = new Tax(this.yearlyDraws[i], drawFederalTax);
      let tempStateTax = new Tax(this.yearlyDraws[i], drawStateTax);
      this.yearlyDraws[i] =
        this.yearlyDraws[i] - tempFederalTax.tax - tempStateTax.tax;
    }
  }

  startAllRuns() {
    this.runs = [];
    for (
      let runNumber = 1;
      runNumber <= this.analyzerInputs.inputs.numberOfRuns;
      runNumber++
    ) {
      let oneRun = new OneRun({
        inputs: this.inputs,
        yearlyDraws: this.yearlyDraws,
        yearlyIncomes: this.yearlyIncomes,
        yearlyBlendeds: this.yearlyBlendeds,
        changeMarried: this.changeMarried,
      });

      let totalShortfall = 0;
      let sortedBalance = oneRun.lastYearBalance;
      let totalTaxes = oneRun.years.reduce((sum, num) => {
        return sum + num.taxes;
      }, 0);

      if (oneRun.yearBalanceRanOut !== "") {
        let index = oneRun.yearBalanceRanOut - oneRun.startYear;
        if (index >= oneRun.yearlyDraws.length - 1) totalShortfall = 0;
        else {
          totalShortfall = oneRun.yearlyDraws
            .slice(index + 1)
            .reduce((sum, num) => {
              return sum + num;
            });
        }
        sortedBalance = -totalShortfall + oneRun.firstShortfall;
      }
      this.runs.push({
        ...oneRun,
        runNumber: runNumber,
        totalTaxes,
        totalShortfall,
        sortedBalance,
      });
    }
    this.results = {
      runResults: this.runs,
      allDraws: this.draws,
      allIncomes: this.incomes,
      allBlendeds: this.blendeds,
      filingType: this.inputs.taxFiling,
      filingState: this.inputs.stateTax,
      isTaxable: this.inputs.isTaxable,
      gainCalculationType: this.inputs.gainValue,
      yearMarried: this.changeMarried,
    };
  }
}
