import React, { Fragment } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import InputFieldTextarea from "../input-fields/input-field-textarea";
import HeaderColorPicker from "../header-color-picker/header-color-picker.component";

import { brokerInputs } from "../../assets/data/brokers.data";

const ColorPickerDisclaimer = ({
  fieldInputs,
  handleInputChange,
  colors,
  handleColors,
  displayColors,
  setDisplayColors,
}) => {
  return (
    <Fragment>
      <div className='p-grid'>
        <div className='p-col-8'>
          <div className='p-fluid p-grid p-form-grid'>
            <InputFieldTextarea
              variables={fieldInputs.firmDisclaimer}
              constants={brokerInputs.firmDisclaimer}
              handler={handleInputChange}
            />
          </div>
        </div>
        <div className='p-col-4'>
          <div className=' p-grid p-form-grid'>
            <div className='p-field p-col-6'>
              <label>PDF Page 2 Header Color</label>
              <Button
                onClick={() => setDisplayColors(true)}
                label='Set Colors'
              />
              <Dialog
                header='PDF Header Color Designer'
                visible={displayColors}
                style={{ width: "50vw" }}
                onHide={() => setDisplayColors(false)}
              >
                <HeaderColorPicker colors={colors} handler={handleColors} />
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ColorPickerDisclaimer;
