import Dates from "../../utilities/dates";

const errorSet = (input, param, isError) => {
  return {
    ...input,
    [param.name]: {
      ...input[param.name],
      value: param.value,
      showError: isError,
    },
  };
};

class ClaimantUtilities {
  static changeInputValue = (inputs, param) => {
    return {
      ...inputs,
      [param.name]: {
        ...inputs[param.name],
        value: param.value,
      },
    };
  };
  static nameAdjustments = (input, param) => {
    let isError = false;
    if (param.value.length < 2) isError = true;
    return errorSet(input, param, isError);
  };

  static ratedAgeAdjustments = (input, param) => {
    let isError = false;
    if (param.value < input.age.value) isError = true;
    return errorSet(input, param, isError);
  };

  static ageAdjustments = (input, param) => {
    if (input.ratedAge.value < param.value)
      return {
        ...input,
        [param.name]: {
          ...input[param.name],
          value: param.value,
        },
        ratedAge: {
          ...input.ratedAge,
          value: param.value,
        },
      };
    return this.changeInputValue(input, param);
  };

  static selAdjustments = (input, param) => {
    return {
      ...input,
      ageSelector: { ...input.ageSelector, value: param.value },
      age: { ...input.age, disabled: !param.value },
      birthDate: { ...input.birthDate, disabled: param.value },
    };
  };

  static dobAdjustments = (input, param) => {
    let newState = { ...input };
    if (
      !Dates.isStringADateFormat(param.value) ||
      !Dates.isStringAValidDate(param.value)
    ) {
      newState.birthDate.showError = true;
      newState.birthDate.value = param.value;
      newState.age.value = 0;
      newState.ratedAge.value = 0;
      return newState;
    }
    let age = Dates.calculateAgeFromString(param.value);
    if (age < 0) {
      newState.birthDate.showError = true;
      newState.birthDate.value = param.value;
      newState.age.value = 0;
      newState.ratedAge.value = 0;
      return newState;
    } else {
      newState.birthDate.value = param.value;
      newState.age.value = age;
      newState.birthDate.showError = false;
    }
    if (input.ratedAge.value < age) newState.ratedAge.value = age;

    return newState;
  };
}

export default ClaimantUtilities;
