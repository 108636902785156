import React from "react";
import NumberInput from "../../../../lib/number-input";
import CheckboxInput from "../../../../lib/checkbox-input";
import CurrencyInputSpecial from "../../../../lib/currency-input-special";
import DropdownInput from "../../../../lib/dropdown-input";
import { calculatorParams } from "../setup-params";
import DecimalInput from "../../../../lib/decimal-input";
import { stateList } from "../../../../assets/data/statelist.data";

const CalculatorInputsView = ({
  inputs,
  handler,
  isRateDisabled,
  isPercentDisabled,
  isBlendedOn,
}) => {
  return (
    <div className='p-fluid p-field p-grid'>
      <NumberInput
        constants={calculatorParams.startYear}
        value={inputs.startYear}
        handler={handler}
      />
      <NumberInput
        constants={calculatorParams.numberOfYears}
        value={inputs.numberOfYears}
        handler={handler}
      />
      <CheckboxInput
        constants={calculatorParams.isTaxable}
        value={inputs.isTaxable}
        handler={handler}
      />
      <CheckboxInput
        constants={calculatorParams.noLoss}
        value={inputs.noLoss}
        handler={handler}
      />
      <CurrencyInputSpecial
        constants={calculatorParams.startBalance}
        value={inputs.startBalance}
        condition={inputs.isTaxable}
        handler={handler}
      />
      <DropdownInput
        constants={calculatorParams.stateTax}
        options={stateList}
        value={inputs.stateTax}
        handler={handler}
      />
      <DecimalInput
        constants={calculatorParams.percentBonds}
        value={inputs.percentBonds}
        handler={handler}
        isDisabled={isPercentDisabled || isBlendedOn}
      />
      <DecimalInput
        constants={calculatorParams.bondRate}
        value={inputs.bondRate}
        handler={handler}
        isDisabled={isRateDisabled || isBlendedOn}
      />
      <NumberInput
        constants={calculatorParams.numberOfRuns}
        value={inputs.numberOfRuns}
        handler={handler}
      />
      <DecimalInput
        constants={calculatorParams.yearlyFees}
        value={inputs.yearlyFees}
        handler={handler}
      />
    </div>
  );
};

export default CalculatorInputsView;
