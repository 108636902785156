import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Splitter, SplitterPanel } from "primereact/splitter";

const QuoteDetailsError = ({
  showErrorDialog,
  handleHide,
  benefitMismatch,
  handleErrorFix,
}) => {
  return (
    benefitMismatch !== null && (
      <Dialog
        className='error-dialog'
        header='Benefits from unselected life companies'
        footer=''
        visible={showErrorDialog}
        style={{ width: "30vw" }}
        onHide={handleHide}
        baseZIndex={1000}
      >
        <Splitter layout='vertical'>
          <SplitterPanel className='p-m-4'>
            <div className='p-d-flex p-flex-column'>
              <span className='p-as-center p-mb-2'>
                Change the errors manually
              </span>
              <Button
                label='Cancel'
                className='p-button-warning'
                onClick={handleErrorFix}
              />
            </div>
          </SplitterPanel>
          <SplitterPanel className='p-m-4'>
            <div className='p-d-flex p-flex-column'>
              <span className='p-as-center p-mb-2'>
                Add the life companies to the proposal
              </span>
              <Button
                label='Proposal'
                className='p-button-success'
                onClick={handleErrorFix}
              />
            </div>
          </SplitterPanel>

          {benefitMismatch !== null &&
            benefitMismatch.lifeCompanies.map((lc) => (
              <SplitterPanel key={lc.id} className='p-m-4'>
                <div className='p-d-flex p-flex-column'>
                  <span className='p-as-center p-mb-2'>
                    Change benefits to the life company
                  </span>
                  <Button
                    className='p-button-primary'
                    label={`${lc.val}`}
                    onClick={handleErrorFix}
                  />
                </div>
              </SplitterPanel>
            ))}
          <SplitterPanel />
        </Splitter>
      </Dialog>
    )
  );
};
export default QuoteDetailsError;
