import React, { Fragment, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AnalyzerRunModal from "./analyzer-run.modal";
import { createCSV } from "../../../../utilities/files";
import { decimal2 } from "../../../../utilities/numbers";

const AnalyzerTable = ({ results }) => {
  const [show, setShow] = useState(false);
  const [loadRun, setLoadRun] = useState(null);

  const actionButtons = (rowData) => {
    const handleView = async (e) => {
      setShow(true);
      setLoadRun(
        results.filter((run) => run.runNumber === rowData.runNumber)[0]
      );
    };

    const handleExport = async (e) => {
      let fn = `stock-analyzer-run-${rowData.runNumber}.csv`;
      let csvData = results.filter(
        (run) => run.runNumber === rowData.runNumber
      )[0].years;
      createCSV(csvData, fn);
    };

    return (
      <Fragment>
        <Button
          iconPos='left'
          icon='pi pi-eye'
          // label='View '
          // className='p-button-raised p-button-warning p-mr-2'
          className='p-button-rounded p-button-sm p-button-warning p-mr-2'
          onClick={handleView}
        />
        <Button
          iconPos='left'
          icon='pi pi-save'
          // label='Export '
          // className='p-button-raised p-button-success p-mr-2'
          className='p-button-rounded p-button-sm p-button-success p-mr-2'
          onClick={handleExport}
        />
      </Fragment>
    );
  };
  const balanceTemplate = (rowData, { field }) => {
    return rowData[field] === ""
      ? formatCurrency(rowData["sortedBalance"])
      : formatCurrency(rowData[field]);
  };
  const taxesTemplate = (rowData, { field }) => {
    return formatCurrency(rowData[field]);
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    results.length > 0 && (
      <Fragment>
        <AnalyzerRunModal
          run={loadRun}
          show={show}
          handleHide={() => setShow(false)}
        />
        <DataTable
          value={results}
          dataKey='runNumber'
          className='p-datatable-striped reduced-height-table'
          paginator
          paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
          currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
          rows={20}
          rowsPerPageOptions={[20, 50, 100]}
        >
          <Column
            field='runNumber'
            header='Run'
            style={{ width: "20%" }}
            sortable
          ></Column>
          <Column
            field='yearBalanceRanOut'
            header='Year Balance Ran Out'
            style={{ width: "20%" }}
            sortable
          ></Column>
          <Column
            field='lastYearBalance'
            body={balanceTemplate}
            header='Last Year Balance'
            style={{ width: "20%" }}
            sortable
          ></Column>
          <Column
            body={taxesTemplate}
            field='totalTaxes'
            header='Total Taxes'
            style={{ width: "20%" }}
            sortable
          ></Column>
          <Column body={actionButtons} style={{ width: "20%" }} />
        </DataTable>
      </Fragment>
    )
  );
};

export default AnalyzerTable;
