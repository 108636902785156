import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";
import Macros from "./macros";

const NotTaxableNotBlendedParagraph = ({
  startBalance,
  runs,
  pass,
  fail,
  isMoreThanOne,
}) => {
  return (
    <Text
      style={[styles.fontm]}
    >{`To summarize, with a starting balance of ${startBalance} and the analysis performed in a Monte Carlo fashion of ${runs} runs, it was determined that the likelihood that the market-based portfolio would out-perform the Structured Settlement ${Macros.PostPlural(
      isMoreThanOne,
      "annuit"
    )} was ${pass}. In other words, this means the tax-free and guaranteed Structured Settlement Annuity out-performed the market-based portfolio ${fail} of the time.`}</Text>
  );
};
export default NotTaxableNotBlendedParagraph;
