export const QuoteActionTypes = {
  LOAD_QUOTE_PASS: "LOAD_QUOTE_PASS",
  LOAD_QUOTE_FAIL: "LOAD_QUOTE_FAIL",
  DELETE_QUOTE_PASS: "DELETE_QUOTE_PASS",
  DELETE_QUOTE_FAIL: "DELETE_QUOTE_FAIL",
  GET_QUOTES_PASS: "GET_QUOTES_PASS",
  GET_QUOTES_FAIL: "GET_QUOTES_FAIL",
  ADD_QUOTE_PASS: "ADD_QUOTE_PASS",
  ADD_QUOTE_FAIL: "ADD_QUOTE_FAIL",
  COPY_QUOTE_PASS: "COPY_QUOTE_PASS",
  COPY_QUOTE_FAIL: "COPY_QUOTE_FAIL",
  SAVE_QUOTE_PASS: "SAVE_QUOTE_PASS",
  SAVE_QUOTE_FAIL: "SAVE_QUOTE_FAIL",
  SAVE_QUOTE_LOADED: "SAVE_QUOTE_LOADED",
  NEW_QUOTE_PASS: "NEW_QUOTE_PASS",
  CANCEL_QUOTE_PASS: "CANCEL_QUOTE_PASS",
  START_EDIT_QUOTE_PASS: "START_EDIT_QUOTE_PASS",
  START_EDIT_QUOTE_FAIL: "START_EDIT_QUOTE_FAIL",
  ADD_BENEFIT_PASS: "ADD_BENEFIT_PASS",
  ADD_BENEFIT_FAIL: "ADD_BENEFIT_FAIL",
  SAVE_AUTO_BENEFITS_PASS: "SAVE_AUTO_BENEFITS_PASS",
  SAVE_AUTO_BENEFITS_FAIL: "SAVE_AUTO_BENEFITS_FAIL",
  START_EDIT_BENEFIT_PASS: "START_EDIT_BENEFIT_PASS",
  START_EDIT_BENEFIT_FAIL: "START_EDIT_BENEFIT_FAIL",
  FINISH_EDIT_BENEFIT_PASS: "FINISH_EDIT_BENEFIT_PASS",
  FINISH_EDIT_BENEFIT_FAIL: "FINISH_EDIT_BENEFIT_FAIL",
  CANCEL_EDIT_BENEFIT_PASS: "CANCEL_EDIT_BENEFIT_PASS",
  CANCEL_EDIT_BENEFIT_FAIL: "CANCEL_EDIT_BENEFIT_FAIL",
  DELETE_BENEFIT_PASS: "DELETE_BENEFIT_PASS",
  DELETE_BENEFIT_FAIL: "DELETE_BENEFIT_FAIL",
  COPY_BENEFIT_PASS: "COPY_BENEFIT_PASS",
  COPY_BENEFIT_FAIL: "COPY_BENEFIT_FAIL",
  REORDER_BENEFITS_PASS: "REORDER_BENEFITS_PASS",
  REORDER_BENEFITS_FAIL: "REORDER_BENEFITS_FAIL",
  DISABLE_BENEFITS_PDF: "DISABLE_BENEFITS_PDF",
  ENABLE_BENEFITS_PDF: "ENABLE_BENEFITS_PDF",
  SET_PAGE_BREAK: "SET_PAGE_BREAK",
  SET_PAGE_BREAK_FAIL: "SET_PAGE_BREAK_FAIL",
  // CANCEL_EDIT_COMPANY: "CANCEL_EDIT_COMPANY",
  // FINISH_EDIT_COMPANY: "FINISH_EDIT_COMPANY",
  // DELETE_BROKER_COMPANY_PASS: "DELETE_BROKER_COMPANY_PASS",
  // VIEW_BROKER_COMPANIES_PASS: "VIEW_BROKER_COMPANIES_PASS",
  // ADD_BROKER_COMPANY_FAIL: "ADD_BROKER_COMPANY_FAIL",
  // EDIT_BROKER_COMPANY_FAIL: "EDIT_BROKER_COMPANY_FAIL",
  // DELETE_BROKER_COMPANY_FAIL: "DELETE_BROKER_COMPANY_FAIL",
  // VIEW_BROKER_COMPANIES_FAIL: "VIEW_BROKER_COMPANIES_FAIL",
  UNLOAD_QUOTE: "UNLOAD_QUOTE",
};
