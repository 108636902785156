import Dates from "../../utilities/dates";
export const claimantInputs = {
  firstName: {
    label: "First Name",
    name: "firstName",
    id: "first-name",
    medSize: 4,
    required: true,
    minimum: 2,
    maximum: 99999,
    textError: "A First Name must be at least 2 charactes",
    validate: "text",
  },
  middleName: {
    label: "Middle Name",
    name: "middleName",
    id: "middle-name",
    medSize: 4,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter a Middle Name",
    validate: "text",
  },
  lastName: {
    label: "Last Name",
    name: "lastName",
    id: "last-name",
    medSize: 4,
    required: true,
    minimum: 2,
    maximum: 99999,
    textError: "A Last Name must be at least 2 charactes",
    validate: "text",
  },
  caseNumber: {
    label: "Case Number",
    name: "caseNumber",
    id: "case-number",
    medSize: 4,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter a Case Number",
    validate: "text",
  },
  ageSelector: {
    labelTrue: "Age",
    labelFalse: "DOB",
    name: "ageSelector",
    id: "age-selector",
    medSize: 1,
  },
  birthDate: {
    label: "Date Of Birth",
    name: "birthDate",
    id: "birth-date",
    medSize: 2,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter a valid Birth Date",
    validate: "date",
  },
  age: {
    label: "Age",
    name: "age",
    id: "normal-age",
    medSize: 1,
    required: true,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter an Age",
    validate: "integer",
  },
  ratedAge: {
    label: "Rated Age",
    name: "ratedAge",
    id: "rated-age",
    medSize: 1,
    required: true,
    minimum: 0,
    maximum: 99999,
    textError: "Must be equal or greater than Age",
    validate: "integer",
  },
};
const inp = {
  value: "",
  showError: false,
  disabled: false,
};
const inpDate = {
  value: Dates.today(),
  showError: false,
  disabled: false,
};
const inpNumber = {
  value: 0,
  showError: false,
  disabled: false,
};
const inpNumberAge = {
  value: 0,
  showError: false,
  disabled: true,
};
const inpSwitch = {
  value: false,
  showError: false,
  disabled: false,
};
export const claimantInitInputs = {
  firstName: inp,
  middleName: inp,
  lastName: inp,
  caseNumber: inp,
  ageSelector: inpSwitch,
  birthDate: inpDate,
  age: inpNumberAge,
  ratedAge: inpNumber,
};

export const claimantAddButtons = {
  cancel: {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-raised p-button-warning",
  },
  save: {
    label: "Save Changes",
    icon: "pi pi-plus",
    className: "p-button-raised",
  },
  clear: {
    label: "Clear",
    icon: "pi pi-times",
    className: "p-button-raised p-button-warning",
  },
  add: {
    label: "Add Claimant",
    icon: "pi pi-plus",
    className: "p-button-raised",
  },
};

export const titles = {
  add: "Add Claimant",
  edit: "Edit Claimant",
  sessionStorage: "ssProposal-claimant",
};
