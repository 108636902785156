import React, { Fragment } from "react";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

const BrokerLogo = ({ isLogoUploaded, logo, onUpload, handleEditLogo }) => {
  return (
    <Fragment>
      <div className={`p-field p-col-4 p-md-12`}>
        {!isLogoUploaded ? (
          <Fragment>
            <label htmlFor={"logo-upload"}>{"Firm Logo"}</label>

            <FileUpload
              name='logo'
              id='logo-upload'
              // disabled={isLogoSelected}
              uploadHandler={onUpload}
              customUpload
              multiple={false}
              auto
              accept='image/*'
              maxFileSize={1000000}
              chooseLabel='Load Logo'
              emptyTemplate={
                <p className='p-m-0'>Drag and drop your logo here to upload.</p>
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <div className='center-100px'>
              <Button
                iconPos='left'
                icon='pi pi-trash'
                label='Delete '
                className='p-button-raised p-button-danger p-mr-2 p-mb-3'
                onClick={handleEditLogo}
              />
              <img
                src={`/img/logos/${logo}`}
                alt={"Logo of the broker"}
                className='logo-display'
                width='100px'
              />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default BrokerLogo;
