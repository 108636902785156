const analyzerPlotsOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.6,
  plugins: {
    legend: {
      labels: {
        // color: "#495057",
        color: "#000000",
      },
    },
  },
  scales: {
    x: {
      // ticks: {
      //   color: "#495057",
      // },
      grid: {
        display: true,
        drawTicks: true,
      },
    },
    y: {
      suggestedMin: 0,
      // ticks: {
      //   color: "#495057",
      // },
      // grid: {
      //   color: "#ebedef",
      // },
    },
  },
};

export default analyzerPlotsOptions;
