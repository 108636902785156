import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import NumberInput from "../../../../lib/number-input";
import CurrencyInput from "../../../../lib/currency-input";
import BlendedTable from "./blended-table";
import DecimalInput from "../../../../lib/decimal-input";
import { blendedsParams } from "../setup-params";

const BlendedIncome = ({ handlers, inputs, blendeds }) => {
  const { handleInputBlended, handleAddBlended, handleDeleteBlended } =
    handlers;

  return (
    <div className='p-shadow-12 br-9'>
      <Card className='stock-calculator'>
        <div className='p-fluid p-grid p-formgrid'>
          <CurrencyInput
            constants={blendedsParams.blendedAmount}
            value={inputs.blendedAmount}
            handler={handleInputBlended}
          />
          <DecimalInput
            constants={blendedsParams.blendedCola}
            value={inputs.blendedCola}
            handler={handleInputBlended}
          />
          <NumberInput
            constants={blendedsParams.blendedStart}
            value={inputs.blendedStart}
            handler={handleInputBlended}
          />
          <NumberInput
            constants={blendedsParams.blendedEnd}
            value={inputs.blendedEnd}
            handler={handleInputBlended}
          />
          <div className='p-field p-col-2'>
            <Button
              onClick={handleAddBlended}
              label='Blended'
              className='k-blended k-mt-27px'
            />
          </div>
        </div>
        <BlendedTable tableData={blendeds} handleDelete={handleDeleteBlended} />
      </Card>
    </div>
  );
};

export default BlendedIncome;
