import { AnalyzerActionTypes } from "./analyzer.types";

export const saveRunResults = (results) => async (dispatch) => {
  try {
    return dispatch({
      type: AnalyzerActionTypes.SAVE_RUN_SUCCESS,
      payload: results,
    });
  } catch (err) {
    return dispatch({
      type: AnalyzerActionTypes.SAVE_RUN_FAIL,
      payload: null,
    });
  }
};

export const deleteRunResults = (index) => async (dispatch) => {
  try {
    return dispatch({
      type: AnalyzerActionTypes.DELETE_RUN_SUCCESS,
      payload: index,
    });
  } catch (err) {
    return dispatch({
      type: AnalyzerActionTypes.DELETE_RUN_FAIL,
      payload: null,
    });
  }
};

export const saveSelectedBroker = (broker) => async (dispatch) => {
  return dispatch({
    type: AnalyzerActionTypes.SET_BROKER_PASS,
    payload: broker,
  });
};

export const saveSelectedClaimant = (claimant) => async (dispatch) => {
  return dispatch({
    type: AnalyzerActionTypes.SET_CLAIMANT_PASS,
    payload: claimant,
  });
};

export const unloadAnalyzer = () => async (dispatch) => {
  return dispatch({
    type: AnalyzerActionTypes.UNLOAD_ANALYZER,
  });
};
