import axios from "axios";
import { ClaimantActionTypes } from "./claimant.types";
import { dbToReduxArray } from "./claimant.action-helpers";

const claimantsURL='/api/v1/claimants/'

const config = { headers: { "Content-Type": "application/json" },withCredentials:true  };

export const getClaimants = () => async (dispatch) => {
  try {
    const res = await axios.get(claimantsURL, config);
    let dbClaims = res.data.data;
    let claims = dbToReduxArray(dbClaims);
    return dispatch({
      type: ClaimantActionTypes.GET_CLAIMANTS_PASS,
      payload: claims,
    });
  } catch (err) {
    return dispatch({
      type: ClaimantActionTypes.GET_CLAIMANTS_FAIL,
    });
  }
};

export const addClaimant = (claimant) => async (dispatch) => {
  try {
    const res = await axios.post(claimantsURL, claimant, config);
    let dbClaims = res.data.data;
    let claims = dbToReduxArray(dbClaims);
    return dispatch({
      type: ClaimantActionTypes.ADD_CLAIMANT_PASS,
      payload: claims,
    });
  } catch (err) {
    return dispatch({
      type: ClaimantActionTypes.ADD_CLAIMANT_FAIL,
    });
  }
};

export const saveEditClaimant = (claimant) => async (dispatch) => {
  try {
    const res = await axios.patch(claimantsURL, claimant, config);
    let dbClaims = res.data.data;
    let claims = dbToReduxArray(dbClaims);
    return dispatch({
      type: ClaimantActionTypes.FINISH_EDIT_CLAIMANT,
      payload: claims,
    });
  } catch (err) {
    return dispatch({
      type: ClaimantActionTypes.EDIT_CLAIMANT_FAIL,
    });
  }
};

export const editClaimant = (id) => async (dispatch) => {
  try {
    return dispatch({
      type: ClaimantActionTypes.START_EDIT_CLAIMANT,
      payload: id,
    });
  } catch (err) {
    return dispatch({
      type: ClaimantActionTypes.EDIT_CLAIMANT_FAIL,
    });
  }
};
export const cancelClaimant = () => async (dispatch) => {
  try {
    return dispatch({
      type: ClaimantActionTypes.CANCEL_EDIT_CLAIMANT,
    });
  } catch (err) {
    return dispatch({
      type: ClaimantActionTypes.EDIT_CLAIMANT_FAIL,
    });
  }
};
export const deleteClaimant = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${claimantsURL}${id}`, config);
    let dbClaims = res.data.data;
    let claims = dbToReduxArray(dbClaims);
    return dispatch({
      type: ClaimantActionTypes.DELETE_CLAIMANT_PASS,
      payload: claims,
    });
  } catch (err) {
    return dispatch({
      type: ClaimantActionTypes.DELETE_CLAIMANT_FAIL,
    });
  }
};
export const unloadClaimant = () => async (dispatch) => {
  return dispatch({
    type: ClaimantActionTypes.UNLOAD_CLAIMANT,
  });
};
