import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";

import styles from "../quote-page.styles";

const P1Header = ({ loc, broker }) => {
  return (
    <View style={loc} >
      <Image
        style={styles.logo}
        src={`/img/logos/${broker.logo}`}
        cache={false}
      />
      <Text style={[styles.text14, styles.right, styles.bold]}>
        {broker.firmName}
      </Text>
      <Text style={[styles.text14, styles.right]}>{broker.firmStreet}</Text>
      <Text style={[styles.text14, styles.right]}>{broker.firmCity}</Text>
      {broker.firmPhone !== "" && (
        <Text style={[styles.text14, styles.right]}>{broker.firmPhone}</Text>
      )}
      {broker.firmWebsite !== "" && (
        <Text style={[styles.text14, styles.right]}>{broker.firmWebsite}</Text>
      )}
    </View>
  );
};

export default P1Header;
