export const BrokerActionTypes = {
  GET_FIRMS_PASS: " GET_FIRMS_PASS",
  ADD_FIRM_PASS: "ADD_FIRM_PASS",
  DELETE_FIRM_PASS: "DELETE_FIRM_PASS",
  VIEW_FIRM_PASS: "VIEW_FIRM_PASS",
  START_EDIT_FIRM: "START_EDIT_FIRM",
  CANCEL_EDIT_FIRM: "CANCEL_EDIT_FIRM",
  FINISH_EDIT_FIRM: "FINISH_EDIT_FIRM",
  GET_FIRMS_FAIL: " GET_FIRMS_FAIL",
  ADD_FIRM_FAIL: "ADD_FIRM_FAIL",
  EDIT_FIRM_FAIL: "EDIT_FIRM_FAIL",
  DELETE_FIRM_FAIL: "DELETE_FIRM_FAIL",
  VIEW_FIRM_FAIL: "VIEW_FIRM_FAIL",
  UPLOAD_LOGO_PASS: "UPLOAD_LOGO_PASS",
  UPLOAD_LOGO_FAIL: "UPLOAD_LOGO_FAIL",
  DELETE_LOGO_PASS: "DELETE_LOGO_PASS",
  DELETE_LOGO_FAIL: "DELETE_LOGO_FAIL",
  UNLOAD_BROKER: "UNLOAD_BROKER",
};
