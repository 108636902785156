import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";

import InputFieldText from "../input-fields/input-field-text";
import InputFieldDate from "../input-fields/input-field-date";
import InputFieldInteger from "../input-fields/input-field-integer";
import InputFieldSwitch from "../input-fields/input-field-switch";
import AddEditButtons from "../button-fields/addEditButtons.component";
import { getSessionStorage, setSessionStorage } from "../../utilities/storage";

import {
  claimantInputs,
  claimantInitInputs,
  claimantAddButtons,
  titles,
} from "../../assets/data/claimants.data";
import {
  clearAllInputs,
  changeClaimantInput,
  prepareEditInputs,
  validateInputs,
  prepareDataForRedux,
} from "./claimants.helpers";
import {
  addClaimant,
  cancelClaimant,
  saveEditClaimant,
} from "../../redux/claimant/claimant.actions";

const AddClaimant = ({
  claimant,
  addClaimant,
  cancelClaimant,
  saveEditClaimant,
}) => {
  const toast = useRef(null);
  const [cardTitle, setCardTitle] = useState(titles.add);
  const [fieldInputs, setFieldInputs] = useState(claimantInitInputs);

  const setStorageFieldInputs = (newState) => {
    setSessionStorage(titles.sessionStorage, newState);
    setFieldInputs(newState);
  };

  useEffect(() => {
    initializeState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (claimant.isEdit) {
      setCardTitle(titles.edit);
      setEditClaimantInputs(claimant.editClaimant);
    } else {
      setCardTitle(titles.add);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimant.isEdit]);

  const showToast = (type) => {
    toast.current.show({
      severity: "error",
      summary: `The Claimant was not ${type}`,
      detail: "Please check that the inputs are valid",
      life: 3000,
    });
  };
  const initializeState = async () => {
    const dataFromStorage = await getSessionStorage(titles.sessionStorage);
    if (!dataFromStorage) return;
    setStorageFieldInputs(dataFromStorage);
  };
  const setEditClaimantInputs = async (editInfo) => {
    const res = await prepareEditInputs(editInfo);
    setStorageFieldInputs(res);
  };

  const handleInputChange = (e) => {
    let newState = changeClaimantInput(fieldInputs, e.target);
    setStorageFieldInputs(newState);
  };

  const handleClear = async () => {
    let clearState = clearAllInputs();
    setStorageFieldInputs(clearState);
  };

  const handleAdd = async () => {
    const { isValid, result } = await validateInputs(fieldInputs);
    if (!isValid) {
      showToast("created");
      setStorageFieldInputs(result);
      return;
    }
    const claim = await prepareDataForRedux({ fieldInputs: fieldInputs });
    await addClaimant(claim);
    handleClear();
  };

  const handleSave = async () => {
    const { isValid, result } = await validateInputs(fieldInputs);
    if (!isValid) {
      showToast("updated");
      setStorageFieldInputs(result);
      return;
    }
    const claim = await prepareDataForRedux({
      fieldInputs: fieldInputs,
      id: claimant.editClaimant._id,
    });
    if (claim["ageSelector"]) claim["birthDate"] = Date.now();
    await saveEditClaimant(claim);
    handleClear();
  };

  const handleCancel = async () => {
    await cancelClaimant();
    handleClear();
  };

  const buttonHandlers = {
    handleClear,
    handleAdd,
    handleCancel,
    handleSave,
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className='add-claimant-form p-shadow-12 p-mb-4'>
        <p className='font-title'>{cardTitle}</p>

        <div className='p-fluid p-grid p-formgrid'>
          <InputFieldText
            variables={fieldInputs.firstName}
            constants={claimantInputs.firstName}
            handler={handleInputChange}
          />
          <InputFieldText
            variables={fieldInputs.middleName}
            constants={claimantInputs.middleName}
            handler={handleInputChange}
          />
          <InputFieldText
            variables={fieldInputs.lastName}
            constants={claimantInputs.lastName}
            handler={handleInputChange}
          />
          <InputFieldText
            variables={fieldInputs.caseNumber}
            constants={claimantInputs.caseNumber}
            handler={handleInputChange}
          />
          <InputFieldSwitch
            variables={fieldInputs.ageSelector}
            constants={claimantInputs.ageSelector}
            handler={handleInputChange}
          />
          <InputFieldDate
            variables={fieldInputs.birthDate}
            constants={claimantInputs.birthDate}
            handler={handleInputChange}
          />
          <InputFieldInteger
            variables={fieldInputs.age}
            constants={claimantInputs.age}
            handler={handleInputChange}
          />
          <InputFieldInteger
            variables={fieldInputs.ratedAge}
            constants={claimantInputs.ratedAge}
            handler={handleInputChange}
          />
        </div>
        <div className='p-d-flex p-jc-evenly'>
          <AddEditButtons
            isEdit={claimant.isEdit}
            addButtons={claimantAddButtons}
            handlers={buttonHandlers}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  claimant: state.claimant,
});
export default connect(mapStateToProps, {
  addClaimant,
  cancelClaimant,
  saveEditClaimant,
})(AddClaimant);
