import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

import { Button } from "primereact/button";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";

import QuoteDetails from "../quote-details/quote-details.component";
import AddBenefit from "../add-benefit/add-benefit.component";
import ViewBenefits from "../view-benefits/view-benefits.component";
import BenefitsGraph from "../benefits-graph/benefits-graph.component";
import PdfDesigner from "../pdf-designer/pdf-designer.component";
import Unsubscribe from "../unsubscribe/unsubscribe.component";

const QuoteDesigner = ({ quote }) => {
  const [showQuoteDetails, setShowQuoteDetails] = useState(false);
  const [showViewBenefits, setShowViewBenefits] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [image3Url, setImage3Url] = useState(null);
  const [image4Url, setImage4Url] = useState(null);

  return (
    <Fragment>
      <Inplace active={showQuoteDetails} closable className='p-mb-4'>
        <InplaceDisplay>
          <Button>Proposal Details</Button>
        </InplaceDisplay>
        <InplaceContent>
          <QuoteDetails />
        </InplaceContent>
      </Inplace>

      <Inplace
        active={showViewBenefits}
        onToggle={(e) => setShowViewBenefits(e.value)}
        closable
        className='p-mb-4'
      >
        <InplaceDisplay>
          <Button>View Benefits</Button>
        </InplaceDisplay>
        <InplaceContent>
          <AddBenefit />
          <ViewBenefits />
          <BenefitsGraph
            setImage3Url={setImage3Url}
            setImage4Url={setImage4Url}
          />
        </InplaceContent>
      </Inplace>
      {showViewBenefits && (
        <Inplace active={showPDF} closable className='p-mb-4'>
          <InplaceDisplay>
            <Button>View PDF</Button>
          </InplaceDisplay>
          <InplaceContent>
            {" "}
            {image3Url !== null && image4Url !== null && (
              <PdfDesigner image3Url={image3Url} image4Url={image4Url} />
            )}
          </InplaceContent>
        </Inplace>
      )}
      {showViewBenefits ? <Unsubscribe /> : <Unsubscribe fixedBottom />}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  quote: state.quote,
});
export default connect(mapStateToProps, null)(QuoteDesigner);
