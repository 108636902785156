export default class TaxAdjustedHybrid {
  //this.income,sg,this.bondGain,ltcgSingle,federalSingle
  constructor(income, stockGain, bondGain, filingCap, filingFed) {
    this.income = income;
    this.stockGain = stockGain;
    this.bondGain = bondGain;
    this.filingCap = filingCap;
    this.filingFed = filingFed;
    this.taxBracketCap = this.findTaxBracket(
      0.5 * this.stockGain,
      this.filingCap
    );
    this.taxBracketFed1 = this.findTaxBracket(
      this.income + 0.5 * this.stockGain + this.bondGain,
      this.filingFed
    );
    this.taxBracketFed2 = this.findTaxBracket(this.income, this.filingFed);
    this.taxCap = this.findTax(
      this.taxBracketCap,
      0.5 * this.stockGain,
      this.filingCap
    );
    this.taxFed1 = this.findTax(
      this.taxBracketFed1,
      this.income + 0.5 * this.stockGain + this.bondGain,
      this.filingFed
    );
    this.taxFed2 = this.findTax(
      this.taxBracketFed2,
      this.income,
      this.filingFed
    );
    this.tax = this.taxCap + this.taxFed1 - this.taxFed2;
    this.taxPercent =
      this.stockGain + this.bondGain === 0
        ? 0
        : (100 * this.tax) / (this.stockGain + this.bondGain);
  }

  findTaxBracket(value, fil) {
    let max = fil.length - 1;
    while (max) {
      if (value >= fil[max].bracket) return max * 1;
      else max--;
    }
    return 0;
  }

  findTax(taxBracket, value, fil) {
    const { rate, bracket, accumulated } = fil[taxBracket];
    let taxBase = accumulated;
    let taxAdditional = rate * (value - bracket);
    return taxBase + taxAdditional;
  }

  findTaxPercent = () => {
    let taxPercent = (100 * this.tax) / (this.stockGain + this.bondGain);
    return taxPercent;
  };
}
