import React, { Fragment } from "react";

import { FormButton } from "./login.styles";

const SubmitButton = ({ buttonText, loading, handleSubmit }) => {
  return (
    <Fragment>
      <FormButton disabled={loading} onClick={handleSubmit}>
        {loading ? (
          <Fragment>
            <div>
              <span className='k-opacity-p5 pi pi-cog pi-spin k-mx-10px'></span>
            </div>
            <div>
              <span className='k-opacity-p5'>{buttonText}</span>
            </div>
            <div className='k-w-38px' />
          </Fragment>
        ) : (
          <Fragment>
            <div className='k-w-38px' />
            <div>
              <span>{buttonText}</span>
            </div>
            <div className='k-w-38px' />
          </Fragment>
        )}
      </FormButton>
    </Fragment>
  );
};

export default SubmitButton;
