export const createReduxItem = async ({ fieldInputs, id }) => {
  let output = {};
  if (id) output = { ...output, id: [id] };
  await Object.keys(fieldInputs).map(async (fieldInput) => {
    output[fieldInput] = fieldInputs[fieldInput].value;
  });
  return output;
};

export const prepareEditInputs = async (fieldInputs, editInfo) => {
  let res = { ...fieldInputs };
  let info = { ...editInfo };

  await Object.keys(info).map(async (fieldInput) => {
    if (fieldInput !== "id")
      res[fieldInput] = { ...res[fieldInput], value: info[fieldInput] };
  });
  return res;
};

const inputIsValid = async (input, field, base) => {
  if (field[input].value === null) return false;
  if (!base[input].required) return true;
  let len = field[input].value.length;
  let required = base[input].required;
  let min = base[input].minimum;
  let max = base[input].maximum;
  if (
    (required === true && (len < min || len > max || len === undefined)) ||
    (required === false && len > 0 && len < min)
  ) {
    return false;
  } else {
    return true;
  }
};

export const inputsAreValid = async (field, base) => {
  let valid = true;
  if (field.name !== null) delete field.name;
  if (field["logo"]) delete field.logo;
  if (field["colors"]) delete field.colors;
  if (field["__v"]) delete field["__v"];
  if (field["_id"]) delete field["_id"];
  if (field["logoAR"]) delete field["logoAR"];
  if (field["ar"]) delete field["ar"];
  let res = { ...field };
  await Object.keys(field).map(async (param) => {
    if ((await inputIsValid(param, field, base)) === false) {
      valid = false;
      res = {
        ...res,
        [param]: { ...res[param], showError: true },
      };
    } else {
      res = {
        ...res,
        [param]: { ...res[param], showError: false },
      };
    }
  });
  return { valid: valid, result: res };
};
