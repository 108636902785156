import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";
import { formatCurrency } from "../../../../../utilities/numbers";

const IsBlendedParagraph = ({ allBlendeds }) => {
  let blendedString = `Additionally, your SSA utilizes a Blended Income approach which combines both the market-based investment assumptions above and a Structured Settlement Annuity that pays ${formatCurrency(
    allBlendeds[0].blendedAmount
  )} annually starting in ${allBlendeds[0].blendedStart} until ${
    allBlendeds[0].blendedEnd
  }`;
  for (let i = 1; i < allBlendeds.length; i++) {
    blendedString += ` as well as ${formatCurrency(
      allBlendeds[i].blendedAmount
    )} annually starting in ${allBlendeds[i].blendedStart} until ${
      allBlendeds[i].blendedEnd
    }`;
  }
  blendedString +=
    ", or through life expectancy depending on your benefit chosen, to provide lasting income.";

  return <Text style={[styles.fontm]}>{blendedString}</Text>;
};

export default IsBlendedParagraph;
