import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";
import { formatCurrency } from "../../../../../utilities/numbers";

const IsDrawsParagraph = ({ allDraws }) => {
  let drawString = `This analysis also assumes an annual payout from the market-based portfolio of ${formatCurrency(
    allDraws[0].drawAmount
  )} starting in ${allDraws[0].drawStart} and running until ${
    allDraws[0].drawEnd
  }`;
  for (let i = 1; i < allDraws.length; i++) {
    drawString += ` as well as ${formatCurrency(
      allDraws[i].drawAmount
    )} annually starting in ${allDraws[i].drawStart} until ${
      allDraws[i].drawEnd
    }`;
  }

  return <Text style={[styles.fontm]}>{drawString}</Text>;
};

export default IsDrawsParagraph;
