import React, { useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const DrawsOverlay = ({ draws, incomes, blendeds }) => {
  const op = useRef(null);

  const priceTemplate = (rowData, { field }) => {
    return rowData[field].toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  const colaTemplate = (rowData, { field }) => {
    return `${rowData[field].toFixed(2)}%`;
  };

  return (
    <div className='p-d-flex p-jc-center'>
      <Button
        label='View Draws, Incomes and Blended'
        onClick={(e) => op.current.toggle(e)}
        aria-haspopup
        aria-controls='overlay_panel'
        className='bg-accent200'
      />
      <OverlayPanel
        ref={op}
        showCloseIcon
        id='overlay_panel'
        style={{ width: "600px" }}
      >
        {draws.length > 0 && (
          <>
            <DataTable
              value={draws}
              dataKey='id'
              className='p-datatable-sm reduced-height-table'
              id='variable-draws-table'
            >
              <Column
                body={priceTemplate}
                style={{ width: "20%" }}
                field='drawAmount'
                header='Draw'
              />
              <Column
                body={colaTemplate}
                style={{ width: "20%" }}
                field='drawCola'
                header='COLA%'
              />
              <Column
                style={{ width: "20%" }}
                field='drawStart'
                header='Start Year'
              />
              <Column
                style={{ width: "20%" }}
                field='drawEnd'
                header='End Year'
              ></Column>
            </DataTable>
            <div className='h-20px' />
          </>
        )}

        {incomes.length > 0 && (
          <>
            <DataTable
              value={incomes}
              dataKey='id'
              className='p-datatable-sm reduced-height-table'
              id='variable-incomes-table'
              // className='p-mt-5'
            >
              <Column
                body={priceTemplate}
                style={{ width: "20%" }}
                field='incomeAmount'
                header='Income'
              />
              <Column style={{ width: "20%" }} />
              <Column
                style={{ width: "20%" }}
                field='incomeStart'
                header='Start Year'
              />
              <Column
                style={{ width: "20%" }}
                field='incomeEnd'
                header='End Year'
              ></Column>
            </DataTable>
            <div className='h-20px' />
          </>
        )}

        {blendeds.length > 0 && (
          <DataTable
            value={blendeds}
            dataKey='id'
            className='p-datatable-sm reduced-height-table'
            id='variable-blendeds-table'
            // className='p-mt-5'
          >
            <Column
              body={priceTemplate}
              style={{ width: "20%" }}
              field='blendedAmount'
              header='Blended'
            />
            <Column
              body={colaTemplate}
              style={{ width: "20%" }}
              field='blendedCola'
              header='COLA%'
            />

            <Column
              style={{ width: "20%" }}
              field='blendedStart'
              header='Start Year'
            />
            <Column
              style={{ width: "20%" }}
              field='blendedEnd'
              header='End Year'
            ></Column>
          </DataTable>
        )}
      </OverlayPanel>
    </div>
  );
};

export default DrawsOverlay;
