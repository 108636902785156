import React, { Fragment } from "react";
import AnalyzerSummaryConditions from "./analyzer-summary-conditions.component";
import AnalyzerSummaryPassing from "./analyzer-summary-passing.component";
import AnalyzerSummaryFailing from "./analyzer-summary-failing.component";

const AnalyzerSummary = ({ summary: { conditions, passing, failing } }) => {
  return (
    <Fragment>
      <div className='p-grid p-text-center'>
        <div className='p-col-12'>
          <AnalyzerSummaryConditions conditions={conditions} />
        </div>
        <div className='p-col-6'>
          <AnalyzerSummaryPassing passing={passing} />
        </div>
        <div className='p-col-6'>
          <AnalyzerSummaryFailing failing={failing} />
        </div>
      </div>
    </Fragment>
  );
};

export default AnalyzerSummary;
