import { AnalyzerActionTypes } from "./analyzer.types";

const INITIAL_STATE = {
  results: [],
  tabManager: [],
  tabNextCount: 1,
  selectedBroker: null,
  selectedClaimant: null,
};

const analyzerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AnalyzerActionTypes.SAVE_RUN_SUCCESS:
      let newTab = {
        id: state.tabNextCount,
        label: `Run ${state.tabNextCount}`,
      };
      return {
        ...state,
        results: [...state.results, action.payload],
        tabManager: [...state.tabManager, newTab],
        tabNextCount: state.tabNextCount + 1,
      };
    case AnalyzerActionTypes.DELETE_RUN_SUCCESS:
      let index = action.payload;
      let indexMax = state.tabManager.length - 1;
      if (index === 0 && indexMax === 0)
        return { ...state, results: [], tabManager: [] };
      if (index === 0 && indexMax > 0)
        return {
          ...state,
          results: [...state.results.slice(1)],
          tabManager: [...state.tabManager.slice(1)],
        };
      if (index > 0 && index === indexMax)
        return {
          ...state,
          results: [...state.results.slice(0, -1)],
          tabManager: [...state.tabManager.slice(0, -1)],
        };
      if (index > 0 && index < indexMax)
        return {
          ...state,
          results: [
            ...state.results.slice(0, index),
            ...state.results.slice(index + 1),
          ],
          tabManager: [
            ...state.tabManager.slice(0, index),
            ...state.tabManager.slice(index + 1),
          ],
        };
      return {
        ...state,
      };
    case AnalyzerActionTypes.SET_BROKER_PASS:
      return {
        ...state,
        selectedBroker: action.payload,
      };
    case AnalyzerActionTypes.SET_CLAIMANT_PASS:
      return {
        ...state,
        selectedClaimant: action.payload,
      };
    case AnalyzerActionTypes.UNLOAD_ANALYZER:
      return {
        results: [],
        tabManager: [],
        tabNextCount: 1,
        selectedBroker: null,
        selectedClaimant: null,
      };
    default:
      return { ...state };
  }
  // case AnalyzerActionTypes.SAVE_SINGLE_SUCCESS:
  //   return {
  //     ...state,
  //     filingTypeSingle: true,
  //     resultsSingle: action.payload,
  //   };
  // case AnalyzerActionTypes.SAVE_MARRIED_SUCCESS:
  //   return {
  //     ...state,
  //     filingTypeMarried: true,
  //     resultsMarried: action.payload,
  //   };
  // case AnalyzerActionTypes.SAVE_TRUST_SUCCESS:
  //   return {
  //     ...state,
  //     filingTypeTrust: true,
  //     resultsTrust: action.payload,
  //   };
  // case AnalyzerActionTypes.SAVE_RUN_FAIL:
  //   return {
  //     ...state,
  //   };
  // case AnalyzerActionTypes.SAVE_ANNUITY_SUCCESS:
  //   let newAnnuityTab = {
  //     id: state.tabManager.length,
  //     label: `Run ${state.tabManager.length + 1}`,
  //   };
  //   return {
  //     ...state,
  //     results: [...state.results, action.payload],
  //     tabManager: [...state.tabManager, newAnnuityTab],
  //   };
};

export default analyzerReducer;
