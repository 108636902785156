import { benefitTypeOptions } from "../../assets/data/products.data";
import { decimal2 } from "../../utilities/numbers";
import { labelsArray, colorsArray } from "./colors-labels";
import BenefitUtilities from "./benefit-utilities";

const compileBenefitsData = (benefits, age) => {
  let { axis, orderedBenefits } = reformatInputs(benefits, age);
  //let axis = createAxis(age);
  let benefitsData = {};
  orderedBenefits.map((ben) => {
    let yearlyData = getYearlyData(ben, axis);
    let cumulativeData = getCumulativeData(yearlyData);
    benefitsData[getBenefitNameForPlot(benefitsData, ben.benefitType)] = {
      yearlyData,
      cumulativeData,
    };
  });

  return convertToStackedData(benefitsData, axis);
};
const reformatInputs = (benefits, age) => {
  let orderedBenefits = [];
  let startYear = parseInt(new Date().toLocaleDateString().split("/")[2]);
  let tempFinishYear = startYear + 82 - age;
  let shortFinishYear = startYear + 82 - age;

  ["LO", "LWC", "JS", "IR", "TL", "CR"].map((typ) => {
    let tempBen = benefits.filter((benefit) => benefit.benefitType === typ);
    if (tempBen.length >= 1) tempBen.map((t) => orderedBenefits.push(t));
  });
  let cnt = orderedBenefits.length;
  let benefitEnd = [];

  let gt82 = cnt >= 1 ? true : false;
  ["UC", "PC", "ELS", "GLS"].map((typ) => {
    let tempBen = benefits.filter((benefit) => benefit.benefitType === typ);
    if (tempBen.length >= 1) tempBen.map((t) => orderedBenefits.push(t));
  });
  if (gt82 === false) {
    orderedBenefits.slice(cnt).map((ben) => {
      if (ben.benefitType === "PC") {
        let temped = ben.endDate.split("/");
        let ed = temped.length === 3 ? Number(temped[2]) : 0;
        benefitEnd.push(ed);
      } else {
        let temped = ben.startDate.split("/");
        let sd = temped.length === 3 ? Number(temped[2]) : 0;
        benefitEnd.push(sd);
      }
    });
    shortFinishYear = Math.max(...benefitEnd);
  }

  let finishYear = gt82 ? tempFinishYear : shortFinishYear;

  let axis = { startYear, finishYear };

  return { axis, orderedBenefits };
};

const convertToStackedData = (benefitsData, axis) => {
  let stackedData = {};
  let cumData = {};
  stackedData["labels"] = getLabels(axis);
  cumData["labels"] = getLabels(axis);
  let datasets = [];
  let datasets2 = [];
  let i = 0;
  Object.keys(benefitsData).map((benefit) => {
    if (!benefit.startsWith("Upfront"))
      datasets.push(
        createDataset(benefit, benefitsData[benefit].yearlyData, i)
      );
    datasets2.push(
      createDataset(benefit, benefitsData[benefit].cumulativeData, i)
    );
    i++;
  });
  stackedData["datasets"] = datasets;
  cumData["datasets"] = datasets2;
  return { cumData, stackedData };
};

const getCumulativeData = (data) => {
  let cumData = [];
  cumData[0] = Number(data[0]);
  for (let i = 1; i < data.length; i++) {
    cumData[i] = cumData[i - 1] + Number(data[i]);
  }
  return cumData;
};

const getLabels = (str) => {
  const { startYear, finishYear } = str;
  let labels = [];
  for (let i = startYear; i <= finishYear; i++) {
    labels.push(`${i}`);
  }
  return labels;
};

const createDataset = (label, data, i) => {
  let [annuityType, index] = label.split("-");
  if (index > 3) index = 3;
  return {
    type: "bar",
    label: label,
    data: data,
    backgroundColor:
      colorsArray[labelsArray.findIndex((el) => el === annuityType)][index - 1],
  };
};

const getBenefitNameForPlot = (bd, bn) => {
  let benefitName = benefitTypeOptions.find((b) => b.value === bn).name;
  return `${benefitName}-${
    Object.keys(bd).filter((ben) => ben.startsWith(benefitName)).length + 1
  }`;
};

const getYearlyData = (ben, axis) => {
  let {
    startDate,
    endDate,
    benefitType,
    benefitAmount,
    modePeriod,
    colaPercent,
  } = ben;

  let [firstMonth, , firstYear] = BenefitUtilities.dateToNumbers(startDate);
  let [lastMonth, , lastYear] = BenefitUtilities.dateToNumbers(endDate);
  let { startYear, finishYear } = axis;
  let type = BenefitUtilities.getBenefitPlotType(benefitType);
  let firstPaymentSchedule = BenefitUtilities.getFirstPaymentVariables({
    fm: firstMonth,
    mp: modePeriod,
  });
  let middlePaymentSchedule = BenefitUtilities.getMiddlePaymentVariables({
    fm: firstMonth,
    mp: modePeriod,
  });
  let lastPaymentSchedule = BenefitUtilities.getLastPaymentVariables({
    lm: lastMonth,
    fm: firstMonth,
    mp: modePeriod,
  });

  let yearOffset = firstYear - startYear;
  let yearEndForType2 = lastYear - startYear;
  let cash = [];

  for (let i = 0; i <= finishYear - startYear; i++) {
    let yearInBenefit = i - yearOffset;
    // 1. Before the benefit is applied
    if (i < yearOffset) {
      cash[i] = 0;
      continue;
    }
    // 2. First year of benefit. This is a partial year. Payments = payments-preCola
    if (i === yearOffset) {
      if (type === 3) {
        cash[i] = decimal2(benefitAmount);
        continue;
      }
      cash[i] = decimal2(
        BenefitUtilities.getFirstYearOfBenefit({
          ba: benefitAmount,
          ps: firstPaymentSchedule,
        })
      );
      continue;
    }
    // 3. Remaining years for type1 and full years of benefits for type2.
    if (type === 1 || (type === 2 && i < yearEndForType2)) {
      cash[i] = decimal2(
        BenefitUtilities.getMiddleYearsOfBenefit({
          cp: colaPercent,
          ba: benefitAmount,
          ps: middlePaymentSchedule,
          yib: yearInBenefit,
        })
      );
      continue;
    }
    // 4. Last year for benefits of type2 (TL and PC)
    if (type === 2 && i === yearEndForType2) {
      cash[i] = decimal2(
        BenefitUtilities.getMiddleYearsOfBenefit({
          cp: colaPercent,
          ba: benefitAmount,
          ps: lastPaymentSchedule,
          yib: yearInBenefit,
        })
      );
      continue;
    }
    cash[i] = 0;
  }
  return cash;
};

export default compileBenefitsData;
