import styled from 'styled-components';

export const CONTAINER = styled.div`
  width: ${(props) => props.width && props.width};
  margin: 0 auto;
`;


export const CONTAINER_DIV = styled.div`
	margin: 0 auto;
	width: 100%;
	@media (min-width: 576px) {
		width: 576px;
	}

	@media (min-width: 768px) {
		width: 768px;
	}

	@media (min-width: 992px) {
		width: 992px;
	}

	@media (min-width: 1200px) {
		width: 1200px;
	}
`;
