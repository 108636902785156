import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import SubmitButton from "./submit-button";
import PasswordInput from "./password-input";
import Greeting from "./greeting";
import ShowErrors from "./show-errors";
import { handleApiChangePassword } from "./handle-api";
import { validatePassword, validatePasswordConfirm } from "./validators";
import { FormContainer, TextButton } from "./login.styles";

const ResetPasswordWithToken = ({ token, history }) => {
  const [loading, setLoading] = useState(false);
  const [finished, setfinished] = useState(false);
  const [changePasswordFail, setChangePasswordFail] = useState({
    isFail: false,
    message: "",
  });
  const [userIn, setUserIn] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [isInputValid, setIsInputValid] = useState({
    isEmailValid: true,
    isPasswordValid: true,
    isPasswordConfirmValid: true,
  });
  useEffect(() => {
    if (finished === true) history.push("/pwd");
  });

  const { password, passwordConfirm } = userIn;

  const handleChangePassword = async () => {
    try {
      const result = await handleApiChangePassword({
        userIn: userIn,
        token: token,
        setValid: setIsInputValid,
        setLoading: setLoading,
      });
      if (result.status === "success") setfinished(true);
    } catch (err) {
      setChangePasswordFail({
        isFail: true,
        message: "Your password was not changed.",
      });
    }
  };

  const handleInputChange = (e) => {
    let isPasswordValid = true;
    let isPasswordConfirmValid = true;
    if (e.target.name === "password") {
      isPasswordValid = validatePassword(e.target.value);
      isPasswordConfirmValid = validatePasswordConfirm(
        e.target.value,
        passwordConfirm
      );
    }
    if (e.target.name === "passwordConfirm") {
      isPasswordConfirmValid = validatePasswordConfirm(
        password,
        e.target.value
      );
      isPasswordValid = validatePassword(password);
    }
    setIsInputValid({
      ...isInputValid,
      isPasswordValid: isPasswordValid,
      isPasswordConfirmValid: isPasswordConfirmValid,
    });
    setChangePasswordFail({
      isFail: false,
      message: "",
    });

    setUserIn({ ...userIn, [e.target.name]: e.target.value });
  };

  return (
    <FormContainer>
      <div className='p-fluid' id='change-password-form'>
        <Greeting action='Change' message=' Account Password' />
        <PasswordInput
          password={password}
          handleChange={handleInputChange}
          isValid={isInputValid.isPasswordValid}
          confirm={false}
        />
        <PasswordInput
          password={passwordConfirm}
          handleChange={handleInputChange}
          isValid={isInputValid.isPasswordConfirmValid}
          confirm={true}
        />
        <SubmitButton
          buttonText='Change Password'
          loading={loading}
          handleSubmit={handleChangePassword}
        />
        <div className='k-text-center'>
          <TextButton
            onClick={() => {
              history.push("/");
            }}
          >
            Go to Login Page
          </TextButton>
          <ShowErrors
            passwordError={!isInputValid.isPasswordValid}
            passwordConfirmError={!isInputValid.isPasswordConfirmValid}
            registerFail={changePasswordFail}
          />
        </div>
      </div>
    </FormContainer>
  );
};

export default withRouter(ResetPasswordWithToken);
