import React from "react";
import InputFieldText from "../input-fields/input-field-text";

const AddBrokerColumn = ({
  fieldInputs,
  brokerInputs,
  handleInputChange,
  column,
}) => {
  return (
    <div className='p-col-4'>
      <div className='p-fluid p-grid p-form-grid'>
        <InputFieldText
          variables={
            column === 1 ? fieldInputs.firmName : fieldInputs.firmStreet
          }
          constants={
            column === 1 ? brokerInputs.firmName : brokerInputs.firmStreet
          }
          handler={handleInputChange}
        />
        <InputFieldText
          variables={
            column === 1 ? fieldInputs.brokerName : fieldInputs.firmCity
          }
          constants={
            column === 1 ? brokerInputs.brokerName : brokerInputs.firmCity
          }
          handler={handleInputChange}
        />
        <InputFieldText
          variables={
            column === 1 ? fieldInputs.brokerTitle : fieldInputs.firmPhone
          }
          constants={
            column === 1 ? brokerInputs.brokerTitle : brokerInputs.firmPhone
          }
          handler={handleInputChange}
        />
      </div>
    </div>
  );
};

export default AddBrokerColumn;
