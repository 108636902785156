class Sorter {
  constructor(data, query) {
    this.data = data;
    this.orderBy = query.orderBy;
    this.params = query.params;
    this.select = query.select;
    this.sortedData = this.sortData();
  }

  sortData() {
    this.allRuns=this.data;
    this.allRuns.sort((b, a) => a[this.params[0]] - b[this.params[0]]);
    this.sortedRuns=this.allRuns;
  }
  getLabels() {
    return this.data[0].years.map((year) => {
      return year.year.toString();
    });
  }
  getDataset(params) {
    let search;
    if (params.set === "Best") search = this.sortedRuns[0];
    if (params.set === "Median")
      search = this.sortedRuns[Math.floor(this.sortedRuns.length / 2)];
    if (params.set === "Worst")
      search = this.sortedRuns[Math.floor(this.sortedRuns.length-1)];
    let item = search["runNumber"];
    let runHit = this.sortedRuns.find((run) => run.runNumber === item).years;
    let pd = [];
    //runHit.map(year=>pd.push(Math.round(year.lastYearBalance)*100/100))
    pd = runHit.map((year) => year.endBalance);
    return {
      label: `${params.set} Run ${item}`,
      data: pd,
      fill: false,
      borderColor: params.color,
      tension: 0.4,
    };
  }
}

export default Sorter;
