import React, { Fragment } from "react";

import { Page, View, Text, Image } from "@react-pdf/renderer";
import COVER from "../../../../../assets/images/black-line-background.jpg";
import styles from "../pdf-analyzer.styles";
import SALOGO from "../../../../../assets/images/thumb_SA.png";

const Page1 = ({ claimant, broker }) => {
  return (
    <Fragment>
      <Page size='letter' object-fit='fill'>
        <View wrap={true}>
          <Image src={COVER} style={[styles.pageImage]} />
        </View>
        <View>
          <Image
            style={styles.logoCover}
            src={`/img/logos/${broker.logo}`}
            cache={false}
          />
        </View>
        <View>
          <Image style={styles.logoCoverBottom} src={SALOGO} />
        </View>
        <View style={[styles.positionTitle]}>
          <Text style={[styles.fonttitle, styles.center, styles.bold]}>
            Structured
          </Text>
          <Text style={[styles.fonttitle, styles.center, styles.bold]}>
            Settlement
          </Text>
          <Text style={[styles.fonttitle, styles.center, styles.bold]}>
            Analysis
          </Text>
        </View>
        <View style={[styles.positionFor]}>
          <Text style={[styles.fontxl, styles.center]}>Prepared For:</Text>
          <Text style={[styles.fonttitle, styles.center, styles.bold]}>
            {claimant.name}
          </Text>
          {claimant.caseNumber !== "" && (
            <Text style={[styles.fontxl, styles.center]}>
              Case Number: {claimant.caseNumber}
            </Text>
          )}
          <Text style={[styles.fontxl, styles.center]}>
            {claimant.ageSelector === true
              ? `Age: ${claimant.age}`
              : `DOB: ${claimant.birthDate}`}
          </Text>
        </View>
        <View style={[styles.positionBy]}>
          <Text style={[styles.fontl, styles.right]}>Prepared By:</Text>
          <Text style={[styles.fontl, styles.right]}>{broker.firmName}</Text>
          <Text style={[styles.fontl, styles.right]}>{broker.brokerName}</Text>
          <Text style={[styles.fontl, styles.right]}>{broker.brokerTitle}</Text>
        </View>
      </Page>
    </Fragment>
  );
};

export default Page1;
