import React, { Fragment } from "react";

const AnalyzerSummaryField = ({ label, value }) => {
  return (
    <Fragment>
      {/* <div className={`p-col-${size} p-field marg-bot-quarter`}> */}
      <div className={`marg-bot-quarter`}>
        <p className='field-header'>{label}</p>
        {/* <h6 className='marg-half'>{label}</h6> */}

        <div className='marg-bot-quarter'>
          <p className='field-value'>{value}</p>
          {/* <span className='pad-1em'>{value}</span> */}
        </div>
      </div>
    </Fragment>
  );
};

export default AnalyzerSummaryField;
