import axios from "axios";
import { BrokerActionTypes } from "./broker.types";

// const logoURL='http://localhost:5010/api/v1/brokers/logo/'
// const firmsURL='http://localhost:5010/api/v1/brokers/'
const logoURL='/api/v1/brokers/logo/'
const firmsURL='/api/v1/brokers/'

const fileConfig = { headers: { "Content-Type": 'multipart/form-data' },withCredentials:true };
const config = { headers: { "Content-Type": "application/json" },withCredentials:true  };

export const uploadLogo = (id) => async (dispatch) => {
  try {
  const res = await axios.post(logoURL, id,fileConfig);
  const logoInfo = { logo: res.data.logo, logoAR: res.data.ar };
  return dispatch({
    type: BrokerActionTypes.UPLOAD_LOGO_PASS,
    payload: logoInfo,
  });
  } catch (err) {
    return dispatch({
      type: BrokerActionTypes.UPLOAD_LOGO_FAIL
    });
  }
}
export const deleteLogo = (id) => async (dispatch) => {
  try {
  await axios.delete(`${logoURL}${id}`,config);
  return dispatch({
    type: BrokerActionTypes.DELETE_LOGO_PASS,
  });
  } catch (err) {
    return dispatch({
      type: BrokerActionTypes.DELETE_LOGO_FAIL
    });
  }
}

export const getFirms=() => async (dispatch) => {
  try {
     const res = await axios.get(firmsURL, config);
     let firms=res.data.data
    //  firms.map(firm=> {
    //   firm['optionsLabel']=`${firm.firmName} - ${firm.brokerName}`  
    //   delete firm.__v
    //   delete firm.user
    // })
    for (let firm of firms) {
      firm['optionsLabel']=`${firm.firmName} - ${firm.brokerName}`  
      delete firm.__v
      delete firm.user
    }
     return dispatch({
       type: BrokerActionTypes.GET_FIRMS_PASS,
       payload: firms,
     });
     } catch (err) {
       return dispatch({
         type: BrokerActionTypes.GET_FIRMS_FAIL
       });
     }
}

export const addFirm = (broker) => async (dispatch) => {
  try {
    broker["name"]=broker.firmName
    const res=await axios.post(firmsURL, broker, config);
    let firms=res.data.data
    firms['optionsLabel']=`${firms.firmName} - ${firms.brokerName}`
    delete firms.__v
    delete firms.user
    return dispatch({
      type: BrokerActionTypes.ADD_FIRM_PASS,
      payload: firms,
    });
  } catch (err) {
    return dispatch({
      type: BrokerActionTypes.ADD_FIRM_FAIL,
    });
  }
};

export const saveEditFirm = (broker) => async (dispatch) => {
  try {
    broker["name"]=broker.firmName
    const res=await axios.patch(firmsURL, broker, config);
    let firm=res.data.data.broker
    firm['optionsLabel']=`${firm.firmName} - ${firm.brokerName}`
    delete firm.__v
    delete firm.user    
    return dispatch({
      type: BrokerActionTypes.FINISH_EDIT_FIRM,
      payload: firm,
    });
  } catch (err) {
    return dispatch({
      type: BrokerActionTypes.EDIT_FIRM_FAIL,
    });
  }
};

export const editFirm = (id) => async (dispatch) => {
  delete id['optionsLabel']
  //delete id['firm']
  return dispatch({
    type: BrokerActionTypes.START_EDIT_FIRM,
    payload: id,
  });
};
export const cancelFirm = () => async (dispatch) => {
  return dispatch({
    type: BrokerActionTypes.CANCEL_EDIT_FIRM,
  });
};

export const unloadBroker = () => async (dispatch) => {
  return dispatch({
    type: BrokerActionTypes.UNLOAD_BROKER,
  });
};

export const deleteFirm = (id) => async (dispatch) => {
  try {
    await axios.delete(`${firmsURL}${id}`, config);
     return dispatch({
       type: BrokerActionTypes.DELETE_FIRM_PASS,
       payload: id,
     });
  } catch (err) {
    return dispatch({
      type: BrokerActionTypes.DELETE_FIRM_FAIL,
    });
  }

  
};
