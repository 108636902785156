import React, { useState } from "react";
import Greeting from "./greeting";
import ForgotInstructions from "./forgot-instructions";
import ForgotPass from "./forgot-pass.component";
import ShowErrors from "./show-errors";
import SubmitButton from "./submit-button";
import EmailInput from "./email-input";
import { handleApiForgot } from "./handle-api";
import { FormContainer, TextButton } from "./login.styles";

const Forgot = ({ handleClick }) => {
  const [loading, setLoading] = useState(false);
  const [forgotFail, setForgotFail] = useState({
    isFail: false,
    message: "",
  });
  const [finished, setFinished] = useState(false);
  const [userIn, setUserIn] = useState({
    email: "",
  });
  const [isInputValid, setIsInputValid] = useState({
    isEmailValid: true,
    isPasswordValid: true,
    isPasswordConfirmValid: true,
  });

  const { email } = userIn;

  const handleForgot = async () => {
    const result = await handleApiForgot({
      userIn: userIn,
      setValid: setIsInputValid,
      setLoading: setLoading,
    });

    if (result.data === null || result.data.status.startsWith("Failed")) {
      setForgotFail({
        isFail: true,
        message: "Invalid email address please try again.",
      });
      return;
    }

    setFinished(true);
    return;
  };

  const handleInputChange = (e) => {
    setForgotFail({
      isFail: false,
      message: "",
    });
    setUserIn({ ...userIn, [e.target.name]: e.target.value });
  };

  return finished === true ? (
    <ForgotPass />
  ) : (
    <FormContainer>
      <div className='p-fluid' id='forgot-form'>
        <Greeting action='Forgot' message=' my Password' />
        <ForgotInstructions />
        <EmailInput
          email={email}
          handleChange={handleInputChange}
          isValid={isInputValid.isEmailValid}
        />
        <SubmitButton
          buttonText='Send Reset Instructions'
          loading={loading}
          handleSubmit={handleForgot}
        />
        <div className='k-text-center'>
          <TextButton
            onClick={() => {
              handleClick("login");
            }}
          >
            Back to Sign In
          </TextButton>
          <ShowErrors
            emailError={!isInputValid.isEmailValid}
            registerFail={forgotFail}
          />
        </div>
      </div>
    </FormContainer>
  );
};

export default Forgot;
