import React, { Fragment } from "react";
import { MultiSelect } from "primereact/multiselect";

const InputMultiselectField = ({
  handler,
  variables: { value, disabled, showError },
  constants: { label, medSize, id, textError, name },
  options,
}) => {
  return (
    <Fragment>
      <div className={`p-field p-col-4 p-md-${medSize}`}>
        <label>{label}</label>

        <MultiSelect
          name={name}
          id={id}
          value={value}
          options={options}
          onChange={handler}
          disabled={disabled}
          aria-describedby={`${id}-help`}
          // className={showError && "p-invalid p-d-block"}
        />
        {showError && (
          <small id={`${id}-help`} className='p-invalid p-d-block'>
            {textError}
          </small>
        )}
      </div>
    </Fragment>
  );
};

export default InputMultiselectField;
