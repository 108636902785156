import { ClaimantActionTypes } from "./claimant.types";

const INITIAL_STATE = {
  claimants: [],
  isEdit: false,
  editClaimant: null,
};

const claimantReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClaimantActionTypes.GET_CLAIMANTS_PASS:
      return {
        ...state,
        claimants: action.payload,
      };

    case ClaimantActionTypes.ADD_CLAIMANT_PASS:
      return {
        ...state,
        claimants: action.payload,
      };
    case ClaimantActionTypes.START_EDIT_CLAIMANT:
      return {
        ...state,
        isEdit: true,
        editClaimant: action.payload,
      };
    case ClaimantActionTypes.CANCEL_EDIT_CLAIMANT:
      return {
        ...state,
        isEdit: false,
        editClaimant: null,
      };
    case ClaimantActionTypes.FINISH_EDIT_CLAIMANT:
      return {
        ...state,
        isEdit: false,
        editClaimant: null,
        claimants: action.payload,
      };
    case ClaimantActionTypes.DELETE_CLAIMANT_PASS:
      return {
        ...state,
        claimants: action.payload,
      };
    case ClaimantActionTypes.UNLOAD_CLAIMANT:
      return {
        claimants: [],
        isEdit: false,
        editClaimant: null,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default claimantReducer;
