class BenefitUtilities {
  static dateToNumbers = (date) => {
    let dateArray = date.split("/");
    let month = Number(dateArray[0]);
    let day = Number(dateArray[1]);
    let year = Number(dateArray[2]);
    return [month, day, year];
  };
  static getBenefitPlotType = (bt) => {
    if (["LO", "LWC", "IR", "JS", "CR"].includes(bt)) return 1;
    else if (["PC", "TL"].includes(bt)) return 2;
    return 3;
  };

  static getFirstYearOfBenefit = ({ ba, ps }) => {
    return ba * ps.payments;
  };

  static getMiddleYearsOfBenefit = ({ cp, ba, ps, yib }) => {
    let pre = ba * ps.preCola * (1 + cp / 100) ** (yib - 1);
    let post = ba * (ps.payments - ps.preCola) * (1 + cp / 100) ** yib;
    return pre + post;
  };

  static getFirstPaymentVariables = ({ fm, mp }) => {
    // Weekly is wrong. I need to fix this
    switch (mp) {
      case "A":
        return { payments: 1 };
      case "S":
        return { payments: Math.floor((12 - fm) / 6) + 1 };
      case "Q":
        return { payments: Math.floor((12 - fm) / 3) + 1 };
      case "M":
        return { payments: 13 - fm };
      case "W":
      default:
        return { payments: 52 - 4 * fm };
    }
  };

  static getMiddlePaymentVariables = ({ fm, mp }) => {
    // Weekly is wrong. I need to fix this
    switch (mp) {
      case "A":
        return { preCola: 0, payments: 1 };
      case "S":
        return { preCola: Math.floor((fm - 1) / 6), payments: 2 };
      case "Q":
        return { preCola: Math.floor((fm - 1) / 3), payments: 4 };
      case "M":
        return { preCola: fm - 1, payments: 12 };
      case "W":
      default:
        return { preCola: (fm - 1) * 4, payments: 52 };
    }
  };

  static getLastPaymentVariables = ({ lm, fm, mp }) => {
    switch (mp) {
      case "A":
        return { preCola: 1, payments: 1 };
      case "S":
        return {
          preCola: Math.floor((fm - 1) / 6),
          payments: Math.floor((lm - 1) / 6) + 1,
        };
      case "Q":
        return {
          preCola: Math.floor((fm - 1) / 3),
          payments: Math.floor((lm - 1) / 3) + 1,
        };
      case "M":
        return { preCola: lm >= fm ? fm - 1 : lm, payments: lm };
      case "W":
      default:
        return { preCola: 52, payments: 52 };
    }
  };
}
export default BenefitUtilities;
