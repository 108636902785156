import React, { Fragment } from "react";
import { Page, View } from "@react-pdf/renderer";

import styles from "./quote-page.styles";

import P1Header from "./views/p1-header.view";
import P1Prepared from "./views/p1-prepared.view";
import P1Dates from "./views/p1-dates.view";
import P1Presented from "./views/p1-presented.view";

const QuotePage1 = ({ details }) => {
  return (
    <Page size='letter' object-fit='fill' style={styles.page}>
      <View style={[styles.section]} wrap={true}>
        {(details !== null) & (details.brokerName !== null) && (
          <Fragment>
            <P1Header broker={details.brokerName} loc={styles.header} />
            <P1Prepared
              loc={styles.prepared}
              claimant={details.claimantsName}
            />
            <P1Dates
              loc={styles.legal}
              quote={details.quoteDate}
              expiration={details.expirationDate}
              purchase={details.purchaseDate}
            />
            <P1Presented
              loc={styles.presented}
              name={details.brokerName.brokerName}
              title={details.brokerName.brokerTitle}
              life_companies={details.lifeCompany}
            />
          </Fragment>
        )}
      </View>
    </Page>
  );
};

export default QuotePage1;
