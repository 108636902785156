export const stackedOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.4,
  plugins: {
    title: {
      display: true,
      text: "Benefits Per Year",
      font: {
        size: 48,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    legend: {
      labels: {
        //color: "#495057",
        color: "#000000",
      },
      position: "bottom",
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        //color: "#495057",
        color: "#000000",
      },
      grid: {
        color: "#ebedef",
      },
      position: "bottom",
    },

    y: {
      stacked: true,
      ticks: {
        //color: "#495057",
        color: "#000000",
      },
      grid: {
        color: "#ebedef",
      },
    },
  },
};
export const cumulativeOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.4,
  plugins: {
    title: {
      display: true,
      text: "Cumulative Benefits Per Year",
      font: {
        size: 48,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    legend: {
      labels: {
        //color: "#495057",
        color: "#000000",
      },
      position: "bottom",
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        //color: "#495057",
        color: "#000000",
      },
      grid: {
        color: "#ebedef",
      },
      position: "bottom",
    },
    y: {
      stacked: true,
      ticks: {
        //color: "#495057",
        color: "#000000",
      },
      grid: {
        color: "#ebedef",
      },
    },
  },
};
