import React from "react";
import { View } from "@react-pdf/renderer";

import P2TableHeader from "./p2-table-header.view";
import P2TableRow from "./p2-table-row.view";
import P2TableTotal from "./p2-table-total.view";
import styles from "../quote-page.styles";

const P2Table = ({
  loc,
  benefits,
  skipHeader,
  skipTotal,
  total,
  skipPremium,
}) => {
  return (
    <View style={[loc, styles.table]}>
      {skipHeader === false && <P2TableHeader skipPremium={skipPremium} />}
      {benefits.map((benefit) => (
        <P2TableRow
          benefit={benefit}
          key={benefit._id}
          skipPremium={skipPremium}
        />
      ))}
      {skipTotal === false && (
        <P2TableTotal benefits={total} skipPremium={skipPremium} />
      )}
    </View>
  );
};

export default P2Table;
