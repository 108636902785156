import { BrokerActionTypes } from "./broker.types";

const INITIAL_STATE = {
  brokers: [],
  isEdit: false,
  editBroker: null,
  isLogoUploaded: false,
  logo: "",
  logoAR: 1,
};

const brokerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BrokerActionTypes.GET_FIRMS_PASS:
      return {
        ...state,
        brokers:action.payload
      };
    case BrokerActionTypes.ADD_FIRM_PASS:
      return {
        ...state,
        brokers: [action.payload, ...state.brokers],
        isLogoUploaded:false,
        logo:''
      };
    case BrokerActionTypes.START_EDIT_FIRM:
      return {
        ...state,
        isEdit: true,
        editBroker: action.payload,
        isLogoUploaded:true,
        logo:action.payload.logo
      };
    case BrokerActionTypes.CANCEL_EDIT_FIRM:
      return {
        ...state,
        isEdit: false,
        editBroker: null,
        isLogoUploaded:false,
        logo:''
      };
    case BrokerActionTypes.FINISH_EDIT_FIRM:
      return {
        ...state,
        isEdit: false,
        editBroker: null,
        brokers: state.brokers.map((broke) =>
          broke._id === action.payload._id ? action.payload : broke
        ),
        isLogoUploaded:false,
        logo:''
      };
    case BrokerActionTypes.DELETE_FIRM_PASS:
      return {
        ...state,
        brokers: state.brokers.filter((broke) => broke._id !== action.payload),
      };
    case BrokerActionTypes.UPLOAD_LOGO_PASS:
      return {
        ...state,
        isLogoUploaded: true,
        logo: action.payload.logo,
        logoAR: action.payload.logoAR,
      };  
      case BrokerActionTypes.UPLOAD_LOGO_FAIL:
        return {
          ...state,
          isLogoUploaded: false,
          logo: ""
        }    
      case BrokerActionTypes.DELETE_LOGO_PASS:
        return {
          ...state,
          isLogoUploaded: false,
          logo: "",
          logoAR: 1,
        };  
      case BrokerActionTypes.DELETE_LOGO_FAIL:
        return {
          ...state,
        }    
      case BrokerActionTypes.UNLOAD_BROKER:
        return {  
          brokers: [],
          isEdit: false,
          editBroker: null,
          isLogoUploaded: false,
          logo: "",
          logoAR: 1,
        }  
      default: {
        return {
          ...state,
        };
    }
  }
};

export default brokerReducer;
