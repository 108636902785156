import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import DrawInputs from "./draw-inputs";
import AdditionalIncome from "./additional-income";
import BlendedIncome from "./blended-income";

const DrawsAndIncome = ({ inputs, inputArrays, handlers }) => {
  const { inputDraw, inputIncome, inputBlended } = inputs;
  const { draws, incomes, blendeds } = inputArrays;

  return (
    <TabView id='tab-view-extra'>
      <TabPanel header='Draws' id='draws-inputs'>
        <DrawInputs handlers={handlers} inputs={inputDraw} draws={draws} />
      </TabPanel>
      <TabPanel header='Additional Income' id='additional-income'>
        <AdditionalIncome
          handlers={handlers}
          inputs={inputIncome}
          incomes={incomes}
        />
      </TabPanel>
      <TabPanel header='Blended' id='blended-income'>
        <BlendedIncome
          handlers={handlers}
          inputs={inputBlended}
          blendeds={blendeds}
        />
      </TabPanel>
    </TabView>
  );
};

export default DrawsAndIncome;
