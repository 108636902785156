import React, { Fragment } from "react";

import ButtonFields from "../button-fields/button-fields.component";

const AddEditButtons = ({
  isEdit,
  addButtons,
  handlers: { handleAdd, handleCancel, handleClear, handleSave },
  disableAdd,
}) => {
  return (
    <Fragment>
      {isEdit ? (
        <Fragment>
          <ButtonFields
            constants={addButtons.cancel}
            handler={handleCancel}
            disabled={false}
          />
          <ButtonFields
            constants={addButtons.save}
            handler={handleSave}
            disabled={false}
          />
        </Fragment>
      ) : (
        <Fragment>
          <ButtonFields
            constants={addButtons.clear}
            handler={handleClear}
            disabled={false}
          />
          <ButtonFields
            constants={addButtons.add}
            handler={handleAdd}
            disabled={disableAdd}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddEditButtons;
