export const calculateSummary = (results) => {
  const conditions = getConditions(results);
  const { passing, failing } = getResults(results.runResults);
  return { conditions, passing, failing };
};

const getConditions = (results) => {
  if (results.runResults.length < 1) return null;
  const runs = results.runResults.length;
  const {
    startYear,
    settlement,
    startBalance,
    // yearlyDraw,
    // yearlyIncome,
    // yearlyBlended,
    yearlyFees,
    numberOfYears,
    percentBonds,
    bondRate,
  } = results.runResults[0];
  return {
    startYear,
    endYear: startYear + numberOfYears - 1,
    startBalance,
    settlement,
    // draw: yearlyDraw,
    // income: yearlyIncome,
    // blended: yearlyBlended,
    fees: yearlyFees,
    gainCalculationType: results.gainCalculationType,
    filingStatus: results.filingType,
    filingState: results.filingState,
    yearMarried: results.yearMarried,
    isTaxable: results.isTaxable,
    allDraws: results.allDraws.map((draw) => {
      return { ...draw };
      //return { ...draw, isDrawNonQualified: isDrawNonQualified };
    }),
    allIncomes: results.allIncomes,
    allBlendeds: results.allBlendeds,
    percentBonds,
    bondRate:
      results.gainCalculationType === "sp500_adjusted" ? bondRate : "variable",
    runs,
  };
};
const getResults = (results) => {
  if (results.length < 1) return null;
  let pass = 0;
  // let passIRR = 0;
  let passTotalTaxes = 0;
  let passBalance = 0;
  let fail = 0;
  // let failIRR = 0;
  let failTotalTaxes = 0;
  let failYear = 0;
  for (let result of results) {
    if (result.lastYearBalance !== "") {
      pass += 1;
      passBalance += result.lastYearBalance;
      passTotalTaxes += result.totalTaxes;
      // passIRR += result.averageIRRYear10;
    } else {
      fail += 1;
      failYear += result.yearBalanceRanOut;
      failTotalTaxes += result.totalTaxes;
      // failIRR += result.averageIRRYear10;
    }
  }
  if (pass > 0) {
    // passIRR = passIRR / pass;
    passBalance = passBalance / pass;
    passTotalTaxes = passTotalTaxes / pass;
  }
  if (fail > 0) {
    // failIRR = failIRR / fail;
    failYear = failYear / fail;
    failTotalTaxes = failTotalTaxes / fail;
  }
  const passing = {
    count: pass,
    // averageIRR: passIRR,
    totalTaxes: passTotalTaxes,
    percent: pass / (pass + fail),
    averageBalance: passBalance,
  };
  const failing = {
    count: fail,
    // averageIRR: failIRR,
    totalTaxes: failTotalTaxes,
    percent: fail / (pass + fail),
    averageYear: failYear,
  };
  return { passing, failing };
};
