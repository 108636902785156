import React, { Fragment } from "react";

import ButtonFields from "../button-fields/button-fields.component";

const ThreeStateButtons = ({
  isQuoteEdit,
  isQuoteLoaded,
  addButtons,
  handlers: {
    handleAdd,
    handleClear,
    handleEdit,
    handleNew,
    handleCancel,
    handleSave,
  },
}) => {
  return (
    <Fragment>
      {!isQuoteLoaded ? (
        <Fragment>
          <ButtonFields
            constants={addButtons.clear}
            handler={handleClear}
            disabled={false}
          />
          <ButtonFields
            constants={addButtons.add}
            handler={handleAdd}
            disabled={false}
          />
        </Fragment>
      ) : null}
      {isQuoteLoaded & !isQuoteEdit ? (
        <Fragment>
          <ButtonFields
            constants={addButtons.new}
            handler={handleNew}
            disabled={false}
          />
          <ButtonFields
            constants={addButtons.edit}
            handler={handleEdit}
            disabled={false}
          />
        </Fragment>
      ) : null}
      {isQuoteLoaded & isQuoteEdit ? (
        <Fragment>
          <ButtonFields
            constants={addButtons.cancel}
            handler={handleCancel}
            disabled={false}
          />
          <ButtonFields
            constants={addButtons.save}
            handler={handleSave}
            disabled={false}
          />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default ThreeStateButtons;
