export const createCSV = (inputs, filename) => {
  let blobString = convertObjectToString("csv", inputs);
  let link = document.createElement("a");
  link.download = `${filename}`;
  let blob = new Blob([blobString], { type: "text/plain" });
  link.href = URL.createObjectURL(blob);
  link.click();
  window.URL.revokeObjectURL(link.href);
  return;
};

const convertObjectToString = (format, data) => {
  let returnString = "";
  if (format === "csv") {
    returnString += createHeader(data[0]);
    data.forEach((row, index) => {
      returnString += createYear(row);
    });
  }
  return returnString;
};

const createHeader = (data) => {
  let returnHeader = "";
  if ("year" in data) returnHeader += "Year";
  if ("gain" in data) returnHeader += ",Random Gain %";
  if ("bondRate" in data) returnHeader += ",Bond Rate %";
  if ("startBalance" in data) returnHeader += ",Start Balance";
  if ("grossBalance" in data) returnHeader += ",Gross Balance";
  if ("netBalance" in data) returnHeader += ",Net Balance";
  if ("endBalance" in data) returnHeader += ",End Balance";
  if ("fee" in data) returnHeader += ",Fee";
  if ("grossDraw" in data) returnHeader += ",Gross Draw";
  if ("draw" in data) returnHeader += ",Draw";
  if ("benefit" in data) returnHeader += ",Benefit";
  if ("stockGain" in data) returnHeader += ",Stock Gain";
  if ("bondGain" in data) returnHeader += ",Bond Gain";
  if ("grossGain" in data) returnHeader += ",Gross Gain";
  if ("netGain" in data) returnHeader += ",Net Gain";
  if ("taxPercent" in data) returnHeader += ",Tax %";
  if ("taxes" in data) returnHeader += ",Taxes";
  returnHeader += "\n";
  return returnHeader;
};
const createYear = (data) => {
  let returnHeader = "";
  if ("year" in data) returnHeader += `${data.year}`;
  if ("gain" in data) returnHeader += `,${parseFloat(data.gain.toFixed(2))}`;
  if ("bondRate" in data)
    returnHeader += `,${parseFloat(data.bondRate.toFixed(2))}`;
  if ("startBalance" in data)
    returnHeader += `,${parseFloat(data.startBalance.toFixed(2))}`;
  if ("grossBalance" in data)
    returnHeader += `,${parseFloat(data.grossBalance.toFixed(2))}`;
  if ("netBalance" in data)
    returnHeader += `,${parseFloat(data.netBalance.toFixed(2))}`;
  if ("endBalance" in data)
    returnHeader += `,${parseFloat(data.endBalance.toFixed(2))}`;
  if ("fee" in data) returnHeader += `,${parseFloat(data.fee.toFixed(2))}`;
  if ("grossDraw" in data)
    returnHeader += `,${parseFloat(data.grossDraw.toFixed(2))}`;
  if ("draw" in data) returnHeader += `,${parseFloat(data.draw.toFixed(2))}`;
  if ("benefit" in data)
    returnHeader += `,${parseFloat(data.benefit.toFixed(2))}`;
  if ("stockGain" in data)
    returnHeader += `,${parseFloat(data.stockGain.toFixed(2))}`;
  if ("bondGain" in data)
    returnHeader += `,${parseFloat(data.bondGain.toFixed(2))}`;
  if ("grossGain" in data)
    returnHeader += `,${parseFloat(data.grossGain.toFixed(2))}`;
  if ("netGain" in data)
    returnHeader += `,${parseFloat(data.netGain.toFixed(2))}`;
  if ("taxPercent" in data)
    returnHeader += `,${parseFloat(data.taxPercent.toFixed(2))}`;
  if ("taxes" in data) returnHeader += `,${parseFloat(data.taxes.toFixed(2))}`;
  returnHeader += "\n";
  return returnHeader;
};
