import Year from "./year";
import { randomizeSequence, randomizeSequencePair } from "./numbers";
import {
  VBTIX,
  sp500_adjusted,
  sp500_vbtix_60_10y,
  sp500_vbtix_60_15y,
} from "../assets/data/stock.data";

export default class OneRun {
  constructor({
    inputs,
    yearlyDraws,
    yearlyIncomes,
    yearlyBlendeds,
    changeMarried,
  }) {
    this.gainValue = inputs.gainValue;
    this.startYear = inputs.startYear;
    this.numberOfYears = inputs.numberOfYears;
    this.noLoss = inputs.noLoss;
    this.settlement = inputs.settlement;
    this.startBalance = inputs.startBalance;
    this.yearlyDraws = yearlyDraws;
    this.yearlyIncomes = yearlyIncomes;
    this.yearlyBlendeds = yearlyBlendeds;
    this.yearlyFees = inputs.yearlyFees;
    this.years = [];
    this.percentBonds = inputs.percentBonds;
    this.bondRate = inputs.bondRate;
    this.stateTax = inputs.stateTax;
    this.taxRun = inputs.taxFiling;
    this.changeMarried = changeMarried;
    this.yearBalanceRanOut = "";
    this.isYearDetermined = false;
    this.lastYearBalance = "";
    const { gainOrder, gainOrder2 } = this.calculateGainOrder();
    this.gainOrder = gainOrder;
    this.gainOrder2 = gainOrder2;
    this.calculateYears();
  }
  calculateGainOrder() {
    // let gainsFor2 = this.gainValue === "sp500_vbtix" ? VBTIX : BAGG;
    if (this.gainValue.startsWith("sp500_vbtix_60")) {
      let gOrder = [];
      let gainType =
        this.gainValue === "sp500_vbtix_60_10y"
          ? sp500_vbtix_60_10y
          : sp500_vbtix_60_15y;
      let ran = Math.floor(this.numberOfYears / gainType.length);
      for (let i = 0; i <= ran; i++) {
        const gainOrder = randomizeSequence(gainType);
        gOrder = [...gOrder, ...gainOrder];
      }
      let gainOrder = gOrder.slice(0, this.numberOfYears);
      return { gainOrder, gainOrder2: [] };
    }
    const gainPairs = { gains1: sp500_adjusted, gains2: VBTIX };
    let gOrder = [];
    let gOrder2 = [];
    // let ran = Math.floor(this.numberOfYears / 20); hardcoded
    let ran = Math.floor(this.numberOfYears / gainPairs.gains1.length);

    for (let i = 0; i <= ran; i++) {
      const { gainOrder, gainOrder2 } = randomizeSequencePair(gainPairs);
      gOrder = [...gOrder, ...gainOrder];
      gOrder2 = [...gOrder2, ...gainOrder2];
    }
    let gainOrder = gOrder.slice(0, this.numberOfYears);
    let gainOrder2 = gOrder2.slice(0, this.numberOfYears);
    return { gainOrder, gainOrder2 };
  }
  calculateYears() {
    this.currentBalance = this.startBalance;
    for (let i = 0; i < this.numberOfYears; i++) {
      if (this.startYear + i === this.changeMarried) {
        this.taxRun = this.taxRun.startsWith("federal")
          ? "federalMarried"
          : "ltcgMarried";
      }
      let draw = this.yearlyDraws[i];
      if (this.gainValue === "VBTIX") this.bondRate = this.gainOrder2[i];
      let yearlyData = {
        id: i,
        year: i + this.startYear,
        gain: this.gainOrder[i],
        startBalance: this.currentBalance,
        draw: draw,
        income: this.yearlyIncomes[i],
        blended: this.yearlyBlendeds[i],
        percentFee: this.yearlyFees,
        taxRun: this.taxRun,
        percentBonds: this.percentBonds,
        bondRate: this.bondRate,
        stateTax: this.stateTax,
        noLoss: this.noLoss,
      };
      this.years.push(new Year(yearlyData));
      if (this.years[i].endBalance <= 0) {
        if (!this.isYearDetermined) {
          this.yearBalanceRanOut = this.years[i].year;
          this.isYearDetermined = true;
          this.firstShortfall = this.years[i].firstShortfall;
        }
        this.lastYearBalance = "";
        this.endBalance = 0;
        this.currentBalance = 0;
      } else {
        this.lastYearBalance = this.years[i].endBalance;
        this.currentBalance = this.years[i].endBalance;
      }
    }
  }
}
