import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "../quote-page.styles";

const P1Dates = ({ loc, quote, expiration, purchase }) => {
  // const showDate = (dateString) => {
  //   //let viewDate = new Date(dateString);
  //   return viewDate(dateString);
  // };
  return (
    <View style={loc}>
      <Text
        style={[
          styles.text12,
          styles.right,
          styles.bold,
          styles.underline,
          styles.margin10_top,
        ]}
      >
        Proposal Date:
      </Text>
      <Text style={[styles.text12, styles.right]}>{quote}</Text>
      <Text
        style={[
          styles.text12,
          styles.right,
          styles.bold,
          styles.underline,
          styles.margin10_top,
        ]}
      >
        Proposal Valid Through:
      </Text>
      <Text style={[styles.text12, styles.right]}>{expiration}</Text>
      <Text
        style={[
          styles.text12,
          styles.right,
          styles.bold,
          styles.underline,
          styles.margin10_top,
        ]}
      >
        Purchase Date:
      </Text>
      <Text style={[styles.text12, styles.right]}>{purchase}</Text>
    </View>
  );
};

export default P1Dates;
