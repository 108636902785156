import React, { Fragment, useState, useEffect } from "react";
import { Button } from "primereact/button";
import { TabMenu } from "primereact/tabmenu";
import AnalyzerSummary from "./analyzer-summary/analyzer-summary.component";
import AnalyzerTable from "./analyzer-table/analyzer-table.component";
import AnalyzerPlots from "./analyzer-plots/analyzer-plots.component";
import AnalyzerPie from "./analyzer-pie/analyzer-pie.component";
import PdfAnalyzer from "./pdf-analyzer/pdf-analyzer.component";

import { calculateSummary } from "./results-utils";
import { delay } from "../../../utilities/numbers";

const ResultsTab = ({ result, claimant, broker, handleDeleteRun }) => {
  const [plotUrl, setPlotUrl] = useState(null);
  const [pieUrl, setPieUrl] = useState(null);
  const [active, setActive] = useState(0);
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    if (result !== null) getSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const getSummary = async () => {
    let sum = await calculateSummary(result);
    setSummary(sum);
  };

  return (
    result.runResults !== undefined &&
    result.runResults.length > 0 && (
      <Fragment>
        <div className='k-flex-between'>
          <div>
            <Button
              iconPos='left'
              icon='pi pi-trash'
              label='Delete Run'
              className='p-button-raised p-button-danger p-mr-4'
              onClick={() => {
                handleDeleteRun();
              }}
            />
          </div>
          <div className='k-analyzer-results-tab'>
            <TabMenu
              model={[
                { id: 0, label: "View HTML" },
                { id: 1, label: "View PDF" },
              ]}
              activeIndex={active}
              onTabChange={async (e) => {
                if (e.value.id === 1) await delay(1000);
                setActive(e.value.id);
              }}
            />
          </div>
          <div className='k-button-space' />
        </div>
        {active === 0 ? (
          <Fragment>
            {summary !== null && <AnalyzerSummary summary={summary} />}
            <AnalyzerPlots
              results={result.runResults}
              setPlotUrl={setPlotUrl}
            />
            <AnalyzerPie results={summary} setPieUrl={setPieUrl} />
            <AnalyzerTable results={result.runResults} />
          </Fragment>
        ) : (
          summary !== null && (
            <div className='w-100pct p-d-flex'>
              <div className='w-10pct'></div>

              <PdfAnalyzer
                results={result.runResults}
                summary={summary}
                plotUrl={plotUrl}
                pieUrl={pieUrl}
                claimant={claimant}
                broker={broker}
              />

              <div className='w-10pct'></div>
            </div>
          )
        )}
      </Fragment>
    )
  );
};

export default ResultsTab;
