import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import {
  addNewUser,
  makeAddNewUserReady,
} from "../../../redux/admin/admin.actions";

const AddUser = ({ admin, addNewUser, makeAddNewUserReady }) => {
  const [adding, setAdding] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [userIn, setUserIn] = useState({
    email: "",
  });
  const { email } = userIn;
  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkErrorFlag();
    // eslint-disable-next-line
  }, [admin.isNewUserAdded]);

  const checkErrorFlag = async () => {};

  const initPage = async () => {
    await makeAddNewUserReady();
  };

  const handleShowDialog = (e) => {
    setShowDialog(true);
  };
  const hideDialog = () => {
    setShowDialog(false);
  };
  const handleRegister = async (e) => {
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(email) === false) {
      setEmailError(true);
      return;
    }
    let userItem = { email };
    try {
      setAdding(true);
      await addNewUser(userItem);
      setAdding(false);
      setShowDialog(false);
      setUserIn({
        email: "",
      });
    } catch (err) {}
  };
  const handleInputChange = (e) => {
    setUserIn({ ...userIn, [e.target.name]: e.target.value });
    setEmailError(false);
  };

  return (
    <Fragment>
      <Dialog
        header='Send Invite'
        footer=''
        visible={showDialog}
        style={{ width: "30vw" }}
        onHide={hideDialog}
        baseZIndex={1000}
      >
        <Fragment>
          <div className='p-fluid p-grid p-dir-col'>
            <div className='p-field p-col-12'>
              <label htmlFor='email-address'>Email Address</label>
              <InputText
                id='email-address'
                name='email'
                value={email}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
          </div>
          {admin.isNewUserAdded === -1 && (
            <h6>The email address is already in use</h6>
          )}
          {emailError === true && <h6>Incorrect email format</h6>}
          <div className='p-d-flex p-jc-evenly'>
            <Button
              onClick={hideDialog}
              label='Cancel'
              className='p-button-warning'
            />
            <Button
              onClick={handleRegister}
              label='Send Invite'
              className='p-button-success'
              loadingIcon='pi pi-spin pi-sun'
              loading={adding}
              // disabled={true}
            />
          </div>
        </Fragment>
      </Dialog>
      <Button onClick={handleShowDialog} label='Add User' />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, {
  makeAddNewUserReady,
  addNewUser,
  // seederAction,
})(AddUser);
