import React, { Fragment } from "react";
import { InputSwitch } from "primereact/inputswitch";

const InputFieldSwitch = ({
  handler,
  variables: { value },
  constants: { labelFalse, labelTrue, id, name, medSize },
}) => {
  return (
    <Fragment>
      <div className={`p-field p-col-4 p-md-${medSize}`}>
        <label className='w-100pct' htmlFor={id}>
          {value ? labelTrue : labelFalse}
        </label>

        <InputSwitch id={id} name={name} checked={value} onChange={handler} />
      </div>
    </Fragment>
  );
};

export default InputFieldSwitch;
