import { combineReducers } from "redux";

import brokerReducer from "./broker/broker.reducer";
import claimantReducer from "./claimant/claimant.reducer";
import quoteReducer from "./quote/quote.reducer";
import userReducer from "./user/user.reducer";
import analyzerReducer from "./analyzer/analyzer.reducer";
import adminReducer from "./admin/admin.reducer";

const rootReducer = combineReducers({
  broker: brokerReducer,
  claimant: claimantReducer,
  quote: quoteReducer,
  user: userReducer,
  analyzer: analyzerReducer,
  admin: adminReducer,
});

export default rootReducer;
