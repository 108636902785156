import Dates from "../../utilities/dates";
export const detailInputs = {
  quoteName: {
    label: "Proposal Name",
    name: "quoteName",
    id: "quote-name1",
    medSize: 8,
    required: true,
    minimum: 1,
    maximum: 99999,
    textError: "Please enter a Proposal Name",
    validate: "text",
  },
  brokerName: {
    label: "Brokerage Firm",
    name: "brokerName",
    id: "broker-name",
    medSize: 4,
    required: true,
    minimum: 1,
    maximum: 99999,
    textError: "Please select a Brokerage Firm",
    validate: "text",
  },
  lifeCompany: {
    label: "Life Companies",
    name: "lifeCompany",
    id: "life-company",
    medSize: 4,
    required: true,
    minimum: 1,
    maximum: 99999,
    textError: "Please select at least one Life Company",
    validate: "text",
  },
  claimantsName: {
    label: "Claimant",
    name: "claimantsName",
    id: "claimants-name",
    medSize: 4,
    required: true,
    minimum: 1,
    maximum: 99999,
    textError: "Please select a Claimant",
    validate: "text",
  },
  irrPercent: {
    label: "IRR %",
    name: "irrPercent",
    id: "irr-percent",
    medSize: 2,
    required: false,
    minimum: 0,
    maximum: 50,
    textError: "Please enter an IRR Percent",
    validate: "date",
  },
  quoteDate: {
    label: "Proposal Date",
    name: "quoteDate",
    id: "quote-date",
    medSize: 4,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter a Proposal Date",
    validate: "text",
  },
  purchaseDate: {
    label: "Purchase Date",
    name: "purchaseDate",
    id: "purchase-date",
    medSize: 4,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter a Purchase Date",
    validate: "date",
  },
  expirationDate: {
    label: "Expiration Date",
    name: "expirationDate",
    id: "expiration-date",
    medSize: 4,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter an Expiration Date",
    validate: "date",
  },
  proposalNotes: {
    label: "Proposal Notes",
    name: "proposalNotes",
    id: "proposal-notes",
    medSize: 12,
    required: false,
    minimum: 1,
    maximum: 99999,
    textError: "Please enter a Proposal Name",
    validate: "text",
    rows: 2,
    cols: 90,
  },
  showTotal: {
    label: "Show Total",
    name: "showTotal",
    id: "show-total",
    medSize: 4,
    required: false,
    minimum: 1,
    maximum: 99999,
    textError: "Not used",
    validate: "text",
  },
  showPremium: {
    label: "Show Premium",
    name: "showPremium",
    id: "show-premium",
    medSize: 4,
    required: false,
    minimum: 1,
    maximum: 99999,
    textError: "Not used",
    validate: "text",
  },
  showPlots: {
    label: "Show Plots Pdf",
    name: "showPlots",
    id: "show-plots",
    medSize: 4,
    required: false,
    minimum: 1,
    maximum: 99999,
    textError: "Not used",
    validate: "text",
  },
};
const inp = {
  value: "",
  showError: false,
  disabled: false,
};
const inpNull = {
  value: null,
  showError: false,
  disabled: false,
};
const inpDate = {
  value: Dates.today(),
  showError: false,
  disabled: false,
};
const inpDateAddMonth = {
  value: Dates.addMonths(1),
  showError: false,
  disabled: false,
};
const inpDateAddWeek = {
  value: Dates.addWeek(),
  showError: false,
  disabled: false,
};
const inpNumber = {
  value: 0,
  showError: false,
  disabled: false,
};
const inpBool = {
  value: true,
  showError: false,
  disabled: false,
};
const inpBool2 = {
  value: true,
  showError: false,
  disabled: false,
};

const inpBool3 = {
  value: true,
  showError: false,
  disabled: false,
};

export const detailInitInputs = {
  quoteName: inp,
  brokerName: inp,
  showTotal: inpBool,
  showPremium: inpBool2,
  showPlots: inpBool3,
  lifeCompany: inpNull,
  claimantsName: inp,
  irrPercent: inpNumber,
  quoteDate: inpDate,
  purchaseDate: inpDateAddMonth,
  expirationDate: inpDateAddWeek,
  proposalNotes: inp,
};

export const detailAddButtons = {
  cancel: {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-raised p-button-warning",
  },
  save: {
    label: "Save Proposal",
    icon: "pi pi-save",
    className: "p-button-raised",
  },
  edit: {
    label: "Edit Proposal",
    icon: "pi pi-pencil",
    className: "p-button-raised p-button-warning",
  },
  new: {
    label: "New Proposal",
    icon: "pi pi-plus",
    className: "p-button-raised p-button-success",
  },
  clear: {
    label: "Clear",
    icon: "pi pi-times",
    className: "p-button-raised p-button-warning",
  },
  add: {
    label: "Add Proposal",
    icon: "pi pi-plus",
    className: "p-button-raised",
  },
};
