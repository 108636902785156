import { StyleSheet } from "@react-pdf/renderer";
const BORDER_COLOR = "#ffffff";
const BORDER_TOTAL = "#000000";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 40;
const COLN_WIDTH = (98 - COL1_WIDTH) / 3;
const styles = StyleSheet.create({
  bgChart: {
    backgroundColor: "#e0e0e0",
  },
  canvasHeader: {
    position: "absolute",
    width: 588,
    height: 100,
    top: 0,
    left: 0,
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    flexGrow: 1,
    border: "2pt solid black",
  },
  grow: {
    flexGrow: 1,
  },
  header: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  header_page2: {
    position: "absolute",
    top: 10,
    left: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 572,
  },
  header_f3dddc: {
    backgroundColor: "#f3dddc",
    height: 100,
    borderBottom: "2pt solid black",
  },
  prepared: {
    position: "absolute",
    top: 250,
    width: 592,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  table_location: {
    position: "absolute",
    top: 120,
    left: 10,
    width: 548,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  presented: {
    position: "absolute",
    bottom: 10,
    left: 10,
    width: 572,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  presented2: {
    position: "absolute",
    marginRight: 10,
    bottom: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 572,
  },
  legal: {
    position: "absolute",
    bottom: 10,
    marginRight: 10,
    width: 578,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // right:10
  },
  guarantee: {
    position: "absolute",
    bottom: 10,
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 578,
  },
  top_chart: {
    position: "absolute",
    top: 125,
    height: 275,
    left: 20,
    display: "flex",
    width: 500,
  },
  bottom_chart: {
    position: "absolute",
    top: 425,
    height: 275,
    left: 20,
    display: "flex",
    width: 500,
  },
  logo: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 100,
    maxWidth: 300,
    maxHeight: 100,
    objectFit: "contain",
    // width: 100,
  },
  logo_small: {
    position: "absolute",
    left: 10,
    bottom: 10,
    //width: "100%",
    height: 50,
    maxWidth: 150,
    maxHeight: 50,
    objectFit: "contain",
    // width: 60,
  },
  left: {
    textAlign: "left",
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  bold: {
    fontWeight: 600,
  },
  underline: {
    textDecoration: "underline",
  },
  italic: {
    textDecoration: "italic",
  },
  text7: {
    fontSize: 7,
    fontFamily: "Open Sans",
  },
  text8: {
    fontSize: 8,
    fontFamily: "Open Sans",
  },
  text10: {
    fontSize: 10,
    fontFamily: "Open Sans",
  },
  text12: {
    fontSize: 12,
    fontFamily: "Open Sans",
  },
  text14: {
    fontSize: 14,
    fontFamily: "Open Sans",
  },
  text16: {
    fontSize: 16,
    fontFamily: "Open Sans",
  },
  text18: {
    fontSize: 18,
    fontFamily: "Open Sans",
  },
  text20: {
    fontSize: 20,
    fontFamily: "Open Sans",
  },
  text22: {
    fontSize: 22,
    fontFamily: "Open Sans",
  },
  margin25_top: {
    marginTop: 25,
  },
  margin20_top: {
    marginTop: 20,
  },
  margin20_bottom: {
    marginBottom: 20,
  },
  margin15_top: {
    marginTop: 15,
  },
  margin10_top: {
    marginTop: 10,
  },
  margin5_top: {
    marginTop: 5,
  },
  margin5_bottom: {
    marginBottom: 5,
  },
  margin0_bottom: {
    marginBottom: 0,
  },
  margin10_left: {
    marginLeft: 10,
  },
  margin10: {
    margin: 10,
  },
  padding10: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table_row: {
    // margin: "auto",
    flexDirection: "row",
  },
  table_col1_header: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    //borderBottomColor: '#000',
    //borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  table_col_header: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    //borderBottomColor: '#000',
    //borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  table_col1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //height: 100,
  },
  table_col: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //height: 100,
  },
  table_total_col1: {
    width: COL1_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  table_total_col: {
    width: COLN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_TOTAL,
    borderTopWidth: 1,
  },
});

export default styles;
