import React from "react";
import { InputNumber } from "primereact/inputnumber";
import TooltipAddon from "./tooltip-addon";

const CurrencyInputSpecial = ({ constants, value, condition, handler }) => {
  const { id, label1, label2, size, min, tt } = constants;
  return (
    <div className={`p-field p-col-${size}`}>
      <label id={`label-${id}`} className='big-bold-label' htmlFor={id}>
        {condition ? label2 : label1}
      </label>
      {tt != undefined && <TooltipAddon tt={tt} element={`#label-${id}`} />}
      <InputNumber
        name={id}
        id={id}
        min={min}
        value={value}
        onValueChange={handler}
        mode='currency'
        currency='USD'
        locale='en-US'
        placeholder='$0.00'
        className={`${value < 0.01 && "p-invalid"}`}
      />
    </div>
  );
};

export default CurrencyInputSpecial;
