import React, { Fragment } from "react";
import { Text, View } from "@react-pdf/renderer";

import styles from "../quote-page.styles";

const P2Guarantee = ({
  loc,
  proposalNotes,
  disclaimer,
  life_companies,
  name,
  title,
}) => {
  const LifeDisplay = () => {
    let disp = life_companies[0].name;
    for (let i = 1; i <= life_companies.length - 1; i++) {
      if (i <= life_companies.length - 2) disp += ", ";
      else if (i === life_companies.length - 1) disp += " and ";
      disp += life_companies[i].name;
    }
    return <Fragment>{disp}</Fragment>;
  };

  return (
    <View style={loc}>
      {proposalNotes.length > 0 && (
        <Text
          style={[
            styles.text10,
            styles.center,
            styles.margin10_left,
            styles.margin20_bottom,
            styles.italic,
          ]}
        >
          {proposalNotes}
        </Text>
      )}
      <Text style={[styles.text8, styles.right, styles.italic]}>
        {disclaimer}
      </Text>
      <Text
        style={[
          styles.text8,
          styles.right,
          styles.italic,
          styles.margin20_bottom,
        ]}
      >
        Current Annuity Rates From:{" "}
        {life_companies.length > 2 ? "Multiple Companies" : <LifeDisplay />}
      </Text>
      <Text
        style={[styles.text12, styles.right, styles.bold, styles.underline]}
      >
        Presented By:
      </Text>
      <Text style={[styles.text12, styles.right]}>{name}</Text>
      {title !== "" && (
        <Text style={[styles.text12, styles.right]}>{title}</Text>
      )}
    </View>
  );
};

export default P2Guarantee;
