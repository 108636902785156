import { Tooltip } from "primereact/tooltip";

const TooltipAddon = ({ element, tt }) => {
  if (!tt) return;
  return (
    <Tooltip
      hideDelay={tt.link === undefined ? 0 : 1000}
      target={element}
      // content={tt.content}
      position={tt.position}
      className={`${tt.color}-tooltip`}
    >
      {tt.link === undefined ? (
        tt.content
      ) : (
        <a target='_blank' rel='noreferrer' href={tt.link}>
          {tt.content}
        </a>
      )}
    </Tooltip>
  );
};

export default TooltipAddon;
