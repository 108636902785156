export default class Tax {
  constructor(income, filing) {
    this.income = income;
    this.filing = filing;
    this.taxBracket = this.findTaxBracket();
    this.tax = this.findTax();
    this.taxPercent = this.findTaxPercent();
  }

  findTaxBracket() {
    let max = this.filing.length - 1;
    while (max) {
      if (this.income >= this.filing[max].bracket) return max * 1;
      else max--;
    }
    return 0;
  }

  findTax() {
    const { rate, bracket, accumulated } = this.filing[this.taxBracket];
    let taxBase = accumulated;
    let taxAdditional = rate * (this.income - bracket);
    return taxBase + taxAdditional;
  }

  findTaxPercent = () => {
    let taxPercent = (100 * this.tax) / this.income;
    return taxPercent;
  };
}
