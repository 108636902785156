import React from "react";

import { MediumTextNC, ColorRed, ColorBlack } from "./login.styles";

const Greeting = ({ action, message }) => {
  let hours = new Date().getHours();
  const partOfDay =
    hours >= 18 ? "Evening" : hours > 12 ? "Afternoon" : "Morning";

  return (
    <div className='k-text-center'>
      <MediumTextNC>
        <ColorBlack>Hello!</ColorBlack>{" "}
        <ColorRed>{`Good ${partOfDay}`}</ColorRed>
      </MediumTextNC>
      <MediumTextNC>
        <ColorRed>{action}</ColorRed> <ColorBlack>{message}</ColorBlack>
      </MediumTextNC>
    </div>
  );
};

export default Greeting;
