import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ActionButtons } from "./action-buttons.component";
import Dates from "../../utilities/dates";

export const TableQuotes = ({
  data,
  brokers,
  toast,
  copyQuote,
  loadQuote,
  deleteQuote,
}) => {
  const showModifiedDateTemplate = (rowData) => {
    if (rowData["modifiedAt"] === undefined) return "";
    return Dates.convertDateToString(rowData["modifiedAt"]);
    //return string;
  };
  const showDateTemplate = (rowData, field) => {
    let date = rowData[field.field];
    return date;
  };

  const showFirmName = (rowData) => {
    return brokers.find((broke) => broke._id == rowData.brokerName).firmName;
  };
  const showBrokersName = (rowData) => {
    return brokers.find((broke) => broke._id == rowData.brokerName).brokerName;
  };

  return (
    <div className='inner-saved-proposals-table p-shadow-12 p-mx-4'>
      <DataTable
        value={data.proposals}
        dataKey='id'
        className='p-datatable-striped'
      >
        <Column
          field='quoteName'
          header='Proposal Name'
          style={{ width: "15%" }}
        ></Column>
        <Column
          field='quoteDate'
          body={showDateTemplate}
          header='Proposal Date'
          style={{ width: "10%" }}
        ></Column>
        <Column
          field='expirationDate'
          body={showDateTemplate}
          header='Expiration Date'
          style={{ width: "10%" }}
        ></Column>
        <Column
          field='purchaseDate'
          body={showDateTemplate}
          header='Purchase Date'
          style={{ width: "10%" }}
        ></Column>
        <Column
          body={showFirmName}
          header='Firm Name'
          style={{ width: "15%" }}
        ></Column>
        <Column
          body={showBrokersName}
          header="Broker's Name"
          style={{ width: "15%" }}
        ></Column>
        <Column
          field='modifiedAt'
          body={showModifiedDateTemplate}
          header='Last Updated'
          style={{ width: "10%" }}
          sortable={true}
        ></Column>
        <Column
          body={(data) => (
            <ActionButtons
              rowData={data}
              toast={toast}
              copyQuote={copyQuote}
              loadQuote={loadQuote}
              deleteQuote={deleteQuote}
            />
          )}
          style={{ width: "30%" }}
        />
      </DataTable>
    </div>
  );
};

export default TableQuotes;
