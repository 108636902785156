import React, { useState, Fragment, useEffect } from "react";
import { Image } from "primereact/image";

import ResetPasswordWithToken from "./reset-password-with-token.component";
import RegPass from "./reg-pass.component";
import RegFail from "./reg-fail.component";
import Login from "./login.component";
import Register from "./register.component";
import Forgot from "./forgot.component";
import LOGO from "../../assets/images/thumb_SA.png";

import { Page, Container, WelcomeContainer } from "./login.styles";

const LoginRegisterPasswords = ({ register, reset, token, message }) => {
  const [loginMessage, setLoginMessage] = useState("");
  const [selected, setSelected] = useState("bypass");
  useEffect(() => {
    if (message !== undefined) setLoginMessage(message);
  }, []);

  const handleSelected = (comp) => {
    setSelected(comp);
    setLoginMessage("");
  };

  return (
    <Fragment>
      <Page>
        <Container>
          <WelcomeContainer>
            <div className='k-w-100pct k-text-center'>
              <Image src={LOGO} />
            </div>
          </WelcomeContainer>

          {selected === "login" ? (
            <Login handleClick={handleSelected} />
          ) : selected === "register" ? (
            <Register handleClick={handleSelected} />
          ) : selected === "forgot" ? (
            <Forgot handleClick={handleSelected} />
          ) : reset !== undefined ? (
            <ResetPasswordWithToken token={reset} />
          ) : token !== undefined ? (
            <Login handleClick={handleSelected} token={token} />
          ) : loginMessage !== undefined && loginMessage !== "" ? (
            <Login handleClick={handleSelected} message={loginMessage} />
          ) : register !== undefined && register === "pass" ? (
            <RegPass />
          ) : register !== undefined && register === "fail" ? (
            <RegFail />
          ) : (
            <Login handleClick={handleSelected} />
          )}
        </Container>
      </Page>
    </Fragment>
  );
};
export default LoginRegisterPasswords;
