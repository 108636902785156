import React, { Fragment } from "react";
import { Page, View, Image } from "@react-pdf/renderer";
import styles from "./quote-page.styles";
import CustomizedHeader from "./customized-header.component";
import PdfBenefitsChart from "./views/pdf-benefits-chart.view";
import P2Header from "./views/p2-header.view";
import P4Footer from "./views/p4-footer.view";

const QuotePage4 = ({ details, image3Url, image4Url }) => {
  return (
    <Fragment>
      {(details !== null) & (details.brokerName !== null) && (
        <Page size='letter' object-fit='fill' style={styles.page}>
          <View style={styles.section} wrap={true}>
            {details.brokerName.colors !== undefined && (
              <CustomizedHeader addOn='3' colors={details.brokerName.colors} />
            )}
            <P2Header
              broker={details.brokerName}
              claimant={details.claimantsName}
              loc={styles.header_page2}
            />
            <PdfBenefitsChart
              chart={image3Url}
              // coordinates={{ top: 125, left: 20, width: 500, height: 275 }}
              loc={styles.top_chart}
            />
            <PdfBenefitsChart
              chart={image4Url}
              // coordinates={{ top: 140, left: 20, width: 500, height: 275 }}
              loc={styles.bottom_chart}
            />

            <P4Footer
              name={details.brokerName.brokerName}
              title={details.brokerName.brokerTitle}
              loc={styles.guarantee}
            />

            <Image
              style={styles.logo_small}
              src={`/img/logos/${details.brokerName.logo}`}
              cache={false}
            />
          </View>
        </Page>
      )}
    </Fragment>
  );
};

export default QuotePage4;
