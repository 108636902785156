import { QuoteActionTypes } from "./quote.types";

const INITIAL_STATE = {
  proposals: [],
  isProposalLoaded: false,
  proposal: null,
  isSavedProposalLoaded: false,
  isProposalEdit: false,
  isBenefitEdit: false,
  editBenefit: null,
  disablePdf: true,
};

const quoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QuoteActionTypes.COPY_QUOTE_PASS:
    case QuoteActionTypes.GET_QUOTES_PASS:
      return {
        ...state,
        proposals: action.payload,
      };
    case QuoteActionTypes.SET_PAGE_BREAK:
      return {
        ...state,
        proposal: { ...state.proposal, pageBreak: action.payload },
      };
    case QuoteActionTypes.COPY_QUOTE_FAIL:
    case QuoteActionTypes.GET_QUOTES_FAIL:
      return {
        ...state,
        isProposalLoaded: false,
        isProposalEdit: false,
        proposal: null,
      };
    case QuoteActionTypes.NEW_QUOTE_PASS:
      return {
        ...state,
        isProposalLoaded: false,
        isProposalEdit: false,
        proposal: null,
        isBenefitEdit: false,
        editBenefit: null,
      };
    case QuoteActionTypes.CANCEL_QUOTE_PASS:
      return {
        ...state,
        isProposalLoaded: true,
        isProposalEdit: false,
        isSavedProposalLoaded: true,
        disablePdf: false,
      };
    case QuoteActionTypes.LOAD_QUOTE_PASS:
      return {
        ...state,
        isProposalLoaded: true,
        isProposalEdit: false,
        disablePdf: false,
        proposal: {
          ...state.proposals.find(
            (proposal) => proposal._id === action.payload
          ),
        },
      };
    case QuoteActionTypes.ADD_QUOTE_PASS:
      return {
        ...state,
        isProposalLoaded: true,
        isProposalEdit: false,
        proposal: action.payload,
      };
    case QuoteActionTypes.ADD_QUOTE_FAIL:
      return {
        ...state,
        isProposalLoaded: false,
        isProposalEdit: false,
        proposal: null,
      };
    case QuoteActionTypes.LOAD_QUOTE_FAIL:
      return {
        ...state,
        isProposalLoaded: false,
        isProposalEdit: false,
        proposal: null,
      };
    case QuoteActionTypes.START_EDIT_QUOTE_PASS:
      return {
        ...state,
        isProposalLoaded: true,
        isProposalEdit: true,
      };
    case QuoteActionTypes.SAVE_QUOTE_PASS: {
      return {
        ...state,
        isProposalLoaded: true,
        isProposalEdit: false,
        proposal: action.payload,
      };
    }
    case QuoteActionTypes.SAVE_QUOTE_FAIL:
      return {
        ...state,
        proposal: null,
      };
    case QuoteActionTypes.DELETE_QUOTE_PASS:
      return {
        ...state,
        proposal: null,
        proposals: state.proposals.filter(
          (proposal) => proposal._id !== action.payload
        ),
        isProposalLoaded: false,
        isProposalEdit: false,
        editProposal: null,
        isBenefitEdit: false,
        pdfPageBreak: 0,
      };
    case QuoteActionTypes.SAVE_QUOTE_LOADED:
      return {
        ...state,
        isSavedProposalLoaded: true,
      };
    case QuoteActionTypes.SAVE_AUTO_BENEFITS_PASS: {
      return {
        ...state,
        proposal: { ...state.proposal, benefits: action.payload },
      };
    }
    case QuoteActionTypes.COPY_BENEFIT_PASS:
    case QuoteActionTypes.REORDER_BENEFITS_PASS:
    case QuoteActionTypes.ADD_BENEFIT_PASS:
      return {
        ...state,
        proposal: action.payload,
      };
    case QuoteActionTypes.DISABLE_BENEFITS_PDF:
      return {
        ...state,
        disablePdf: true,
      };
    case QuoteActionTypes.ENABLE_BENEFITS_PDF:
      return {
        ...state,
        disablePdf: false,
      };
    case QuoteActionTypes.START_EDIT_BENEFIT_PASS:
      return {
        ...state,
        isBenefitEdit: true,
        editBenefit: action.payload,
      };
    case QuoteActionTypes.FINISH_EDIT_BENEFIT_PASS:
      return {
        ...state,
        isBenefitEdit: false,
        editBenefit: null,
        proposal: action.payload,
      };
    case QuoteActionTypes.CANCEL_EDIT_BENEFIT_PASS:
      return {
        ...state,
        isBenefitEdit: false,
        editBenefit: null,
      };
    case QuoteActionTypes.DELETE_BENEFIT_PASS:
      return {
        ...state,
        proposal: action.payload,
      };
    case QuoteActionTypes.SAVE_AUTO_BENEFITS_FAIL:
    case QuoteActionTypes.COPY_BENEFIT_FAIL:
    case QuoteActionTypes.ADD_BENEFIT_FAIL:
      return {
        ...state,
      };
    case QuoteActionTypes.START_EDIT_BENEFIT_FAIL:
      return {
        ...state,
      };
    case QuoteActionTypes.FINISH_EDIT_BENEFIT_FAIL:
      return {
        ...state,
      };
    case QuoteActionTypes.CANCEL_EDIT_BENEFIT_FAIL:
      return {
        ...state,
      };
    case QuoteActionTypes.DELETE_BENEFIT_FAIL:
      return {
        ...state,
      };
    case QuoteActionTypes.UNLOAD_QUOTE:
      return {
        proposals: [],
        isProposalLoaded: false,
        proposal: null,
        isSavedProposalLoaded: false,
        isProposalEdit: false,
        isBenefitEdit: false,
        editBenefit: null,
        disablePdf: true,
      };
    case QuoteActionTypes.SET_PAGE_BREAK_FAIL:
    default: {
      return {
        ...state,
      };
    }
  }
};

export default quoteReducer;
