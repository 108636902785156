const toast = {
  showToast: (toastRef, { type, message, details, time }) => {
    toastRef.current.show({
      severity: type,
      summary: message,
      detail: details,
      life: time,
    });
  },
  drawStartEarlyFailed: {
    type: "error",
    message: "The draw was not added",
    details: "The Start Year is before the Start Year of the analysis",
    time: 5000,
  },
  drawStartLateFailed: {
    type: "error",
    message: "The draw was not added",
    details: "The Start Year is after the End Year of the analysis",
    time: 5000,
  },
  drawEndEarlyFailed: {
    type: "error",
    message: "The draw was not added",
    details: "The End Year is before the Start Year of the analysis",
    time: 5000,
  },
  drawEndLateFailed: {
    type: "error",
    message: "The draw was not added",
    details: "The End Year is after the End Year of the analysis",
    time: 5000,
  },
  drawYearFailed: {
    type: "error",
    message: "The draw was not added",
    details: "The End Year of the draw is before the Start Year of the draw",
    time: 5000,
  },
  drawAmountFailed: {
    type: "error",
    message: "The draw was not added",
    details: "The Draw Amount must be at least $0.01",
    time: 5000,
  },
  incomeStartEarlyFailed: {
    type: "error",
    message: "The income was not added",
    details: "The Start Year is before the Start Year of the analysis",
    time: 5000,
  },
  incomeStartLateFailed: {
    type: "error",
    message: "The income was not added",
    details: "The Start Year is after the End Year of the analysis",
    time: 5000,
  },
  incomeEndEarlyFailed: {
    type: "error",
    message: "The income was not added",
    details: "The End Year is before the Start Year of the analysis",
    time: 5000,
  },
  incomeEndLateFailed: {
    type: "error",
    message: "The income was not added",
    details: "The End Year is after the End Year of the analysis",
    time: 5000,
  },
  incomeYearFailed: {
    type: "error",
    message: "The income was not added",
    details:
      "The End Year of the added income is before the Start Year of the added income",
    time: 5000,
  },
  incomeAmountFailed: {
    type: "error",
    message: "The additional income was not added",
    details: "The Income Amount must be at least $0.01",
    time: 5000,
  },
  drawsNotValid: {
    type: "error",
    message: "The calculation could not be done",
    details: "There is a draw with an invalid start or end year",
    time: 5000,
  },
  incomesNotValid: {
    type: "error",
    message: "The calculation could not be done",
    details: "There is an additional income with an invalid start or end year",
    time: 5000,
  },
};
export default toast;
