import React from "react";
import { Text } from "@react-pdf/renderer";
import { formatCurrency } from "../../../../../utilities/numbers";
import styles from "../pdf-analyzer.styles";

const IsIncomeParagraph = ({ allIncomes }) => {
  let incomeString = `Your SSA also factors in an additional annual income of ${formatCurrency(
    allIncomes[0].incomeAmount
  )} from ${allIncomes[0].incomeStart} to ${allIncomes[0].incomeEnd}`;
  for (let i = 1; i < allIncomes.length; i++) {
    incomeString += ` as well as ${formatCurrency(
      allIncomes[i].incomeAmount
    )} annually starting in ${allIncomes[i].incomeStart} until ${
      allIncomes[i].incomeEnd
    }`;
  }
  incomeString +=
    " in an effort to more accurately display your potential tax position.";
  return <Text style={[styles.fontm]}>{incomeString}</Text>;
};
export default IsIncomeParagraph;
