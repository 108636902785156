export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const currency = (cash) => {
  const money = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  return money.format(cash);
};

export const percentage = (num) => {
  return `${num}%`;
};

export const decimal2 = (num) => {
  let numString = num.toString();
  let numArray = numString.split(".");
  let numLength = numArray.length;
  if (numLength === 1) numString += ".00";
  else if (numArray[1].length === 1) numString += "0";
  else if (numArray[1].length > 2) {
    // Need to round up on 2nd digit
    numString = `${numArray[0]}.${numArray[1].substring(0, 2)}`;
  }
  return numString;
};
export const integer = (num) => {
  let numString = num.toString();
  let numArray = numString.split(".");
  return numArray[0];
};

export const randomizeSequence = (gains) => {
  let num = [...gains];
  let counter = num.length;
  while (counter > 0) {
    let index = getRandomInt(counter);
    counter--;
    [num[counter], num[index]] = [num[index], num[counter]];
  }
  return num;
};

export const randomizeSequencePair = ({ gains1, gains2 }) => {
  let num1 = [...gains1];
  let num2 = [...gains2];
  let counter = num1.length;
  while (counter > 0) {
    let index = getRandomInt(counter);
    counter--;
    [num1[counter], num1[index]] = [num1[index], num1[counter]];
    [num2[counter], num2[index]] = [num2[index], num2[counter]];
  }

  return { gainOrder: num1, gainOrder2: num2 };
};

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
export const formatPercent = (value) => {
  return `${decimal2(value * 100)}%`;
};
export const formatPercentInteger = (value) => {
  return `${Math.floor(0.5 + value * 100)}%`;
};
export const formatPercentNoSign = (value) => {
  return `${decimal2(value * 100)}`;
};
export const addPercentSign = (value) => {
  return `${decimal2(value)}%`;
};

export const formatAverage = (value) => {
  return decimal2(value);
};
export const formatAverageInteger = (value) => {
  return integer(value);
};
export const formatCurrency = (value) => {
  if (value === undefined) return "";
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
