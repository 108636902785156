import React, { Fragment } from "react";
import { Image, View } from "@react-pdf/renderer";
import styles from "../quote-page.styles";

const PdfBenefitsChart = ({ chart, loc }) => {
  return (
    <Fragment>
      <View
        style={[loc]}
        // style={[
        //   styles.absolute,
        //   { top: top },
        //   { left: left },
        //   { width: width },
        //   { height: height },
        //   // styles.bgChart,
        // ]}
      >
        <Image src={chart} />
      </View>
    </Fragment>
  );
};

export default PdfBenefitsChart;
