import React, { Fragment } from "react";
import AnalyzerSummaryField from "./analyzer-summary-field.component";
import {
  formatCurrency,
  formatAverageInteger,
  formatPercent,
} from "../../../../utilities/numbers";

const AnalyzerSummaryFailing = ({ failing }) => {
  return (
    <Fragment>
      <div className='p-shadow-12 p-mb-4 br-9'>
        <div className='card analyzer-failing pad-top-half'>
          <p className='font-subtitle my-p5'>Failing Runs</p>
          <div className='k-flex-between px-2'>
            <AnalyzerSummaryField
              size={3}
              label='Count'
              value={failing.count}
            />
            <AnalyzerSummaryField
              size={3}
              label='Percent'
              value={formatPercent(failing.percent)}
            />
            <AnalyzerSummaryField
              size={6}
              label='Total Taxes'
              value={formatCurrency(failing.totalTaxes)}
            />
            <AnalyzerSummaryField
              size={12}
              label='Avg Year Balance Ran Out'
              value={formatAverageInteger(failing.averageYear)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AnalyzerSummaryFailing;
