import React from "react";

import LoginRegisterPasswords from "../../components/login-register-passwords/login-register-passwords.component";
const ResetPasswordPage = ({
  match: {
    params: { token },
  },
}) => {
  return <LoginRegisterPasswords reset={token} />;
};
export default ResetPasswordPage;
