import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import NumberInput from "../../../../lib/number-input";
import CurrencyInput from "../../../../lib/currency-input";
import IncomeTable from "./income-table";
import { incomesParams } from "../setup-params";

const AdditionalIncome = ({ handlers, inputs, incomes }) => {
  const { handleInputIncome, handleAddIncome, handleDeleteIncome } = handlers;

  return (
    <div className='p-shadow-12 br-9'>
      <Card className='stock-calculator'>
        <div className='p-fluid p-grid p-formgrid'>
          <CurrencyInput
            constants={incomesParams.incomeAmount}
            value={inputs.incomeAmount}
            handler={handleInputIncome}
          />
          <div className='p-col-2' />
          <NumberInput
            constants={incomesParams.incomeStart}
            value={inputs.incomeStart}
            handler={handleInputIncome}
          />
          <NumberInput
            constants={incomesParams.incomeEnd}
            value={inputs.incomeEnd}
            handler={handleInputIncome}
          />

          <div className='p-field p-col-2'>
            {incomes.length === 0 && (
              <Button
                onClick={handleAddIncome}
                label='Income'
                className='p-button-success k-mt-27px'
              />
            )}
          </div>
        </div>
        <IncomeTable tableData={incomes} handleDelete={handleDeleteIncome} />
      </Card>
    </div>
  );
};

export default AdditionalIncome;
