import React, { Fragment } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import ShowUsers from "./users/show-users.component";

const AdminPanel = () => {
  return (
    <Fragment>
      <div className='p-text-center'>
        <h1>Admin Panel</h1>
        <div className='p-grid p-my-6 container'>
          <div className='p-col-12'>
            <TabView>
              <TabPanel header='All Users'>
                <ShowUsers />
              </TabPanel>
              {/* <TabPanel header='Subscriptions'>
                <ShowSubscriptions />
              </TabPanel> */}
            </TabView>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminPanel;
