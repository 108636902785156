import React, { Fragment, useState, useEffect } from "react";
import { InputNumber } from "primereact/inputnumber";

const InputFieldIntegerDual = ({
  handler,
  variables: { value, disabled, showError },
  constants: { label, medSize, id, textError, name, minimum, maximum },
  labelSwitchInput,
  alternateLabel,
}) => {
  const [labelSwitch, setLabelSwitch] = useState(false);
  useEffect(() => {
    labelSwitchInput === "W" ? setLabelSwitch(true) : setLabelSwitch(false);
  }, [labelSwitchInput]);
  return (
    <Fragment>
      <div className={`p-field p-col-4 p-md-${medSize}`}>
        <label htmlFor={id}>{labelSwitch ? alternateLabel : label}</label>
        <InputNumber
          name={name}
          id={id}
          min={minimum}
          max={maximum}
          value={value}
          onValueChange={handler}
          disabled={disabled}
          placeholder='0'
        />
        {showError && (
          <small id={`${id}-help`} className='p-invalid p-d-block'>
            {textError}
          </small>
        )}
      </div>
    </Fragment>
  );
};

export default InputFieldIntegerDual;
