import React,{Fragment} from 'react';
import {Text, View} from '@react-pdf/renderer';
import { currency, percentage, decimal2 } from "../../../utilities/numbers";
import {
  benefitTypeOptions,
  modePeriodOptions,
} from "../../../assets/data/products.data";
import styles from '../quote-page.styles';

const P2TableRow = ({ benefit, skipPremium }) => {
  const Design = () => {
    let removeCondionals = "";
    let bto = benefitTypeOptions.find(
      (ben) => ben.value === benefit.benefitType
    ).designer;
    const conditionals = bto.split("YYY");
    if (conditionals.length > 2) {
      let show = false;
      conditionals.forEach((element, index) => {
        if (index % 3 === 0) removeCondionals += element;
        if (index % 3 === 1) {
          if (benefit[element] > 0) show = true;
          else show = false;
        }
        if (index % 3 === 2 && show) removeCondionals += element;
      });
    } else removeCondionals += bto;
    const unformatted = removeCondionals.split("XXX");
    let displayText = "";
    unformatted.forEach((element, index) => {
      if (index % 2 === 1) {
        if (element === "modePeriod")
          displayText += modePeriodOptions.find(
            (per) => per.value === benefit.modePeriod
          ).period;
        if (element === "colaPercent")
          displayText += percentage(decimal2(benefit.colaPercent));
        if (element === "startDate" || element === "endDate")
          displayText += benefit[element];
        //displayText += new Date(benefit[element]).toLocaleDateString();
        if (
          element === "benefitAmount" ||
          element === "annuityPremium" ||
          element === "guaranteedBenefit" ||
          element === "expectedBenefit"
        )
          displayText += currency(benefit[element]);
        if (element === "termYearsMonths") {
          if (benefit.modePeriod === "W")
            displayText += `${benefit.termYears} weeks`;
          else {
            if (benefit.termYears > 0 && benefit.termMonths > 0)
              displayText += `${benefit.termYears} years and ${benefit.termMonths} months`;
            else if (benefit.termYears > 0)
              displayText += `${benefit.termYears} years`;
            else if (benefit.termMonths > 0)
              displayText += `${benefit.termMonths} months`;
          }
        }
      } else {
        if (index >= 1) displayText += element;
      }
    });

    return (
      <Fragment>
        <View>
          <Text style={[styles.text10]}>
            <Text
              style={[styles.text12, styles.bold]}
            >{`${benefit.benefitTitle}\n`}</Text>
            <Text style={[styles.text12, styles.bold]}>{unformatted[0]}</Text>
            {displayText}
          </Text>
        </View>
      </Fragment>
    );
  };

  return (
    <View style={[styles.table_row, styles.margin10_top]}>
      <View style={[styles.table_col1]}>
        <Design />
      </View>
      {skipPremium === false ? (
        <View style={styles.table_col}>
          <Text style={[styles.text12, styles.center]}>
            {currency(benefit.annuityPremium)}
          </Text>
        </View>
      ) : (
        <View style={styles.table_col} />
      )}
      <View style={styles.table_col}>
        <Text style={[styles.text12, styles.center]}>
          {currency(benefit.guaranteedBenefit)}
        </Text>
      </View>
      <View style={styles.table_col}>
        <Text style={[styles.text12, styles.center]}>
          {currency(benefit.expectedBenefit)}
        </Text>
      </View>
    </View>
  );
};

export default P2TableRow;
