export default class TaxAdjusted {
  constructor(income, gain, filing) {
    this.income = income;
    this.gain = gain;
    this.filing = filing;
    this.taxBracket1 = this.findTaxBracket(this.income + this.gain);
    this.taxBracket2 = this.findTaxBracket(this.income);
    this.tax1 = this.findTax(this.taxBracket1, this.income + this.gain);
    this.tax2 = this.findTax(this.taxBracket2, this.income);
    this.tax = this.tax1 - this.tax2;
    this.taxPercent = gain>0 ? this.findTaxPercent() : 0;
  }

  findTaxBracket(value) {
    let max = this.filing.length - 1;
    while (max) {
      if (value >= this.filing[max].bracket) return max * 1;
      else max--;
    }
    return 0;
  }

  findTax(taxBracket, value) {
    const { rate, bracket, accumulated } = this.filing[taxBracket];
    let taxBase = accumulated;
    let taxAdditional = rate * (value - bracket);
    return taxBase + taxAdditional;
  }

  findTaxPercent = () => {
    let taxPercent = (100 * this.tax) / this.gain;
    return taxPercent;
  };
}
