import React from "react";
import { Document } from "@react-pdf/renderer";

import QuotePage1 from "./quote-page-1.component.jsx";
import QuotePage2 from "./quote-page-2.component.jsx";
import QuotePage3 from "./quote-page-3.component.jsx";
import QuotePage4 from "./quote-page-4.component.jsx";

const PdfDocument = ({ pdfData, image3Url, image4Url }) => {
  return (
    <Document>
      <QuotePage1 details={pdfData} />
      {pdfData.benefits.length >= 1 && (
        <QuotePage2
          details={pdfData}
          benefits={pdfData.benefits}
          pagebreak={pdfData.pageBreak}
        />
      )}
      {pdfData.pageBreak > 0 && pdfData.pageBreak < pdfData.benefits.length && (
        <QuotePage3
          details={pdfData}
          benefits={pdfData.benefits}
          pagebreak={pdfData.pageBreak}
        />
      )}
      {pdfData.showPlots && (
        <QuotePage4
          details={pdfData}
          image3Url={image3Url}
          image4Url={image4Url}
        />
      )}
    </Document>
  );
};

export default PdfDocument;
