import React from "react";
import { Checkbox } from "primereact/checkbox";
import TooltipAddon from "./tooltip-addon";

const CheckboxInput = ({ constants, value, handler }) => {
  const { id, label, size, tt } = constants;

  return (
    <div className={`p-field p-col-${size} p-text-center`}>
      <label
        id={`label-${id}`}
        className='p-text-center big-bold-label'
        htmlFor={id}
      >
        {label}
      </label>
      {tt != undefined && <TooltipAddon tt={tt} element={`#label-${id}`} />}
      <div className='w-100pct p-d-flex '>
        <Checkbox
          name={id}
          id={id}
          className='pad-top-7px marg-auto '
          onChange={handler}
          checked={value}
        />
      </div>
    </div>
  );
};

export default CheckboxInput;
