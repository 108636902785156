import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import NumberInput from "../../../../lib/number-input";
import CurrencyInput from "../../../../lib/currency-input";
import DecimalInput from "../../../../lib/decimal-input";
import DrawsTable from "./draws-table";
import { drawsParams } from "../setup-params";

const DrawInputs = ({ handlers, inputs, draws }) => {
  const { handleInputDraw, handleAddDraw, handleDeleteDraw } = handlers;

  return (
    <div className='p-shadow-12 br-9'>
      <Card className='stock-calculator'>
        <div className='p-fluid p-grid p-formgrid'>
          <CurrencyInput
            constants={drawsParams.drawAmount}
            value={inputs.drawAmount}
            handler={handleInputDraw}
          />
          <DecimalInput
            constants={drawsParams.drawCola}
            value={inputs.drawCola}
            handler={handleInputDraw}
          />
          <NumberInput
            constants={drawsParams.drawStart}
            value={inputs.drawStart}
            handler={handleInputDraw}
          />
          <NumberInput
            constants={drawsParams.drawEnd}
            value={inputs.drawEnd}
            handler={handleInputDraw}
          />
          <div className='p-field p-col-2'>
            <Button
              onClick={handleAddDraw}
              label='Draw'
              className='p-button-danger k-mt-27px'
            />
          </div>
        </div>
        <DrawsTable tableData={draws} handleDelete={handleDeleteDraw} />
      </Card>
    </div>
  );
};

export default DrawInputs;
