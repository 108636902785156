import React, { Fragment } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

import PrivateRoute from "./routing/private-route";
import HomePage from "./pages/home-page/home-page.component";
import MyHomePage from "./pages/myHome-page/myHome-page.component";
import RegisterPass from "./pages/register/register-pass.component";
import RegisterFail from "./pages/register/register-fail.component";
import LoginPage from "./pages/login/login-page.component";
import LoginMessagePage from "./pages/login/login-message-page.component";
import ResetPasswordPage from "./pages/reset-password/reset-password-page.component";

import "./App.css";
import "./Settlement.css";

function App() {
  return (
    <Fragment>
      <Router>
        <Switch>
          <PrivateRoute exact path='/my/home' component={MyHomePage} />
          <Route exact path='/' component={HomePage} />
          <Route exact path='/pwd' component={LoginMessagePage} />
          <Route exact path='/register-pass' component={RegisterPass} />
          <Route exact path='/register-fail' component={RegisterFail} />
          <Route exact path='/login/:token' component={LoginPage} />
          <Route exact path='/reset/:token' component={ResetPasswordPage} />
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
