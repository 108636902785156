import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";

const AnnualGrowthParagraph = ({
  percentBonds,
  bondRate,
  gainCalculationType,
}) => {
  let pctStock = gainCalculationType.startsWith("sp500_vbtix_60")
    ? 60
    : 100 - percentBonds;
  let startingYear = "2000";
  if (gainCalculationType === "sp500_vbtix_60_10y") startingYear = "2013";
  if (gainCalculationType === "sp500_vbtix_60_15y") startingYear = "2008";
  const firstString = `Your SSA’s annual growth model assumes ${pctStock}% of the portfolio increases/decreases according to the S&P500 index since ${startingYear} in a randomized order`;
  const secondString = ` and ${percentBonds}% of the portfolio increases at a bond rate of ${bondRate}%.`;
  const secondStringVBTIX = ` and ${
    100 - pctStock
  }% of the portfolio increases at a variable rate according to the VBTIX bond index in concurrence with the S&P500 return for that year. `;
  const secondStringBAGG = ` and ${percentBonds}% of the portfolio increases at a variable rate according to the BAGG bond index in concurrence with the S&P500 return for that year. `;
  let finalString = firstString;
  if (gainCalculationType.startsWith("sp500_vbtix_60"))
    finalString += secondStringVBTIX;
  else if (percentBonds < 0.05) finalString += ".";
  else if (gainCalculationType === "sp500_adjusted")
    finalString += secondString;
  else finalString += secondStringVBTIX;

  return <Text style={[styles.fontm]}>{finalString}</Text>;
};
export default AnnualGrowthParagraph;
