import React, { Fragment, useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";

import InputFieldDropdown from "../input-fields/input-field-dropdown";
import InputFieldText from "../input-fields/input-field-text";
import InputFieldDate from "../input-fields/input-field-date";
import InputFieldIntegerDual from "../input-fields/input-field-integer-dual";
import InputFieldIntegerDualDisable from "../input-fields/input-field-integer-dual-disable";
import InputFieldCurrency from "../input-fields/input-field-currency";
import InputFieldPercentage from "../input-fields/input-field-percentage";
import AddEditButtons from "../button-fields/addEditButtons.component";
import { getSessionStorage, setSessionStorage } from "../../utilities/storage";

import {
  prepareLifeCompanyOptions,
  changeBenefitInput,
  clearBenefits,
  prepareDataForRedux,
  validateInputs,
  loadInputsToEdit
} from "./add-benefit.helpers";

import {
  addBenefit,
  cancelBenefit,
  saveEditBenefit,
  disableBenefitsInPdf,
  enableBenefitsInPdf
} from "../../redux/quote/quote.actions";

import {
  benefitInputs,
  benefitInitInputs,
  benefitAddButtons,
} from "../../assets/data/benefits.data";

const AddBenefit = ({
  quote,
  addBenefit,
  cancelBenefit,
  saveEditBenefit,
  disableBenefitsInPdf,
  enableBenefitsInPdf
}) => {
  const toast = useRef(null);
  const [cardTitle, setCardTitle] = useState("Add Benefit");
  const [fieldInputs, setFieldInputs] = useState(benefitInitInputs);
 
  const setStorageFieldInputs = (newState) => {
    setSessionStorage("ssProposal-benefit", newState);
    setFieldInputs(newState);
  };

  useEffect(() => {
    initializeState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quote.isProposalLoaded) enableLifeCompany();
    else initializeState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote.isProposalLoaded]);

  useEffect(() => {
    if (!quote.isProposalEdit && quote.isProposalLoaded) enableLifeCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote.isProposalEdit]);

  useEffect(() => {
    if (quote.isBenefitEdit) loadEditedBenefit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote.isBenefitEdit]);

  const loadEditedBenefit = async () => {
    let res = await loadInputsToEdit({
      quote: quote.proposal,
      benefit: quote.editBenefit,
    });
    setStorageFieldInputs(res);
  };

  const initializeState = async () => {
    if (!quote.isProposalLoaded || !quote.isBenefitEdit) {
      setCardTitle("Add Benefit");
      await setStorageFieldInputs(benefitInitInputs);
    } else {
      const dataFromStorage = await getSessionStorage("ssProposal-benefit");
      if (dataFromStorage) await setStorageFieldInputs(dataFromStorage);
      else await setStorageFieldInputs(benefitInitInputs);
    }
  };

  const enableLifeCompany = async () => {
    setCardTitle(`Add Benefit to ${quote.proposal.quoteName}`);
    let res = await prepareLifeCompanyOptions(fieldInputs, quote);
    setStorageFieldInputs(res);
  };

  const handleInputChange = async (e) => {
    let newState = changeBenefitInput(fieldInputs, e.target);
    setStorageFieldInputs(newState);
  };

  const handleClear = async () => {
    if (quote.isProposalLoaded) {
      let res = await clearBenefits(fieldInputs.lifeCompanyOptions);
      setStorageFieldInputs(res);
    }
  };

  const showToast=(isSuccess, raisedBy) => {
    const severity= isSuccess ? 'success' : "error"
    const act= isSuccess ? '' : "not "
    const summary=`Benefit was ${act}${raisedBy}`
    const detail=isSuccess ? 'The benefit is added to the pdf file' : 'Check the inputs for errors'
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  }

  const addOrSave = async (isAdd) => {
    await disableBenefitsInPdf();
    const validation = await validateInputs(fieldInputs);
    showToast(validation.isValid,isAdd ? 'Added' : 'Saved')
    if (!validation.isValid) {
      setStorageFieldInputs(validation.result);
      return;
    }
    if (isAdd) {
      const benefit = await prepareDataForRedux({
        fieldInputs: fieldInputs,
        proposal: quote.proposal._id,
      });
    
      await addBenefit(benefit);
    } else {
      const benefit = await prepareDataForRedux({
        fieldInputs: fieldInputs,
        proposal: quote.proposal._id,
        _id: quote.editBenefit._id,
      });
      await saveEditBenefit(benefit);
    }
    await enableBenefitsInPdf();
    handleClear();
  };

  const handleAdd = async () => {
    await addOrSave(true);;
  };
  const handleSave = async () => {
    await addOrSave(false);;
  };

  const handleCancel = async () => {
    await disableBenefitsInPdf();
    await cancelBenefit();
    await enableBenefitsInPdf();
    handleClear();
  };

  const buttonHandlers = {
    handleClear,
    handleAdd,
    handleCancel,
    handleSave,
  };

  return (
    <Fragment>
       <Toast ref={toast} />
      <div className='p-shadow-12 p-mt-4'>
        <Card title={cardTitle}>
          <div className='p-grid'>
            <div className='p-col-6'>
              <div className='p-fluid p-grid p-formgrid'>
                <InputFieldDropdown
                  variables={fieldInputs.lifeCompany}
                  constants={benefitInputs.lifeCompany}
                  handler={handleInputChange}
                  options={fieldInputs.lifeCompanyOptions}
                />
                <InputFieldDropdown
                  variables={fieldInputs.benefitType}
                  constants={benefitInputs.benefitType}
                  handler={handleInputChange}
                  options={fieldInputs.benefitTypeOptions}
                />
                <InputFieldCurrency
                  variables={fieldInputs.benefitAmount}
                  constants={benefitInputs.benefitAmount}
                  handler={handleInputChange}
                />
                <InputFieldDropdown
                  variables={fieldInputs.modePeriod}
                  constants={benefitInputs.modePeriod}
                  handler={handleInputChange}
                  options={fieldInputs.modePeriodOptions}
                />
              </div>
            </div>

            <div className='p-col-6'>
              <div className='p-fluid p-grid p-formgrid'>
                <InputFieldDate
                  variables={fieldInputs.startDate}
                  constants={benefitInputs.startDate}
                  handler={handleInputChange}
                />
                <InputFieldIntegerDual
                  variables={fieldInputs.termYears}
                  constants={benefitInputs.termYears}
                  handler={handleInputChange}
                  labelSwitchInput={fieldInputs.modePeriod.value}
                  alternateLabel='Weeks'
                />
                <InputFieldIntegerDualDisable
                  variables={fieldInputs.termMonths}
                  constants={benefitInputs.termMonths}
                  handler={handleInputChange}
                  labelSwitchInput={fieldInputs.modePeriod.value}
                />
                <InputFieldDate
                  variables={fieldInputs.endDate}
                  constants={benefitInputs.endDate}
                  handler={handleInputChange}
                />
                <InputFieldPercentage
                  variables={fieldInputs.colaPercent}
                  constants={benefitInputs.colaPercent}
                  handler={handleInputChange}
                />
              </div>
            </div>

            <div className='p-col-6'>
              <div className='p-fluid p-grid p-formgrid'>
                <InputFieldCurrency
                  variables={fieldInputs.annuityPremium}
                  constants={benefitInputs.annuityPremium}
                  handler={handleInputChange}
                />
                <InputFieldCurrency
                  variables={fieldInputs.guaranteedBenefit}
                  constants={benefitInputs.guaranteedBenefit}
                  handler={handleInputChange}
                />
                <InputFieldCurrency
                  variables={fieldInputs.expectedBenefit}
                  constants={benefitInputs.expectedBenefit}
                  handler={handleInputChange}
                />
              </div>
            </div>

            <div className='p-col-6'>
              <div className='p-fluid p-grid p-formgrid'>
                <InputFieldText
                  variables={fieldInputs.benefitTitle}
                  constants={benefitInputs.benefitTitle}
                  handler={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className='p-d-flex p-jc-evenly'>
            <AddEditButtons
              isEdit={quote.isBenefitEdit}
              addButtons={benefitAddButtons}
              handlers={buttonHandlers}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  quote: state.quote,
});
export default connect(mapStateToProps, {
  addBenefit,
  cancelBenefit,
  saveEditBenefit,
  disableBenefitsInPdf,
  enableBenefitsInPdf
})(AddBenefit);
