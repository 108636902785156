import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Greeting from "./greeting";
import EmailInput from "./email-input";
import PasswordInput from "./password-input";
import ShowErrors from "./show-errors";
import SubmitButton from "./submit-button";
import { loginUser } from "../../redux/user/user.actions";
import { validateInputs } from "./validators";
import {
  FormContainer,
  XSmallText,
  TextButton,
  XSmallTextRedBold,
} from "./login.styles";

const config = { headers: { "Content-Type": "application/json" } };
const Login = ({ handleClick, loginUser, user, history, token, message }) => {
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const [loginFail, setLoginFail] = useState({
    isFail: false,
    message: "",
  });
  const [userIn, setUserIn] = useState({
    email: "",
    password: "",
  });
  const [isInputValid, setIsInputValid] = useState({
    isEmailValid: true,
    isPasswordValid: true,
    isPasswordConfirmValid: true,
  });

  const { email, password } = userIn;

  useEffect(() => {
    if (token !== undefined) apiConfirmEmail(token);
    if (message !== undefined) setLoginMessage(message);
  }, []);

  useEffect(() => {
    if (user !== undefined && user.email.length > 2) history.push("/my/home");
  }, [user]);

  useEffect(() => {
    if (user.notConfirmed === true)
      setLoginFail({
        isFail: true,
        message:
          "Your email address is not confirmed. We sent you an email with further instructions. ",
      });
  }, [user.notConfirmed]);
  useEffect(() => {
    if (user.incorrectLogin === true)
      setLoginFail({
        isFail: true,
        message: "Incorrect email or password. ",
      });
  }, [user.incorrectLogin]);

  const apiConfirmEmail = async (token) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/users/signup-complete/${token}`,
        config
      );
      if (res.data.status === "success") {
        setLoading(false);
        setConfirmed(true);
        return;
      }
    } catch (err) {
      setLoading(false);
    }
    setLoading(false);
    setLoginFail({
      isFail: true,
      message: "Your email address could not be confirmed.",
    });
  };

  const handleLogin = async (e) => {
    const { emailValid, passwordValid, passwordConfirmValid } =
      validateInputs(userIn);

    setIsInputValid({
      isEmailValid: emailValid,
      isPasswordValid: passwordValid,
      isPasswordConfirmValid: passwordConfirmValid,
    });
    if (!emailValid || !passwordValid || !passwordConfirmValid) return;
    setLoading(true);

    try {
      await loginUser(userIn);
    } catch (err) {}
    setLoading(false);
    return;
  };

  const handleInputChange = (e) => {
    setLoginMessage("");
    // setLoginFail({
    //   isFail: false,
    //   message: "",
    // });
    setUserIn({ ...userIn, [e.target.name]: e.target.value });
  };

  return (
    <FormContainer>
      <div className='p-fluid' id='login-form'>
        <Greeting action='Login' message=' to Your Account' />
        <EmailInput
          email={email}
          handleChange={handleInputChange}
          isValid={isInputValid.isEmailValid}
        />
        <PasswordInput
          password={password}
          handleChange={handleInputChange}
          isValid={isInputValid.isPasswordValid}
          confirm={false}
        />
        <div className='k-flex-between k-mb-2r k-h-34px'>
          <div className='k-flex-start'>
            <input type='checkbox'></input>
            <XSmallText>Remember</XSmallText>
          </div>
          <TextButton
            onClick={() => {
              handleClick("forgot");
            }}
          >
            Forgot Password?
          </TextButton>
        </div>
        <SubmitButton
          buttonText='Sign In'
          loading={loading}
          handleSubmit={handleLogin}
        />

        <div className='k-text-center'>
          {token === undefined ? (
            <TextButton
              onClick={() => {
                handleClick("register");
              }}
            >
              Create Account
            </TextButton>
          ) : (
            confirmed === true && (
              <XSmallTextRedBold>You can now login</XSmallTextRedBold>
            )
          )}
          <ShowErrors
            emailError={!isInputValid.isEmailValid}
            passwordError={!isInputValid.isPasswordValid}
            registerFail={loginFail}
            message={message}
          />
        </div>
      </div>
    </FormContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps, { loginUser })(Login));
