import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { cumulativeOptions, stackedOptions } from "./stacked-options";
import { delay } from "../../utilities/numbers";
import compileBenefitsData from "./compile-benefits-data";

const BenefitsGraph = ({ quote, claimant, setImage3Url, setImage4Url }) => {
  const [isReady, setIsReady] = useState(false);
  const [stackedData, setStackedData] = useState(null);
  const [stackedCumulative, setStackedCumulative] = useState(null);
  
  useEffect(() => {
    if (quote.proposal !== null) checkBenefits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote.proposal]);

  useEffect(() => {
    setImage3Url(null);
    if (stackedData !== null) makeImage3();
    // eslint-disable-next-line
  }, [stackedData]);

  useEffect(() => {
    setImage4Url(null);
    if (stackedCumulative !== null) makeImage4();
    // eslint-disable-next-line
  }, [stackedCumulative]);

  useEffect(() => {
    if (isReady === true) makeImages();
    // eslint-disable-next-line
  }, [isReady]);

  const makeImages = async () => {
    await makeImage3();
    await makeImage4();
    return true;
  };
  const checkBenefits = async () => {
    setIsReady(false);
    if (quote.isProposalLoaded === false) return;
    if (quote.proposal.benefits.length === 0) return;

    let age = claimant.claimants.find(
      (claim) => claim._id === quote.proposal.claimantsName
    ).age;
    let temp = compileBenefitsData(quote.proposal.benefits, age);
    setStackedData(temp.stackedData);
    setStackedCumulative(temp.cumData);
    setIsReady(true);
  };

  const makeImage3 = async () => {
    await delay(1000);
    let canvas3 = document.querySelector("#benefits-details-chart>canvas");
    canvas3.toBlob(async (blob) => {
      const dataURI3 = canvas3.toDataURL();
      setImage3Url(dataURI3);
    });
  };
  const makeImage4 = async () => {
    await delay(1000);
    let canvas4 = document.querySelector("#benefits-cumulative-chart>canvas");
    canvas4.toBlob(async (blob) => {
      const dataURI4 = canvas4.toDataURL();
      setImage4Url(dataURI4);
    });
  };

  return (
    <Fragment>
      <div className='p-shadow-12 p-mt-4'>
        <Card>
          <Chart
            id='benefits-details-chart'
            type='bar'
            data={stackedData}
            options={stackedOptions}
          />
        </Card>
      </div>
      <div className='p-shadow-12 p-mt-4'>
        <Card>
          <Chart
            id='benefits-cumulative-chart'
            type='bar'
            data={stackedCumulative}
            options={cumulativeOptions}
          />
        </Card>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  quote: state.quote,
  claimant: state.claimant,
});
export default connect(mapStateToProps, null)(BenefitsGraph);
