import { StyleSheet } from "@react-pdf/renderer";

const LEFT = 72;
const RIGHT = 72;
const TOP = 50;
const BOTTOM = 50;
const MBOTTOM = 3;
const MBOTTOM2 = 2;
const MRIGHT = 1;
const MLEFT = 10;
const MRIGHT2 = 10;
const MRIGHT3 = 6;
const MLEFT2 = 10;
const MLEFT3 = 6;
const MTOP = 3;
const MTOP2 = 10;
const MROWS = 1;
const MROW = 10;
const FONTXS = 8;
const FONTS = 10;
const FONTM = 12;
const FONTL = 14;
const FONTSUBTITLE = 26;
const FONTTITLE = 36;
const BORDER_COLOR = "#ffffff";
const BORDER_STYLE = "solid";
const COLUMN_WIDTH = 80;
const BORDER_ROUND = 10;

const styles = StyleSheet.create({
  bgRed: {
    color: "#c0303040",
  },
  positionUnsubscribe: {
    position: "absolute",
    left: 0,
    top: 770,
    width: 612,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  positionTitle: {
    position: "absolute",
    left: 0,
    top: 150,
    width: 612,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  positionLogo: {
    position: "absolute",
    left: 50,
    top: 25,
  },
  positionDisclaimer: {
    position: "absolute",
    left: 0,
    bottom: 25,
    width: 612,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  positionHeaderPage1: {
    position: "absolute",
    left: 0,
    top: 30,
    width: 612,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  positionFor: {
    position: "absolute",
    left: 0,
    top: 350,
    width: 612,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  positionBy: {
    position: "absolute",
    left: -25,
    bottom: 30,
    width: 612,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  canvasTopLeft: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 150,
    height: 150,
  },
  canvasBottomRight: {
    position: "absolute",
    left: 462,
    top: 642,
    width: 150,
    height: 150,
  },
  pageImage: {
    left: 0,
    top: 0,
    width: 612,
    height: 792,
    position: "absolute",
    opacity: 0.3,
  },
  pageImage2: {
    left: 0,
    top: 0,
    width: 612,
    height: 792,
    position: "absolute",
    opacity: 0.2,
  },
  pieImage: {
    objectFit: "cover",
    width: "100%",
    // maxHeight: "100%",
    height: "100%",
  },
  page: {
    // paddingTop: 40,
    // paddingBottom: 40,
    // paddingLeft: 50,
    // paddingRight: 30,
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  bgChart: {
    backgroundColor: "#e0e0e0",
  },
  outline: {
    margin: 10,
    flexGrow: 1,
  },
  outlineBorder: {
    marginTop: 80,
    marginRight: 10,
    marginLeft: 10,
    marginBottom: 30,
    flexGrow: 1,
    border: "2pt solid black",
  },
  header: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  pageMargins: {
    marginTop: 54,
    marginLeft: 54,
    marginRight: 54,
    marginBottom: 54,
  },
  header_right: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  canvasCenterLine: {
    position: "absolute",
    width: 595.28,
    height: 769.89,
  },
  presented: {
    position: "absolute",
    bottom: 10,
    left: 10,
    right: 10,
  },
  leftColumn: {
    position: "absolute",
    left: 20,
    top: 10,
  },
  rightColumn: {
    position: "absolute",
    left: 320,
    top: 10,
  },
  reset: {
    position: "static",
  },
  border: {
    border: "1pt solid black",
  },
  borderTop: {
    borderTop: "1pt solid black",
  },
  rounded_border: {
    border: "1pt solid black",
    borderTopLeftRadius: BORDER_ROUND,
    borderTopRightRadius: BORDER_ROUND,
    borderBottomLeftRadius: BORDER_ROUND,
    borderBottomRightRadius: BORDER_ROUND,
  },
  table_border: {
    border: "2pt solid black",
  },
  margins: {
    top: TOP,
    bottom: BOTTOM,
    left: LEFT,
    right: RIGHT,
  },
  // logo: {
  //   height: 60,
  //   objectFit: "contain",
  // },
  tinted: {
    backgroundColor: "#eeeeee",
  },
  lessOpaque: {
    opacity: 0.2,
  },
  logoCover: {
    position: "absolute",
    right: 25,
    top: 25,
    height: 60,
    maxWidth: 200,
    maxHeight: 60,
    objectFit: "contain",
  },
  logoCoverBottom: {
    position: "absolute",
    left: 10,
    top: 722,
    height: 60,
    width: 60,
    opacity: 0.75,
  },
  logoPage1: {
    position: "absolute",
    left: 15,
    top: 25,
    height: 60,
    maxWidth: 200,
    maxHeight: 60,
    objectFit: "contain",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  abs_flex_row_sb: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flex_row_sb: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flex_row_c: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  flex_col_sb: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  mtop: {
    marginTop: MTOP,
  },
  mtop2: {
    marginTop: MTOP2,
  },
  mbottom: {
    marginBottom: MBOTTOM,
  },
  mbottom2: {
    marginBottom: MBOTTOM2,
  },
  mrow: {
    marginTop: MROW,
  },
  mrows: {
    marginTop: MROWS,
  },
  mleft: {
    marginLeft: MLEFT,
  },
  mright2: {
    marginRight: MRIGHT2,
  },
  mleft2: {
    marginLeft: MLEFT2,
  },
  mright3: {
    marginRight: MRIGHT3,
  },
  mleft3: {
    marginLeft: MLEFT3,
  },
  mright: {
    marginLeft: MRIGHT,
  },
  left: {
    textAlign: "left",
  },
  center: {
    // display: "flex",
    // alignSelf: "center",
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  fontxs: {
    fontSize: FONTXS,
    fontFamily: "Open Sans",
  },
  fonts: {
    fontSize: FONTS,
    fontFamily: "Open Sans",
  },
  fontm: {
    fontSize: FONTM,
    fontFamily: "Open Sans",
  },
  fontl: {
    fontSize: FONTL,
    fontFamily: "Open Sans",
  },
  fonttitle: {
    fontSize: FONTTITLE,
    fontFamily: "Open Sans",
  },
  fontsubtitle: {
    fontSize: FONTSUBTITLE,
    fontFamily: "Open Sans",
  },
  bold: {
    fontWeight: 600,
  },
  underline: {
    textDecoration: "underline",
  },
  italic: {
    textDecoration: "italic",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table_col_header: {
    width: COLUMN_WIDTH + "%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    //borderBottomColor: '#000',
    //borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  table_row: {
    // margin: "auto",
    flexDirection: "row",
  },
});

export default styles;
