export const ClaimantActionTypes = {
  GET_CLAIMANTS_PASS: " GET_CLAIMANTS_PASS",
  ADD_CLAIMANT_PASS: "ADD_CLAIMANT_PASS",
  START_EDIT_CLAIMANT: "START_EDIT_CLAIMANT",
  CANCEL_EDIT_CLAIMANT: "CANCEL_EDIT_CLAIMANT",
  FINISH_EDIT_CLAIMANT: "FINISH_EDIT_CLAIMANT",
  DELETE_CLAIMANT_PASS: "DELETE_CLAIMANT_PASS",
  GET_CLAIMANTS_FAIL: " GET_CLAIMANTS_FAIL",
  ADD_CLAIMANT_FAIL: "ADD_CLAIMANT_FAIL",
  EDIT_CLAIMANT_FAIL: "EDIT_CLAIMANT_FAIL",
  DELETE_CLAIMANT_FAIL: "DELETE_CLAIMANT_FAIL",
  UNLOAD_CLAIMANT: "UNLOAD_CLAIMANT",
};
