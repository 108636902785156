import React from "react";
import { Dropdown } from "primereact/dropdown";
import TooltipAddon from "./tooltip-addon";

const DropdownInput = ({ constants, options, value, handler }) => {
  const { id, label, optionLabel, placeholder, size, outer, tt } = constants;
  return (
    <div className={`p-field p-col-${size} ${outer}`}>
      <label id={`label-${id}`} className='big-bold-label' htmlFor={id}>
        {label}
      </label>
      {tt != undefined && <TooltipAddon tt={tt} element={`#label-${id}`} />}
      <Dropdown
        name={id}
        value={value}
        options={options}
        onChange={handler}
        optionLabel={optionLabel}
        filter
        showClear
        filterBy={optionLabel}
        placeholder={placeholder}
        className={`${
          value === null || value === undefined ? "p-invalid" : ""
        }`}
      />
    </div>
  );
};

export default DropdownInput;
