import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import DropdownInput from "../../../../lib/dropdown-input";
import {
  saveSelectedBroker,
  saveSelectedClaimant,
} from "../../../../redux/analyzer/analyzer.actions";
import { brokerClaimantParams } from "../setup-params";

const BrokerAndClaimant = ({
  claimant,
  broker,
  analyzer: { selectedBroker, selectedClaimant },
  saveSelectedBroker,
  saveSelectedClaimant,
}) => {
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [claimantOptions, setClaimantOptions] = useState([]);

  useEffect(() => {
    if (broker.brokers !== null) setBrokerOptions(broker.brokers);
  }, [broker]);
  useEffect(() => {
    if (claimant.claimants !== null) setClaimantOptions(claimant.claimants);
  }, [claimant]);

  const handleBrokerChange = (e) => {
    saveSelectedBroker(e.target.value);
  };
  const handleClaimantChange = (e) => {
    saveSelectedClaimant(e.target.value);
  };

  return (
    <Card className=' broker-claimant' title='Broker and Claimant'>
      <div className='p-fluid p-grid p-formgrid'>
        <DropdownInput
          constants={brokerClaimantParams.selectBroker}
          options={brokerOptions}
          value={selectedBroker}
          handler={handleBrokerChange}
        />
        <DropdownInput
          constants={brokerClaimantParams.selectClaimant}
          options={claimantOptions}
          value={selectedClaimant}
          handler={handleClaimantChange}
        />
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  claimant: state.claimant,
  broker: state.broker,
  analyzer: state.analyzer,
});
export default connect(mapStateToProps, {
  saveSelectedBroker,
  saveSelectedClaimant,
})(BrokerAndClaimant);
