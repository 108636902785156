import React, { Fragment } from "react";
import { Image, View, Text } from "@react-pdf/renderer";
import {
  formatAverageInteger,
  formatCurrency,
} from "../../../../../utilities/numbers";
import TextItem from "./text-item.view";
import styles from "../pdf-analyzer.styles";

const PdfPie = ({
  summary,
  // imgBackground,
  chart,
  coordinates: { top, left, width, height },
}) => {
  return (
    <Fragment>
      {/* <View
        style={[
          styles.tinted,
          styles.rounded_border,
          styles.absolute,
          { top: top },
          { left: left },
          { width: width },
          { height: height },
        ]}
      >
        <Image src={imgBackground} />
      </View> */}

      <View
        style={[
          styles.tinted,
          styles.rounded_border,
          styles.absolute,
          { top: top },
          { left: left },
          { width: width },
          { height: height },
        ]}
      >
        <Text style={[styles.fontm, styles.mrow, styles.bold, styles.center]}>
          Outcomes
        </Text>
        <View style={[styles.flex_row_sb, styles.mrow]}>
          <TextItem
            label='Passing Count'
            value={summary.passing.count}
            width={width / 2}
          />
          <TextItem
            label='Failing Count'
            value={summary.failing.count}
            width={width / 2}
          />
        </View>
        <View style={[styles.flex_row_sb, styles.mrow]}>
          <TextItem
            label='Avg Final Bal'
            value={formatCurrency(summary.passing.averageBalance)}
            width={width / 2}
          />
          <TextItem
            label='Avg Year Bal Ran Out'
            value={formatAverageInteger(summary.failing.averageYear)}
            width={width / 2}
          />
        </View>
        <Image
          style={[
            styles.pieImage,
            styles.relative,
            { top: 15 },
            { left: 10 },
            { width: 205 },
            { height: 220 },
          ]}
          src={chart}
        />
      </View>
    </Fragment>
  );
};

export default PdfPie;
