import axios from "axios";
import { UserActionTypes } from "./user.types";

const config = { headers: { "Content-Type": "application/json" } };

export const loginUser = (userParams) => async (dispatch) => {
  const output = await logUser(userParams, dispatch);
  if (output.type === "LOGIN_SUCCESS") await loadUser(dispatch);
};

// Just to get the token
export const logUser = async (userParams, dispatch) => {
  try {
    const res = await axios.post("/api/v1/users/login", userParams, config);
    if (res.data.status !== undefined && res.data.status === "email") {
      throw "email";
    }
    return dispatch({
      type: UserActionTypes.LOGIN_SUCCESS,
    });
  } catch (err) {
    if (err === "email") {
      return dispatch({
        type: UserActionTypes.LOGIN_NOT_CONFIRMED,
      });
    }
    return dispatch({
      type: UserActionTypes.LOGIN_FAIL,
    });
  }
};

const loadUser = async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/users/me", { withCredentials: true });
    const userInfo = {
      email: res.data.data.data.email,
      role: res.data.data.data.role,
    };

    return dispatch({ type: UserActionTypes.USER_LOADED, payload: userInfo });
  } catch (err) {
    return dispatch({
      type: UserActionTypes.AUTH_ERROR,
    });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await axios.get("/api/v1/users/logout", {
      withCredentials: true,
    });

    return dispatch({ type: UserActionTypes.LOGOUT });
  } catch (err) {
    return dispatch({ type: UserActionTypes.LOGOUT });
  }
};
