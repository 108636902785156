import React, { Fragment, useEffect, useState } from "react";
import { Font, PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "./pdf-document.component";
import { connect } from "react-redux";

import { lifeCompanies } from "../../assets/data/products.data";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
Font.registerHyphenationCallback((word) => [word]);

const PdfDesigner = ({ claimant, broker, quote, image3Url, image4Url }) => {
  const [pdfData, setPdfData] = useState(null);
  const [fn, setFn] = useState({ ready: false, name: "" });

  useEffect(() => {
    initializeData();
    // eslint-disable-next-line
  }, [quote.proposal]);

  const initializeData = () => {
    if (quote.isProposalLoaded) {
      const claimantsName = () => {
        return claimant.claimants.find(
          (claim) => claim._id == quote.proposal.claimantsName
        );
      };
      const brokerName = () => {
        return broker.brokers.find(
          (broke) => broke._id == quote.proposal.brokerName
        );
      };
      const lifeCompany = () => {
        return quote.proposal.lifeCompany.map((life) => {
          return lifeCompanies.find((lifeCo) => lifeCo.value === life);
        });
      };
      let pdf = { ...quote.proposal };
      pdf["claimantsName"] = claimantsName();
      pdf["brokerName"] = brokerName();
      pdf["lifeCompany"] = lifeCompany();
      pdf["benefits"] = quote.proposal.benefits;
      setPdfData(pdf);
      let date = new Date().toLocaleDateString().replace(/[^a-z0-9]/gi, "_");
      let qn = `${pdf.quoteName.replace(/[^a-z0-9]/gi, "_")}`;
      setFn({ ready: true, name: `${qn}_${date}` });
    }
  };

  return (
    fn.ready === true && (
      <Fragment>
        <PDFViewer width='80%' height='900px'>
          <PdfDocument
            pdfData={pdfData}
            image3Url={image3Url}
            image4Url={image4Url}
          />
        </PDFViewer>
      </Fragment>
    )
  );
};

const mapStateToProps = (state) => ({
  claimant: state.claimant,
  broker: state.broker,
  quote: state.quote,
});
export default connect(mapStateToProps, null)(PdfDesigner);
