import React, { Fragment } from "react";

import LoginRegisterPasswords from "../../components/login-register-passwords/login-register-passwords.component";

const LoginPage = ({
  match: {
    params: { token },
  },
}) => {
  return (
    <Fragment>
      <LoginRegisterPasswords token={token} />
    </Fragment>
  );
};

export default LoginPage;
