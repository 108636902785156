export const brokerInputs = {
  firmName: {
    label: "Brokerage Firm",
    name: "firmName",
    id: "firm-name",
    medSize: 12,
    required: true,
    minimum: 5,
    maximum: 99999,
    textError: "A Brokerage Firm Name must be at least 5 characters",
  },
  brokerName: {
    label: "Broker",
    name: "brokerName",
    id: "broker-name",
    medSize: 12,
    required: true,
    minimum: 5, // Talk to Aaron. I forgot this requirement
    maximum: 99999,
    textError: "A Broker's Name must be at least 5 characters",
  },
  brokerTitle: {
    label: "Broker Title",
    name: "brokerTitle",
    id: "broker-title",
    medSize: 12,
    required: false,
    minimum: 2,
    maximum: 99999,
    textError: "If entered, A Broker's Title must be at least 2 characters",
  },
  firmStreet: {
    label: "Street Address",
    name: "firmStreet",
    id: "firm-street",
    medSize: 12,
    required: false,
    minimum: 3,
    maximum: 99999,
    textError: "If entered a Street Address must be at least 3 characters",
  },
  firmCity: {
    label: "City/State/Zip",
    name: "firmCity",
    id: "firm-city",
    medSize: 12,
    required: false,
    minimum: 3,
    maximum: 99999,
    textError: "If entered, a City/State/Zip must be at least 3 characters",
  },
  firmPhone: {
    label: "Phone",
    name: "firmPhone",
    id: "firm-phone",
    medSize: 12,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter the phone number",
  },
  firmWebsite: {
    label: "Website",
    name: "firmWebsite",
    id: "firm-website",
    medSize: 12,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter the firm's website",
  },
  firmDisclaimer: {
    label: "Disclaimer",
    name: "firmDisclaimer",
    id: "firm-disclaimer",
    medSize: 12,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter a disclaimer for the firm",
    rows: 3,
    cols: 90,
  },
};
const inp = {
  value: "",
  showError: false,
  disabled: false,
};
const inpDisclaimer = {
  value:
    "Guaranteed payments (if any) flow to the beneficiary upon the death of the annuitant.\nAll payments are income tax-free in accordance with Internal Revenue Code Section 104(a)(1) or 104(a)(2).\nThis proposal is an illustration, not a contract. We reserve the right to correct any clerical errors.",
  showError: false,
  disabled: false,
};
export const brokerInitInputs = {
  firmName: inp,
  brokerName: inp,
  brokerTitle: inp,
  firmStreet: inp,
  firmCity: inp,
  firmPhone: inp,
  firmWebsite: inp,
  firmDisclaimer: inpDisclaimer,
};

export const brokerInitColors = {
  format: "solid",
  color0: "ffffff",
  color1: "ffffff",
  color2: "ffffff",
};

export const brokerAddButtons = {
  cancel: {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-raised p-button-warning",
  },
  save: {
    label: "Save Changes",
    icon: "pi pi-plus",
    className: "p-button-raised",
  },
  clear: {
    label: "Clear",
    icon: "pi pi-times",
    className: "p-button-raised p-button-warning",
  },
  add: {
    label: "Add Brokerage",
    icon: "pi pi-plus",
    className: "p-button-raised",
  },
};
