import React, { Fragment } from "react";
import { Page, View, Text, Image } from "@react-pdf/renderer";
import PdfConditions from "../views/pdf-conditions.view";
import COVER from "../../../../../assets/images/black-line-background.jpg";
// import COVER from "../../../assets/images/low_poly.jpg";
//import BGCONDITIONS from "../../../assets/images/conditions.jpg";
import PdfChart from "../views/pdf-chart.view";
import PdfPie from "../views/pdf-pie.view";
import styles from "../pdf-analyzer.styles";

const Page3 = ({ imgSrc, imgPie, summary }) => {
  return (
    <Fragment>
      <Page size='letter' object-fit='fill'>
        <View wrap={true}>
          <Image src={COVER} style={[styles.pageImage2]} />
        </View>
        <View style={styles.positionHeaderPage1}>
          <Text style={[styles.fontsubtitle, styles.center, styles.bold]}>
            Analysis Results
          </Text>
        </View>
        <View style={styles.positionDisclaimer}>
          <Text style={[styles.fontxs, styles.center]}>
            This analysis shows only projected outcomes and are not guaranteed.
            Actual results will vary with market fluctuation and investment
            options
          </Text>
        </View>
        <View style={[styles.outline]} wrap={true}>
          <PdfConditions
            conditions={summary.conditions}
            coordinates={{ top: 100, left: 72, width: 225, height: 350 }}
          />
          <PdfPie
            summary={summary}
            chart={imgPie}
            coordinates={{ top: 100, left: 315, width: 225, height: 350 }}
          />
          <PdfChart
            chart={imgSrc}
            coordinates={{ top: 470, left: 72, width: 468, height: 250 }}
          />
        </View>
      </Page>
    </Fragment>
  );
};

export default Page3;
