import React from "react";
import { XSmallTextNC, ColorBlack } from "./login.styles";

const ForgotInstructions = () => {
  return (
    <div className='p-field k-mb-2r k-text-center'>
      <XSmallTextNC>
        <ColorBlack>Enter your email address</ColorBlack>
      </XSmallTextNC>
      <XSmallTextNC>
        <ColorBlack>and we'll send you reset instructions</ColorBlack>
      </XSmallTextNC>
    </div>
  );
};

export default ForgotInstructions;
