import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";

import BrokerLogo from "./broker-logo.component";
import ColorPickerDisclaimer from "./color-picker-disclaimer.component";
import AddBrokerColumn from "./add-broker-column.component";
import InputFieldText from "../input-fields/input-field-text";
import AddEditButtons from "../button-fields/addEditButtons.component";

import { getSessionStorage, setSessionStorage } from "../../utilities/storage";
import {
  createReduxItem,
  prepareEditInputs,
  inputsAreValid,
} from "./add-broker.helpers";

import {
  addFirm,
  cancelFirm,
  saveEditFirm,
  uploadLogo,
  deleteLogo,
} from "../../redux/broker/broker.actions";

import {
  brokerInputs,
  brokerInitInputs,
  brokerInitColors,
  brokerAddButtons,
} from "../../assets/data/brokers.data";

const AddBroker = ({
  user: { role },
  broker,
  addFirm,
  cancelFirm,
  saveEditFirm,
  uploadLogo,
  deleteLogo,
}) => {
  const toast = useRef(null);
  const [cardTitle, setCardTitle] = useState("Add Brokerage Firm");
  const [fieldInputs, setFieldInputs] = useState(brokerInitInputs);
  const [colors, setColors] = useState(brokerInitColors);
  const [displayColors, setDisplayColors] = useState(false);

  const setStorageFieldInputs = (newState) => {
    setSessionStorage("ssProposal-broker", newState);
    setFieldInputs(newState);
  };

  useEffect(() => {
    initializeState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Allow only one broker
  useEffect(() => {
    if (role === "user") {
      setCardTitle("Edit Brokerage Firm");
      if (broker.isEdit) setEditBrokerInputs(broker.editBroker);
      return;
    }
    if (broker.isEdit) {
      setCardTitle("Edit Brokerage Firm");
      setEditBrokerInputs(broker.editBroker);
    } else {
      setCardTitle("Add Brokerage Firm");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broker.isEdit]);

  const initializeState = async () => {
    const dataFromStorage = await getSessionStorage("ssProposal-broker");
    if (!dataFromStorage) return;

    for (const [key, value] of Object.entries(dataFromStorage)) {
      value.showError = false;
    }
    setStorageFieldInputs(dataFromStorage);
  };

  const setEditBrokerInputs = async (editInfo) => {
    setColors({
      format: editInfo.colors.format,
      color0: editInfo.colors.color0,
      color1: editInfo.colors.color1,
      color2: editInfo.colors.color2,
    });
    const res = await prepareEditInputs(fieldInputs, editInfo);
    setStorageFieldInputs(res);
  };

  const onUpload = async (id) => {
    let form = new FormData();
    form.append("image", id.files[0]);
    await uploadLogo(form);
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  const handleColors = (e) => {
    if (e.target.name === "color0" || e.target.name === "color3")
      setColors({ ...colors, color0: e.target.value, color3: e.target.value });
    if (e.target.name === "color1" || e.target.name === "color4")
      setColors({ ...colors, color1: e.target.value, color4: e.target.value });
    if (e.target.name === "color2" || e.target.name === "color5")
      setColors({ ...colors, color2: e.target.value, color5: e.target.value });
    if (e.target.name === "format")
      setColors({ ...colors, [e.target.name]: e.target.value });
  };

  const handleEditLogo = async () => {
    await deleteLogo(broker.logo);
  };

  const handleInputChange = (e) => {
    const newState = {
      ...fieldInputs,
      [e.target.name]: { ...fieldInputs[e.target.name], value: e.target.value },
    };

    setStorageFieldInputs(newState);
  };

  const handleClear = async () => {
    setColors(brokerInitColors);
    setStorageFieldInputs(brokerInitInputs);
  };

  const handleAdd = async () => {
    if (role === "user" && broker.brokers.length > 0) return;
    const validation = await inputsAreValid(fieldInputs, brokerInputs);
    setStorageFieldInputs(validation.result);
    if (validation.valid === false) return;
    const broke = await createReduxItem({
      fieldInputs: fieldInputs,
    });
    broke["colors"] = colors;
    broke["logo"] = broker.logo;
    broke["ar"] = broker.logoAR;

    await addFirm(broke);
    handleClear();
  };

  const handleCancel = async () => {
    cancelFirm();
    handleClear();
  };

  const handleSave = async () => {
    const validation = await inputsAreValid(fieldInputs, brokerInputs);
    setStorageFieldInputs(validation.result);
    if (validation.valid === false) return;
    const broke = await createReduxItem({
      id: broker.editBroker._id,
      fieldInputs: fieldInputs,
    });
    broke["colors"] = colors;
    broke["logo"] = broker.logo;
    broke["ar"] = broker.logoAR;

    await saveEditFirm(broke);
    handleClear();
  };

  const buttonHandlers = {
    handleClear,
    handleAdd,
    handleCancel,
    handleSave,
  };

  return (
    <Fragment>
      <Toast ref={toast} />

      <div className='add-broker-form p-shadow-12'>
        <p className='font-title'>{cardTitle}</p>
        <div className='p-grid'>
          <AddBrokerColumn
            column={1}
            fieldInputs={fieldInputs}
            brokerInputs={brokerInputs}
            handleInputChange={handleInputChange}
          />
          <AddBrokerColumn
            column={2}
            fieldInputs={fieldInputs}
            brokerInputs={brokerInputs}
            handleInputChange={handleInputChange}
          />
          <div className='p-col-4'>
            <div className='p-fluid p-grid p-form-grid'>
              <InputFieldText
                variables={fieldInputs.firmWebsite}
                constants={brokerInputs.firmWebsite}
                handler={handleInputChange}
              />
              {role === "user" &&
              broker.brokers.length > 0 &&
              !broker.isEdit ? (
                <></>
              ) : (
                <BrokerLogo
                  isLogoUploaded={broker.isLogoUploaded}
                  logo={broker.logo}
                  onUpload={onUpload}
                  handleEditLogo={handleEditLogo}
                />
              )}
            </div>
          </div>
        </div>
        <ColorPickerDisclaimer
          fieldInputs={fieldInputs}
          handleInputChange={handleInputChange}
          colors={colors}
          handleColors={handleColors}
          displayColors={displayColors}
          setDisplayColors={setDisplayColors}
        />

        <div className='p-d-flex p-jc-evenly'>
          <AddEditButtons
            isEdit={broker.isEdit}
            addButtons={brokerAddButtons}
            handlers={buttonHandlers}
            disableAdd={role === "user" && broker.brokers.length > 0}
          />
        </div>
        {/* </Card> */}
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  broker: state.broker,
  user: state.user,
});
export default connect(mapStateToProps, {
  addFirm,
  cancelFirm,
  saveEditFirm,
  uploadLogo,
  deleteLogo,
})(AddBroker);
