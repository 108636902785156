import React, { Fragment, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

import { connect } from "react-redux";
import {
  editClaimant,
  deleteClaimant,
} from "../../redux/claimant/claimant.actions";
import { getQuotes } from "../../redux/quote/quote.actions";

const ViewClaimants = ({
  claimant: { claimants, isEdit },
  editClaimant,
  deleteClaimant,
  getQuotes,
}) => {
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    {
      initial: true,
      field: "caseNumber",
      header: "Case Number",
      sortable: true,
    },
    {
      initial: false,
      field: "firstName",
      header: "First Name",
      sortable: true,
    },
    {
      initial: false,
      field: "middleName",
      header: "Middle Name",
      sortable: true,
    },
    { initial: true, field: "lastName", header: "Last Name", sortable: true },
    {
      initial: true,
      field: "name",
      header: "Full Name",
      style: 'width: "40%"',
      sortable: true,
    },
    {
      initial: false,
      field: "displayedDOB",
      header: "DOB",
      style: 'width: "10%"',
      sortable: true,
    },
    {
      initial: true,
      field: "displayedAge",
      header: "Age",
      style: 'width: "10%"',
      sortable: true,
    },
    {
      initial: true,
      field: "ratedAge",
      header: "Rated Age",
      style: 'width: "10%"',
      sortable: true,
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columns.filter((col) => col.initial === true)
  );
  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
      />
    );
  });

  const actionButtons = (rowData) => {
    const handleEdit = async (e) => {
      await editClaimant(rowData);
    };
    const handleDelete = async (e) => {
      confirmDialog({
        message: "This will delete all proposals created for the claimant",
        header: "Confirm Delete",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        baseZIndex: 1000,
        accept: async () => {
          await deleteClaimant(rowData._id);
          await getQuotes();
          toast.current.show({
            severity: "warn",
            summary: "Confirmed",
            detail: "The claimant was deleted",
            life: 3000,
          });
        },
        reject: () => {},
      });
    };

    return (
      <Fragment>
        <Button
          iconPos='left'
          icon='pi pi-pencil'
          className='p-button-rounded p-button-sm p-button-warning p-mr-2'
          onClick={handleEdit}
          disabled={isEdit}
        />
        <Button
          iconPos='left'
          icon='pi pi-trash'
          className='p-button-rounded p-button-sm p-button-danger'
          onClick={handleDelete}
          disabled={isEdit}
        />
      </Fragment>
    );
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const header = (
    <div className='claimant-table-header'>
      {/* <div className='k-w-205p5px' /> */}
      <div style={{ textAlign: "left", paddingRight: "50px" }}>
        <MultiSelect
          value={selectedColumns}
          options={columns}
          optionLabel='header'
          onChange={onColumnToggle}
        />
      </div>
      <p className='font-title'>View Claimants</p>
      <div className='k-w-205p5px'>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            type='search'
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder='Search...'
          />
        </span>
      </div>
    </div>
  );

  return (
    <div className='view-claimants p-shadow-12 p-mt-4'>
      <Toast ref={toast} />

      <div className='view-claimants-table p-shadow-12 p-mt-4'>
        <DataTable
          value={claimants}
          dataKey='id'
          className='p-datatable-striped reduced-height-table'
          header={header}
          globalFilter={globalFilter}
          paginator
          paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
          currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          {columnComponents}
          <Column body={actionButtons} style={{ width: "20%" }} />
        </DataTable>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  claimant: state.claimant,
});
export default connect(mapStateToProps, {
  editClaimant,
  deleteClaimant,
  getQuotes,
})(ViewClaimants);
