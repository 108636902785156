import React, { Fragment, useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import analyzerPlotsOptions from "./analyzer-plots-options";
import Sorter from "../../../../utilities/sorter";

const AnalyzerPlots = ({ results, setPlotUrl }) => {
  const [isReady, setIsReady] = useState(false);
  const [plotData, setPlotData] = useState(null);

  useEffect(() => {
    initializePlot();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isReady === true) makeImage();
    // eslint-disable-next-line
  }, [isReady]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const makeImage = async () => {
    await delay(1000);
    let canvas = document.querySelector("canvas");
    canvas.style.backgroundColor = "#eeeeee80";
    canvas.toBlob(async (blob) => {
      const dataURI = canvas.toDataURL();
      setPlotUrl(dataURI);
    });
  };

  const initializePlot = () => {
    let pData = {};
    let datasets = [];
    const sorter = new Sorter(results, {
      params: ["sortedBalance"],
      select: ["runNumber"],
      orderBy: "descending",
    });
    // const sorter = new Sorter(results, {
    //   params: ["lastYearBalance", "yearBalanceRanOut"],
    //   select: ["runNumber"],
    //   orderBy: "descending",
    // });
    pData["labels"] = sorter.getLabels();
    datasets.push(sorter.getDataset({ set: "Best", color: "#42A5F5" }));
    datasets.push(sorter.getDataset({ set: "Median", color: "#42A526" }));
    datasets.push(sorter.getDataset({ set: "Worst", color: "#FFA726" }));
    // datasets.push(
    //   sorter.getAllDraws({ set: "Annuity Payment", color: "#F04060" })
    // );
    datasets.push({
      label: "Annuity Payment",
      data: results[0].yearlyDraws,
      fill: false,
      borderColor: "#F04060",
      tension: 0.4,
    });
    pData["datasets"] = datasets;
    setPlotData(pData);
    setIsReady(true);
  };

  return (
    <Fragment>
      <div className='p-grid p-text-center'>
        <div className='p-col-12'>
          <h1>Yearly Balance</h1>
          {isReady === true && (
            <Chart type='line' data={plotData} options={analyzerPlotsOptions} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AnalyzerPlots;
