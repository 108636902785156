import React from "react";
import { RadioButton } from "primereact/radiobutton";

const RadioInputGroup = ({ constants, value, handler, isDisabled }) => {
  const { id, label, size } = constants;
  return (
    <div className={`p-field-radiobutton p-ml-5 p-col-${size}`}>
      <RadioButton
        inputId={id}
        name={id}
        onChange={handler}
        checked={value === id}
        disabled={isDisabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioInputGroup;
