import styled from "styled-components";
import background from "../../assets/images/background-resize.jpg";

export const Page = styled.div`
  position: relative;
  height: 100vh;
  background: #000000ff;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-image: url(${background});
    background-size: cover;
    opacity: 0.5;
  }
`;
export const Container = styled.div`
  margin: auto;
  max-width: 900px;
  display: flex;
  flex-align: between;
`;

export const Title = styled.p`
  color: #fff;
  font-size: 60px;
  margin: 0px;
`;
export const SmallText = styled.p`
  color: #fff;
  font-size: 16px;
  margin: 0px;
  opacity: 0.6;
`;
export const SmallTextBlack = styled.p`
  color: #000;
  font-size: 16px;
  margin: 0px;
  opacity: 0.8;
`;
export const XSmallText = styled.p`
  color: #000;
  font-size: 14px;
  margin: 0px 0px 0px 0.5rem;
  opacity: 0.7;
`;
export const XSmallTextNC = styled.p`
  font-size: 14px;
  margin: 0px 0px 0px 0.5rem;
  opacity: 0.7;
`;
export const XSmallTextRedBold = styled.p`
  font-size: 14px;
  margin: 0px 0px 0px 0.5rem;
  color: #ed1c24;
  //color: #262262;
  font-weight: bold;
`;

export const MediumText = styled.p`
  color: #fff;
  font-size: 26px;
  margin: 20px 0px;
  opacity: 1;
`;
export const MediumTextBlue = styled.p`
  color: #262262;
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 40px;
  margin-top: 40px;
  font-weight: bold;
  opacity: 1;
`;
export const SmallTextBlue = styled.p`
  color: #262262;
  font-size: 16px;
  margin: 0 auto;
  opacity: 1;
`;
export const MediumTextNC = styled.p`
  font-size: 26px;
  font-weight: bold;
  margin: 20px 0px;
  opacity: 1;
  margin-bottom: 40px;
`;
export const DummyH26 = styled.div`
  width: 100%;
  height: 26px;
`;

export const WelcomeContainer = styled.div`
  width: 500px;
  margin-top: 15vh;
  height: 70vh;
  background: #c0c0c0e0;
  //background: #ed1c2480;
  //background: #262262;
  border-radius: 20px 0 0 20px;
  opacity: 1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.div`
  width: 400px;
  padding: 40px;
  margin-top: 15vh;
  background: #fff;
  z-index: 2;
  border-radius: 0 20px 20px 0;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const FormButton = styled.button`
  &:hover {
    border: 2px solid #ed1c24;
    border: 2px solid #ed1c2440;
    // border: 2px solid #262262;
  }
  position: relative;
  color: #ffffff;
  //color: #262262;
  font-size: 22px;
  padding: 6px 0;
  margin: 20px 0px;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #fff;
  background: #ed1c24;
  // background-image: url(${background});
  // background-size: cover;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;
export const TextButton = styled.button`
  &:hover {
    opacity: 1;
  }
  color: #000;
  font-size: 14px;
  margin: 0 auto;
  margin: 0px;
  opacity: 0.7;
  background: #fff;
  border: none;
  cursor: pointer;
`;
export const ReturnButton = styled.button`
  &:hover {
    opacity: 1;
    border: 1px solid #ed1c24;
    background: #ed1c2440;
    border-radius: 6px;
  }
  color: #000;
  font-size: 14px;
  margin: 0 auto;
  margin: 0px;
  padding: 0.5rem;
  opacity: 0.9;
  background: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;
`;
export const ColorRed = styled.span`
  color: #ed1c24;
  //color: #262262;
`;
export const ColorBlack = styled.span`
  color: #000000;
`;
export const ErrorArea = styled.div`
  height: 50px;
  width: 100%;
  margin-top: 20px;
`;
