import { detailInputs } from "../../assets/data/details.data";
import { lifeCompanies } from "../../assets/data/products.data";
import {
  changeInputValue,
  dateAdjustments,
  showTotalAdjustments,
} from "./quote-details.helpers.utils";
import Dates from "../../utilities/dates";

export const changeDetailsInput = (inputs, param) => {
  if (
    param.id === "quote-date" ||
    param.id === "expiration-date" ||
    param.id === "purchase-date"
  )
    return dateAdjustments(inputs, param);
  if (param.id === "irr-percent" && param.value === null) param.value = 0;  
  if (
    param.id === "show-total" ||
    param.id === "show-plots" ||
    param.id === "show-premium"
  )
    return showTotalAdjustments(inputs, param); 
  return changeInputValue(inputs, param);
};

export const validateInputs = async (field) => {
  let res = { ...field };
  let isValid = true;
  for (const [key, value] of Object.entries(detailInputs)) {
    if (value.required === true) {
      
      if (field[key].value ===null ||
        (value.validate === "text" &&
        field[key].value.length < value.minimum)
      ) {
        res = { ...res, [key]: { ...res[key], showError: true } };
        isValid = false;
      } else res = { ...res, [key]: { ...res[key], showError: false } };
    }
  }
  if (res.quoteDate.showError || res.expirationDate.showError|| res.purchaseDate.showError) {
    isValid = false;
  }
  if (isValid) {
    let val = disableAllInputs(res, true);
    return { isValid: true, result: val };
  } else return { isValid: isValid, result: res };
};

export const clearAllInputs = () => {
  return {
    quoteName: { value: "", disabled: false, showError: false },
    brokerName: { value: "", disabled: false, showError: false },
    lifeCompany: { value: null, disabled: false, showError: false },
    claimantsName: { value: "", disabled: false, showError: false },
    irrPercent: { value: 0, disabled: false, showError: false },
    showTotal: { value: true, disabled: false, showError: false },
    showPremium: { value: true, disabled: false, showError: false },
    showPlots: { value: true, disabled: false, showError: false },
    quoteDate: {
      value: Dates.today(),
      disabled: false,
      showError: false,
    },
    purchaseDate: {
      value: Dates.addMonths(1),
      disabled: false,
      showError: false,
    },
    expirationDate: {
      value: Dates.addWeek(),
      disabled: false,
      showError: false,
    },
    proposalNotes: { value: "", disabled: false, showError: false },
  };
};

export const prepareDataForRedux = async ({ fieldInputs, id }) => {
  let output = {};
  let selected = {};
  const selectedLifeCompanies = fieldInputs.lifeCompany.value.map((abbrev) =>
    lifeCompanies.find((obj) => obj.value === abbrev)
  );
  if (id !== undefined) output = { ...output, id: id };
  output["quoteName"] = fieldInputs.quoteName.value;
  output["brokerName"] = fieldInputs.brokerName.value._id;
  output["lifeCompany"] = fieldInputs.lifeCompany.value;
  output["claimantsName"] = fieldInputs.claimantsName.value._id;
  output["irrPercent"] = fieldInputs.irrPercent.value;
  output["showTotal"] = fieldInputs.showTotal.value;
  output["showPremium"] = fieldInputs.showPremium.value;
  output["showPlots"] = fieldInputs.showPlots.value;
  output["quoteDate"] = fieldInputs.quoteDate.value;
  output["purchaseDate"] = fieldInputs.purchaseDate.value;
  output["expirationDate"] = fieldInputs.expirationDate.value;
  output["proposalNotes"] = fieldInputs.proposalNotes.value;
  selected["selectedClaimantsName"] = fieldInputs.claimantsName.value;
  selected["selectedBrokerName"] = fieldInputs.brokerName.value;
  selected["selectedLifeCompanie"] = selectedLifeCompanies;
  return { output, selected };
};

export const disableAllInputs = (fieldInputs, isDisabled) => {
  let output = { ...fieldInputs };
  output.quoteName.disabled = isDisabled;
  output.brokerName.disabled = isDisabled;
  output.lifeCompany.disabled = isDisabled;
  output.claimantsName.disabled = isDisabled;
  output.irrPercent.disabled = isDisabled;
  output.showTotal.disabled = isDisabled;
  output.showPremium.disabled = isDisabled;
  output.showPlots.disabled = isDisabled;
  output.quoteDate.disabled = isDisabled;
  output.purchaseDate.disabled = isDisabled;
  output.expirationDate.disabled = isDisabled;
  output.proposalNotes.disabled = isDisabled;
  output.quoteName.showError = false;
  output.brokerName.showError = false;
  output.lifeCompany.showError = false;
  output.claimantsName.showError = false;
  output.irrPercent.showError = false;
  output.showTotal.showError = false;
  output.showTotal.showPremium = false;
  output.showTotal.showPlots = false;
  output.quoteDate.showError = false;
  output.purchaseDate.showError = false;
  output.expirationDate.showError = false;
  output.proposalNotes.showError = false;
  return output;
};

export const updateInputsToQuote = ({
  quote,
  brokers,
  claimants,
  isDisabled,
}) => {
  return {
    quoteName: {
      value: quote.quoteName,
      disabled: isDisabled,
      showError: false,
    },
    brokerName: {
      value: brokers.find((broke) => quote.brokerName === broke._id),
      disabled: isDisabled,
      showError: false,
    },
    lifeCompany: {
      value: quote.lifeCompany,
      disabled: isDisabled,
      showError: false,
    },
    claimantsName: {
      value: claimants.find((claim) => quote.claimantsName === claim._id),
      disabled: isDisabled,
      showError: false,
    },
    irrPercent: {
      value: quote.irrPercent,
      disabled: isDisabled,
      showError: false,
    },
    showTotal: {
      value: quote.showTotal,
      disabled: isDisabled,
      showError: false,
    },
    showPremium: {
      value: quote.showPremium,
      disabled: isDisabled,
      showError: false,
    },
    showPlots: {
      value: quote.showPlots,
      disabled: isDisabled,
      showError: false,
    },
    quoteDate: {
      // value: getFormattedDateFromString(quote.quoteDate),
      value: quote.quoteDate,
      disabled: isDisabled,
      showError: false,
    },
    purchaseDate: {
      // value: getFormattedDateFromString(quote.purchaseDate),
      value: quote.purchaseDate,
      disabled: isDisabled,
      showError: false,
    },
    expirationDate: {
      // value: getFormattedDateFromString(quote.expirationDate),
      value: quote.expirationDate,
      disabled: isDisabled,
      showError: false,
    },
    proposalNotes: {
      value: quote.proposalNotes,
      disabled: isDisabled,
      showError: false,
    },
  };
};
