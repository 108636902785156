import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import {
  copyQuote,
  deleteQuote,
  getQuotes,
  loadQuote,
} from "../../redux/quote/quote.actions";
import Unsubscribe from "../unsubscribe/unsubscribe.component";
import TableClaimant from "./table-claimant.render";
import TableQuotes from "./table-quotes.render";

const SavedQuotes = ({
  broker,
  claimant,
  quote,
  getQuotes,
  copyQuote,
  loadQuote,
  deleteQuote,
}) => {
  const toast = useRef(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [quotesByClaimant, setQuotesByClaimant] = useState(null);

  useEffect(() => {
    getAllQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllQuotesByClaimant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote.proposals]);

  const getAllQuotes = async () => {
    await getQuotes();
  };

  const getAllQuotesByClaimant = async () => {
    await getQuotesByClaimant();
  };

  const getQuotesByClaimant = () => {
    let _quotesByClaimant = claimant.claimants.map((claimant) => {
      let claim = { ...claimant };
      let proposals = quote.proposals.filter(
        (quote) => quote.claimantsName === claimant._id
      );
      claim["proposals"] = proposals;
      return claim;
    });
    setQuotesByClaimant(_quotesByClaimant);
  };

  const expandAll = () => {
    let _expandedRows = {};
    quotesByClaimant.forEach((p) => (_expandedRows[`${p._id}`] = true));
    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const handleRowToggle = (e) => {
    setExpandedRows(e.data);
  };

  const rowExpansionTemplate = (data) => {
    return data.proposals.length > 0 ? (
      <TableQuotes
        data={data}
        toast={toast}
        copyQuote={copyQuote}
        loadQuote={loadQuote}
        brokers={broker.brokers}
        deleteQuote={deleteQuote}
      />
    ) : (
      <h2>There are no proposals for this claimant</h2>
    );
  };

  return (
    <>
      <div className='view-saved-proposals p-shadow-12 p-mt-4'>
        {quotesByClaimant !== null && (
          <TableClaimant
            claimant={quotesByClaimant}
            globalFilter={globalFilter}
            expandedRows={expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            toast={toast}
            setGlobalFilter={setGlobalFilter}
            setExpandedRows={setExpandedRows}
            expandAll={expandAll}
            collapseAll={collapseAll}
            handleRowToggle={handleRowToggle}
          />
        )}
      </div>
      <Unsubscribe />
    </>
  );
};

const mapStateToProps = (state) => ({
  quote: state.quote,
  claimant: state.claimant,
  broker: state.broker,
});
export default connect(mapStateToProps, {
  getQuotes,
  copyQuote,
  loadQuote,
  deleteQuote,
})(SavedQuotes);
