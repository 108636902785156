export const setSessionStorage = async (item, newState) => {
  window.sessionStorage.setItem(item, JSON.stringify(newState));
};

export const getSessionStorage = async (item) => {
  let res = await sessionStorage.getItem(item);
  if (res === null) return false;
  const store = await JSON.parse(res);
  return store;
};
