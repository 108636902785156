import React,{useEffect,useState} from 'react';
import {Text, View} from '@react-pdf/renderer';
import { currency } from "../../../utilities/numbers";

import styles from '../quote-page.styles';

const P2TableTotal = ({ benefits,skipPremium }) => {
  const [annuity, setAnnuity] = useState(0);
  const [guaranteed, setGuaranteed] = useState(0);
  const [expected, setExpected] = useState(0);

  const calculateTotals = () => {
    let ann = 0;
    let gua = 0;
    let exp = 0;
    for (let benefit of benefits) {
      ann += benefit.annuityPremium;
      gua += benefit.guaranteedBenefit;
      exp += benefit.expectedBenefit;
    };
    setAnnuity(ann);
    setGuaranteed(gua);
    setExpected(exp);
  };

  useEffect(() => {
    calculateTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefits]);

  return (
    <View
      style={[styles.table_row, styles.margin20_top]}
    >
      <View style={[styles.table_total_col1]}>
        <Text style={[styles.text12, styles.bold,styles.margin10_top]}>Total</Text>
      </View>
      {skipPremium ===false ?
      <View style={[styles.table_total_col]}>
        <Text style={[styles.text12, styles.center, styles.bold,styles.margin10_top]}>
          {currency(annuity)}
        </Text>
      </View>
      : <View style={[styles.table_total_col]}/>}
      <View style={[styles.table_total_col]}>
        <Text style={[styles.text12, styles.center, styles.bold,styles.margin10_top]}>
          {currency(guaranteed)}
        </Text>
      </View>
      <View style={[styles.table_total_col]}>
        <Text style={[styles.text12, styles.center, styles.bold,styles.margin10_top]}>
          {currency(expected)}
        </Text>
      </View>
    </View>
  );
};

export default P2TableTotal;
