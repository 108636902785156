import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CurrencyTemplate from "../../../../lib/currency-template";
import DeleteTemplate from "../../../../lib/delete-template";

const IncomeTable = ({ tableData, handleDelete }) => {
  return (
    <Fragment>
      <DataTable
        value={tableData}
        dataKey='id'
        className='p-datatable-sm reduced-height-table'
        id='variable-incomes-table'
      >
        <Column
          body={CurrencyTemplate}
          style={{ width: "33.33%" }}
          field='incomeAmount'
          header='Additional Income'
        />
        <Column
          style={{ width: "16.67%" }}
          field='incomeStart'
          header='Start Year'
        />
        <Column
          style={{ width: "16.67%" }}
          field='incomeEnd'
          header='End Year'
        ></Column>
        <Column
          style={{ width: "16.67%" }}
          body={(rowData) => {
            return (
              <DeleteTemplate
                rowData={rowData}
                color='accent100'
                handler={handleDelete}
              />
            );
          }}
        ></Column>
      </DataTable>
    </Fragment>
  );
};

export default IncomeTable;
