import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";

const ContinuingParagraph = ({ startYear, endYear }) => {
  return (
    <Text
      style={[styles.fontm]}
    >{`Continuing, this analysis is assumed to begin immediately and is a comparison over the next ${
      1 + endYear - startYear
    } years.`}</Text>
  );
};
export default ContinuingParagraph;
