import React, { Fragment } from "react";

import { TabMenu } from "primereact/tabmenu";

const TabMenuNav = ({ active, setActive, menuItems }) => {
  return (
    <Fragment>
      <div className='k-tab-menu-nav'>
        <div className='card'>
          <TabMenu
            model={menuItems}
            activeIndex={active}
            onTabChange={(e) => {
              setActive(e.value.id);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TabMenuNav;
