class Dates {
  static today = () => {
    let date = new Date();
    return this.formatString(date);
  };
  static isDataADate = (data) => {
    let date_regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    return date_regex.test(data);
  };
  static convertDateToString(date) {
    // "2022-02-10T05:00:00.000Z";
    let [year, month, day] = date.split("-");
    return `${month}/${day.substring(0, 2)}/${year}`;
  }
  static formatString = (date) => {
    let mm = date.getMonth() + 1; // getMonth() is zero-based
    let dd = date.getDate();
    let yyyy = date.getFullYear();
    return [(mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd, yyyy].join("/");
  };

  static isStringADateFormat = (string) => {
    if (string === undefined || string.split("/").length < 3) return false;
    let [month, day, year] = string.split("/");
    if (year.includes("y") || month.includes("m") || day.includes("d"))
      return false;
    return true;
  };

  static isStringAValidDate = (string) => {
    let [month, day, year] = string.split("/");
    const date = new Date(`${year}-${month}-${day} 00:00`);
    return date == "Invalid Date" ? false : true;
  };

  static calculateAgeFromString = (string) => {
    let [month, day, year] = string.split("/");
    const birthDate = new Date(`${year}-${month}-${day} 00:00`);
    return this.getAge(birthDate);
  };

  static getAge = (birthDate) => {
    let today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;
    return age;
  };
  static getEndDateFromString = (dateString, months, period) => {
    let date = new Date(dateString);
    let endDate = new Date(date.setMonth(date.getMonth() + months - period));
    return this.formatString(endDate);
  };
  static getEndDateByWeekFromString = (dateString, weeks) => {
    let date = new Date(dateString);
    let endDate = date;
    if (weeks > 1)
      endDate = new Date(date.setDate(date.getDate() + 7 * (weeks - 1)));
    return this.formatString(endDate);
  };
  static addMonths = (months) => {
    return this.formatString(
      new Date(new Date().setMonth(new Date().getMonth() + months))
    );
  };
  static addWeek = () => {
    return this.formatString(
      new Date(new Date().setDate(new Date().getDate() + 7))
    );
  };
}

export default Dates;
