import React, { Fragment, useState, useEffect } from "react";

const InputFieldText = ({ loaded, edit, isError }) => {
  useEffect(() => {
    if (isError) setMessage("Check inputs Values");
    else {
      if (loaded & edit) setMessage("Proposal is being edited");
      if (loaded & !edit) setMessage("Proposal is loaded");
      if (!loaded) setMessage("Proposal is not loaded");
    }
  }, [loaded, edit,  isError]);
  const [message, setMessage] = useState("Proposal is not loaded");

  return (
    <Fragment>
      <div className='p-col-4 p-md-12 background-primary'>
        <h5 className='center-text'>{message}</h5>
      </div>
    </Fragment>
  );
};

export default InputFieldText;
