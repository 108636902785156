import React,{Fragment} from 'react';
import {Text, View} from '@react-pdf/renderer';

import styles from '../quote-page.styles';

const P1Presented = ({loc, name,title, life_companies}) => {
	return (
		<View style={loc}>
			<Text style={[styles.text12, styles.left, styles.bold, styles.underline]}>
				Presented By:
			</Text>
			<Text style={[styles.text12, styles.left]}>{name}</Text>
			{title !==undefined && <Text style={[styles.text12, styles.left]}>{title}</Text>}
			<Text
				style={[
					styles.text12,
					styles.left,
					styles.bold,
					styles.underline,
					styles.margin10_top,
				]}
			>
				Current Annuity Rates From:
			</Text>
			{life_companies.length>2 ? 
				<Fragment>
					<Text	style={[styles.text12, styles.left]}>
						Multiple Companies
					</Text>
				</Fragment>
				:
			life_companies.map((life_company) => {
				return <Fragment key={life_company.id}>
					<Text	style={[styles.text12, styles.left]}>
						{life_company.name}
					</Text>
					<Text style={[styles.text12, styles.margin5_bottom, styles.left]}>
						A.M. Best Rating: {life_company.rating}
					</Text>					
				</Fragment>
			})}
			
		</View>
	);
};

export default P1Presented;
