import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CurrencyTemplate from "../../../../lib/currency-template";
import Percent2Template from "../../../../lib/percent-2-template";
import DeleteTemplate from "../../../../lib/delete-template";

const DrawsTable = ({ tableData, handleDelete }) => {
  return (
    <DataTable
      value={tableData}
      dataKey='id'
      className='p-datatable-sm reduced-height-table'
      id='variable-draws-table'
    >
      <Column
        body={CurrencyTemplate}
        style={{ width: "33.33%" }}
        field='drawAmount'
        header='Draw'
      />
      <Column
        body={Percent2Template}
        style={{ width: "16.67%" }}
        field='drawCola'
        header='COLA%'
      />
      <Column
        style={{ width: "16.67%" }}
        field='drawStart'
        header='Start Year'
      />
      <Column
        style={{ width: "16.67%" }}
        field='drawEnd'
        header='End Year'
      ></Column>
      <Column
        style={{ width: "16.67%" }}
        body={(rowData) => {
          return (
            <DeleteTemplate
              rowData={rowData}
              color='secondary100'
              handler={handleDelete}
            />
          );
        }}
      ></Column>
    </DataTable>
  );
};

export default DrawsTable;
