import React, { Fragment } from "react";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

export const ActionButtons = ({
  copyQuote,
  loadQuote,
  deleteQuote,
  rowData,
  toast,
}) => {
  const handleCopy = async (e) => {
    await copyQuote(rowData._id);
    toast.current.show({
      severity: "success",
      summary: `${rowData.quoteName} was successfully copied`,
      detail: "Don't forget to change the proposal name",
      life: 5000,
    });
  };
  const handleLoad = async (e) => {
    await loadQuote(rowData._id);
    toast.current.show({
      severity: "success",
      summary: `${rowData.quoteName} is Loaded`,
      detail: "Go to Proposal Designer to View",
      life: 5000,
    });
  };
  const handleDelete = async (e) => {
    confirmDialog({
      message: "Do you want to delete this proposal?",
      header: "Confirm Delete",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      baseZIndex: 1000,
      accept: async () => {
        await deleteQuote(rowData._id);
        toast.current.show({
          severity: "warn",
          summary: "Confirmed",
          detail: "The proposal was deleted",
          life: 3000,
        });
      },
      reject: () => {},
    });
  };

  return (
    <Fragment>
      <Button
        iconPos='left'
        icon='pi pi-copy'
        // label='Copy '
        className='p-button-rounded p-button-sm p-button-success p-mr-2'
        // className='p-button-raised p-mr-2'
        onClick={handleCopy}
        disabled={false}
      />
      <Button
        iconPos='left'
        icon='pi pi-download'
        // label='Load '
        className='p-button-rounded p-button-sm p-mr-2'
        // className='p-button-raised p-button-success p-mr-2'
        onClick={handleLoad}
        disabled={false}
      />
      <Button
        iconPos='left'
        icon='pi pi-trash'
        // label='Delete '
        className='p-button-rounded p-button-sm p-button-danger p-mr-2'
        // className='p-button-raised p-button-danger'
        onClick={handleDelete}
        disabled={false}
      />
    </Fragment>
  );
};

export default ActionButtons;
