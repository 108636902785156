import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "../quote-page.styles";

const P1Prepared = ({ loc, claimant }) => {
  const ShowAgeOrDate = () => {
    const dob = claimant.birthDate;
    const ageString = `Age: ${claimant.displayedAge}`;
    const dobString = `Date of Birth: ${dob}`;
    return claimant.ageSelector ? ageString : dobString;
  };

  return (
    <View style={loc}>
      <Text style={[styles.text18, styles.center]}>PREPARED FOR:</Text>
      <Text
        style={[styles.text22, styles.center, styles.bold, styles.margin10_top]}
      >
        {claimant.name}
      </Text>
      {claimant.caseNumber.length >= 1 && (
        <Text style={[styles.text18, styles.center, styles.margin5_top]}>
          {`Case Number: ${claimant.caseNumber}`}
        </Text>
      )}
      <Text style={[styles.text18, styles.center]}>
        <ShowAgeOrDate />
      </Text>
      {claimant.ratedAge > claimant.age && (
        <Text style={[styles.text18, styles.center]}>
          {`Rated Age: ${claimant.ratedAge}`}
        </Text>
      )}
    </View>
  );
};

export default P1Prepared;
