import { Button } from "primereact/button";

const DeleteTemplate = ({ rowData, color, handler }) => {
  return (
    <Button
      icon='pi pi-trash'
      className={`p-button-rounded p-button-text color-${color}`}
      onClick={() => handler(rowData)}
      tooltip='Delete item'
      tooltipOptions={{ position: "top" }}
    />
  );
};

export default DeleteTemplate;
