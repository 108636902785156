import React, { Fragment } from "react";
import { FormContainer, MediumTextBlue, SmallTextBlue } from "./login.styles";

const ForgotPass = () => {
  return (
    <Fragment>
      <FormContainer>
        <div className='p-fluid'>
          <div className='k-text-center k-my-20px'>
            <MediumTextBlue>
              Please check your email to for password rest instructions.{" "}
            </MediumTextBlue>
          </div>
          <div className='k-text-center k-my-20px'>
            <SmallTextBlue>
              If you did not receive an email, check your Spam folder. You can
              contact us at
            </SmallTextBlue>
          </div>
          <div className='k-text-center k-my-20px'>
            <SmallTextBlue>
              <em>admin@setanalyzer.com</em>
            </SmallTextBlue>
          </div>
          <div className='k-text-center k-my-20px'>
            <MediumTextBlue>You can now close this tab.</MediumTextBlue>
          </div>
        </div>
      </FormContainer>
    </Fragment>
  );
};

export default ForgotPass;
