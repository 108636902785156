import styled from "styled-components";

export const SpacerDiv = styled.div`
  height: 41.98px;
  display: inline-block;
`;
export const FullSpacerDiv = styled.div`
  height: 25.98px;
  marginbottom: 1rem;
`;

const getColor = ({ colors: { format, color0, color1, color2 } }) => {
  if (format === "solid") return `background: #${color1};`;
  if (format === "horizontal")
    return `background: linear-gradient(to right,#${color0} 0%, #${color2} 100%)`;
  if (format === "vertical")
    return `background: linear-gradient(to bottom,#${color0} 0%, #${color2} 100%)`;
};

export const ColorDiv = styled.div`
  height: 100px;
  border: solid 1px #40404040;
  margin-bottom: 1.5rem;
  ${getColor}
`;
