const columns = [
  { initial: true, field: "year", header: "Year" },
  {
    initial: true,
    field: "gain",
    header: "Random Gain",
  },
  {
    initial: true,
    field: "bondRate",
    header: "Bond Rate",
  },
  {
    initial: true,
    field: "startBalance",
    header: "Start Balance",
  },
  {
    initial: false,
    field: "stockGain",
    header: "Stock Gain",
  },
  {
    initial: false,
    field: "bondGain",
    header: "Bond Gain",
  },
  {
    initial: true,
    field: "grossGain",
    header: "Gross Gain",
  },
  {
    initial: false,
    field: "grossDraw",
    header: "Gross Draw",
  },
  {
    initial: true,
    field: "draw",
    header: "Draw",
  },
  {
    initial: true,
    field: "grossBalance",
    header: "Gross Balance",
  },

  { initial: true, field: "fee", header: "Fee" },
  {
    initial: true,
    field: "netBalance",
    header: "Net Balance",
  },
  {
    initial: false,
    field: "income",
    header: "Income",
  },

  {
    initial: false,
    field: "taxPercent",
    header: "Tax %",
  },
  {
    initial: false,
    field: "federalTaxPercent",
    header: "Federal %",
  },
  {
    initial: false,
    field: "stateTaxPercent",
    header: "State %",
  },
  {
    initial: false,
    field: "bondTax",
    header: "Bond Tax",
  },
  {
    initial: false,
    field: "stockTax",
    header: "Stock Tax",
  },
  {
    initial: false,
    field: "federalTaxes",
    header: "Federal Tax",
  },
  {
    initial: false,
    field: "stateTaxes",
    header: "State Tax",
  },
  {
    initial: true,
    field: "taxes",
    header: "Taxes",
  },
  {
    initial: true,
    field: "endBalance",
    header: "End Balance",
  },
  {
    initial: true,
    field: "blended",
    header: "Blended",
  },
];

export default columns;
