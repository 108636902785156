import React, { Fragment, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";

import { connect } from "react-redux";
import { editFirm, deleteFirm } from "../../redux/broker/broker.actions";
import { getQuotes } from "../../redux/quote/quote.actions";

const ViewBrokers = ({
  broker: { brokers, isEdit },
  editFirm,
  deleteFirm,
  getQuotes,
}) => {
  const toast = useRef(null);
  const actionButtons = (rowData) => {
    const handleEdit = async (e) => {
      await editFirm(rowData);
    };
    const handleDelete = async (e) => {
      confirmDialog({
        message: "This will delete all proposals created by the firm",
        header: "Confirm Delete",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        baseZIndex: 1000,
        accept: async () => {
          await deleteFirm(rowData._id);
          await getQuotes();
          toast.current.show({
            severity: "warn",
            summary: "Confirmed",
            detail: "The firm was deleted",
            life: 3000,
          });
        },
        reject: () => {},
      });
    };

    return (
      <Fragment>
        <Button
          iconPos='left'
          icon='pi pi-pencil'
          className='p-button-rounded p-button-sm p-button-warning p-mr-2'
          onClick={handleEdit}
          disabled={isEdit || rowData._id === 1}
        />
        <Button
          iconPos='left'
          icon='pi pi-trash'
          className='p-button-rounded p-button-sm p-button-danger'
          onClick={handleDelete}
          disabled={isEdit || rowData._id === 1}
        />
      </Fragment>
    );
  };
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`/img/logos/${rowData.logo}`}
        alt='Logo for the firm'
        className='logo-display'
        height='50px'
      />
    );
  };
  const header = (
    <div className='broker-table-header'>
      <div />
      <p className='font-title'>View Brokers</p>
      <div />
    </div>
  );

  return (
    <div className='view-brokers p-shadow-12'>
      <Toast ref={toast} />
      <div className='view-brokers-table p-shadow-12 p-mt-4'>
        <DataTable
          value={brokers}
          dataKey='_id'
          header={header}
          className='p-datatable-striped'
        >
          <Column header='Logo' body={imageBodyTemplate}></Column>
          <Column field='firmName' header='Brokerage Firm'></Column>
          <Column field='firmCity' header='City, State, Zip'></Column>
          <Column field='brokerName' header='Broker Name'></Column>
          <Column field='brokerTitle' header='Broker Title'></Column>

          <Column body={actionButtons} />
        </DataTable>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  broker: state.broker,
});
export default connect(mapStateToProps, {
  editFirm,
  deleteFirm,
  getQuotes,
})(ViewBrokers);
