import TaxAdjusted from "./taxAdjusted";
import TaxAdjustedHybrid from "./taxAdjustedHybrid";
import {
  federalSingle,
  federalMarried,
  ltcgSingle,
  ltcgMarried,
  taxTrust,
} from "../assets/data/taxes.data";
import stateTaxList from "../assets/data/stateTaxList.json";

export default class Year {
  constructor(inputs) {
    this.id = inputs.id;
    this.year = inputs.year;
    this.startBalance = inputs.startBalance;
    this.gain = inputs.gain;
    this.noLoss = inputs.noLoss;
    this.draw = inputs.draw;
    this.income = inputs.income;
    this.blended = inputs.blended;
    this.percentFee = inputs.percentFee;
    this.taxRun = inputs.taxRun;
    this.stateTax = inputs.stateTax;
    this.percentBonds = inputs.percentBonds;
    this.bondRate = inputs.bondRate;
    this.bondGain =
      (this.startBalance * this.percentBonds * this.bondRate) / 10000;
    this.stockGain =
      ((100 - this.percentBonds) * this.startBalance * this.gain) / 10000;
    this.grossGain = this.stockGain + this.bondGain;

    this.grossBalance = this.startBalance + this.grossGain - this.draw;
    this.fee = (this.grossBalance * this.percentFee) / 100;
    this.netBalance = this.grossBalance - this.fee;
    this.netGain = this.grossGain - this.fee;
    this.taxPercent = 0;
    this.federalTaxPercent = 0;
    this.stateTaxPercent = 0;
    this.taxes = 0;
    this.federalTaxes = 0;
    this.stateTaxes = 0;
    this.firstShortfall = 1;
    let stateTaxInformation = "no tax";
    if (this.taxRun !== "no tax") {
      stateTaxInformation = this.taxRun.endsWith("Married")
        ? stateTaxList.find((s) => this.stateTax === s.state)["Married"]
        : stateTaxList.find((s) => this.stateTax === s.state)["Single"];
    }
    switch (this.taxRun) {
      case "no tax": {
        this.federalTaxPercent = 0;
        this.stateTaxPercent = 0;
        break;
      }
      case "federalSingle": {
        if (this.draw <= 0) break;
        let fTax = new TaxAdjusted(this.income, this.draw, federalSingle);
        let sTax = new TaxAdjusted(this.income, this.draw, stateTaxInformation);
        this.federalTaxPercent = fTax.taxPercent;
        this.stateTaxPercent = sTax.taxPercent;
        break;
      }
      case "federalMarried": {
        if (this.draw <= 0) break;
        let fTax = new TaxAdjusted(this.income, this.draw, federalMarried);
        let sTax = new TaxAdjusted(this.income, this.draw, stateTaxInformation);
        this.federalTaxPercent = fTax.taxPercent;
        this.stateTaxPercent = sTax.taxPercent;
        break;
      }
      case "ltcgSingle": {
        if (this.draw <= 0) break;
        let fTax = new TaxAdjusted(this.income, this.draw, ltcgSingle);
        let sTax = new TaxAdjusted(this.income, this.draw, stateTaxInformation);
        this.federalTaxPercent = fTax.taxPercent;
        this.stateTaxPercent = sTax.taxPercent;
        break;
      }
      case "ltcgMarried": {
        if (this.draw <= 0) break;
        let fTax = new TaxAdjusted(this.income, this.draw, ltcgMarried);
        let sTax = new TaxAdjusted(this.income, this.draw, stateTaxInformation);
        this.federalTaxPercent = fTax.taxPercent;
        this.stateTaxPercent = sTax.taxPercent;
        break;
      }
      case "hybridSingle": {
        if (this.grossGain <= 0) break;
        if (this.bondGain + this.stockGain < 0) break;
        let sg = this.stockGain;
        //if (sg < 0) sg = 0;
        let fedTax = new TaxAdjustedHybrid(
          this.income,
          sg,
          this.bondGain,
          ltcgSingle,
          federalSingle
        );

        let sTax = new TaxAdjusted(
          this.income,
          sg + this.bondGain,
          stateTaxInformation
        );
        this.federalTaxPercent = fedTax.taxPercent;
        this.stateTaxPercent = sTax.taxPercent;
        this.federalTaxes = fedTax.tax;
        this.stateTaxes = ((this.bondGain + sg) * this.stateTaxPercent) / 100;
        break;
      }
      case "hybridMarried": {
        if (this.grossGain <= 0) break;
        if (this.bondGain + this.stockGain < 0) break;
        let sg = this.stockGain;
        //if (sg < 0) sg = 0;

        let fedTax = new TaxAdjustedHybrid(
          this.income,
          sg,
          this.bondGain,
          ltcgMarried,
          federalMarried
        );

        let sTax = new TaxAdjusted(
          this.income,
          sg + this.bondGain,
          stateTaxInformation
        );
        this.federalTaxPercent = fedTax.taxPercent;
        this.stateTaxPercent = sTax.taxPercent;
        this.federalTaxes = fedTax.tax;
        this.stateTaxes = ((this.bondGain + sg) * this.stateTaxPercent) / 100;
        break;
      }
      default: {
        if (this.draw <= 0) break;
        let fTax = new TaxAdjusted(this.income, this.draw, taxTrust);
        let sTax = new TaxAdjusted(this.income, this.draw, stateTaxInformation);
        this.federalTaxPercent = fTax.taxPercent;
        this.stateTaxPercent = sTax.taxPercent;
        break;
      }
    }
    if (!this.taxRun.startsWith("hybrid")) {
      if (this.draw <= 0) this.draw = 0;
      this.federalTaxes = (this.draw * this.federalTaxPercent) / 100;
      this.stateTaxes = (this.draw * this.stateTaxPercent) / 100;
    }
    // Added below for No Loss
    if (this.noLoss && this.grossGain < 0) {
      this.federalTaxPercent = 0;
      this.stateTaxPercent = 0;
      this.federalTaxes = 0;
      this.stateTaxes = 0;
    }
    // Added above for No Loss

    this.taxes = this.federalTaxes + this.stateTaxes;
    this.taxPercent = this.federalTaxPercent + this.stateTaxPercent;
    this.endBalance = this.netBalance - this.taxes + this.blended;

    if (this.endBalance <= 0) {
      this.firstShortfall = this.endBalance;
      this.endBalance = 0;
      this.grossBalance = 0;
      this.netBalance = 0;
      this.fee = 0;
      this.draw = 0;
      this.income = 0;
    }
  }
}
