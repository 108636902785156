import React, { Fragment } from "react";
import { Image, View } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";

const PdfChart = ({ chart, coordinates: { top, left, width, height } }) => {
  return (
    <Fragment>
      <View
        style={[
          styles.rounded_border,
          styles.absolute,
          { top: top },
          { left: left },
          { width: width },
          { height: height },
          styles.bgChart,
        ]}
      >
        <Image src={chart} />
      </View>
    </Fragment>
  );
};

export default PdfChart;
