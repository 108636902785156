import React, { Fragment } from "react";

import LoginRegisterPasswords from "../../components/login-register-passwords/login-register-passwords.component";

const LoginMessagePage = () => {
  return (
    <Fragment>
      <LoginRegisterPasswords message='Your password was changed.' />
    </Fragment>
  );
};

export default LoginMessagePage;
