export const dbToRedux = (db) => {
  let res = { ...db };
  if (res["irrPercent"] === undefined) res["irrPercent"] = 0;
  delete res.__v;
  delete res.user;
  return res;
};

export const dbToReduxArray = (db) => {
  let res = [...db];
  for (let quote of res) {
    if (quote["irrPercent"] === undefined) quote["irrPercent"] = 0;
    delete quote.__v;
    delete quote.user;
  }
  return res;
};
