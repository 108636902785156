import React, { Fragment } from "react";
import { FormContainer, MediumTextBlue, SmallTextBlue } from "./login.styles";

const RegFail = () => {
  return (
    <Fragment>
      <FormContainer>
        <div className='p-fluid'>
          <div className='k-text-center k-my-20px'>
            <MediumTextBlue>Your registration did not complete</MediumTextBlue>
          </div>
          <div className='k-text-center k-my-20px'>
            <SmallTextBlue>Please try again or contact us at</SmallTextBlue>
          </div>
          <div className='k-text-center k-my-20px'>
            <SmallTextBlue>
              <em>admin@setanalyzer.com</em>
            </SmallTextBlue>
          </div>
          <div className='k-text-center k-my-20px'>
            <MediumTextBlue>You can now close this tab.</MediumTextBlue>
          </div>
        </div>
      </FormContainer>
    </Fragment>
  );
};

export default RegFail;
