import React, { Fragment } from "react";
import { InputNumber } from "primereact/inputnumber";

const InputFieldPercentage = ({
  handler,
  variables: { value, disabled, showError },
  constants: { label, medSize, id, textError, name, maximum },
}) => {
  return (
    <Fragment>
      <div className={`p-field p-col-4 p-md-${medSize}`}>
        <label htmlFor={id}>{label}</label>

        <InputNumber
          id={id}
          name={name}
          value={value === null ? 0.0 : value}
          onValueChange={handler}
          disabled={disabled}
          suffix='%'
          min={0.0}
          max={maximum}
          minFractionDigits={2}
          mode='decimal'
          placeholder="0.00%"
        />
        {showError && (
          <small id={`${id}-help`} className='p-invalid p-d-block'>
            {textError}
          </small>
        )}
      </div>
    </Fragment>
  );
};

export default InputFieldPercentage;
