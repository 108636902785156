export const calculatorParams = {
  startYear: {
    id: "startYear",
    label: "Start Year",
    size: 3,
    min: 1900,
    max: 2200,
    tt: {
      content: "Select the year to start the simulation",
      position: "top",
      color: "green",
    },
  },
  numberOfYears: {
    id: "numberOfYears",
    label: "Years",
    size: 3,
    min: 1,
    max: 100,
    tt: {
      content: "Select the number of years to run",
      position: "top",
      color: "green",
    },
  },
  isTaxable: {
    id: "isTaxable",
    label: "Taxable",
    size: 3,
    tt: {
      content: "Select if the initial settlement is taxable",
      position: "top",
      color: "green",
    },
  },
  noLoss: {
    id: "noLoss",
    label: "No Tax",
    size: 3,
    tt: {
      content: "Select if you want no taxes years when the gain is negative ",
      position: "top",
      color: "green",
    },
  },
  startBalance: {
    id: "startBalance",
    label1: "Start Balance",
    label2: "Settlement",
    size: 6,
    min: 0,
    tt: {
      content: "Select the settlement value",
      position: "top",
      color: "green",
    },
  },
  stateTax: {
    id: "stateTax",
    label: "State Tax",
    optionLabel: "name",
    placeholder: "Select a State",
    size: 6,
    tt: {
      content: "Select the state which the claimant lives in",
      position: "top",
      color: "green",
    },
  },
  percentBonds: {
    id: "percentBonds",
    label: "% Bonds",
    suffix: "%",
    min: 0.0,
    max: 100,
    minFractionDigits: 0,
    placeholder: "0%",
    size: 3,
    tt: {
      content: "The percentage of bonds in the investment",
      position: "top",
      color: "green",
    },
  },
  bondRate: {
    id: "bondRate",
    label: "Rate",
    suffix: "%",
    min: 0.0,
    max: 100,
    minFractionDigits: 2,
    placeholder: "0.00%",
    size: 3,
    tt: {
      content:
        "Click to Check out US TREASURY-CURRENT 10 YEAR's stock price (US10Y) in real time",
      position: "top",
      color: "green",
      link: "https://www.cnbc.com/quotes/US10Y",
    },
  },
  numberOfRuns: {
    id: "numberOfRuns",
    label: "Runs",
    size: 3,
    min: 1,
    max: 1000,
    tt: {
      content: "How many iterations in the Monte Carlo simulation",
      position: "top",
      color: "green",
    },
  },
  yearlyFees: {
    id: "yearlyFees",
    label: "Fees",
    suffix: "%",
    min: 0.0,
    max: 100,
    minFractionDigits: 2,
    placeholder: "0.00%",
    size: 3,
    tt: {
      content: "Enter the percentage of the Advisor's yearly fee",
      position: "top",
      color: "green",
    },
  },
  marriedYear: {
    id: "yearMarried",
    label: "Year Married",
    size: 8,
    offset: 2,
    min: 2023,
    max: 2100,
    tt: {
      content: "Select the year to file taxes as married",
      position: "top",
      color: "green",
    },
  },
};
export const brokerClaimantParams = {
  selectBroker: {
    id: "selectBroker",
    label: "Select Broker",
    optionLabel: "optionsLabel",
    placeholder: "Select a Broker",
    size: 6,
    outer: "pr-4pct",
    tt: {
      content: "Select a broker from the dropdown menu",
      position: "top",
      color: "green",
    },
  },
  selectClaimant: {
    id: "selectClaimant",
    label: "Select Claimant",
    optionLabel: "name",
    placeholder: "Select a Claimant",
    size: 6,
    outer: "pl-4pct",
    tt: {
      content: "Select a claimant from the dropdown menu",
      position: "top",
      color: "red",
    },
  },
};
export const gainParams = {
  sp500_adjusted: {
    id: "sp500_adjusted",
    label: "S&P500 Index + Dividends",
    size: 8,
  },
  sp500_vbtix: {
    id: "sp500_vbtix",
    label: "S&P500 Index + Dividends with VBTIX",
    size: 8,
  },
  sp500_bagg: {
    id: "sp500_bagg",
    label: "S&P500 Index + Dividends with BAGG",
    size: 8,
  },
  sp500_vbtix_60_10y: {
    id: "sp500_vbtix_60_10y",
    label: "S&P 500 + VBTIX - 60/40 - Last 10 Years",
    size: 8,
  },
  sp500_vbtix_60_15y: {
    id: "sp500_vbtix_60_15y",
    label: "S&P 500 + VBTIX - 60/40 - Last 15 Years",
    size: 8,
  },
};
export const drawsParams = {
  drawAmount: {
    id: "drawAmount",
    label: "Draw",
    size: 4,
    min: 0,
    tt: {
      content: "Enter the amount of the draw per year",
      position: "top",
      color: "green",
    },
  },
  drawCola: {
    id: "drawCola",
    label: "COLA%",
    suffix: "%",
    min: 0.0,
    max: 100,
    minFractionDigits: 2,
    placeholder: "0.00%",
    size: 2,
    tt: {
      content: "Enter the COLA of the draw per year",
      position: "top",
      color: "green",
    },
  },
  drawStart: {
    id: "drawStart",
    label: "Start Year",
    size: 2,
    min: 1900,
    max: 2200,
    tt: {
      content: "Enter the year to start the draw",
      position: "top",
      color: "green",
    },
  },
  drawEnd: {
    id: "drawEnd",
    label: "End Year",
    size: 2,
    min: 1900,
    max: 2200,
    tt: {
      content: "Enter the year to end the draw",
      position: "top",
      color: "green",
    },
  },
};
export const incomesParams = {
  incomeAmount: {
    id: "incomeAmount",
    label: "Income",
    size: 4,
    min: 0,
    tt: {
      content: "Enter the amount of income to add per year",
      position: "top",
      color: "green",
    },
  },
  incomeStart: {
    id: "incomeStart",
    label: "Start Year",
    size: 2,
    min: 1900,
    max: 2200,
    tt: {
      content: "Enter the year to start adding additional income",
      position: "top",
      color: "green",
    },
  },
  incomeEnd: {
    id: "incomeEnd",
    label: "End Year",
    size: 2,
    min: 1900,
    max: 2200,
    tt: {
      content: "Enter the year to end adding the additional income",
      position: "top",
      color: "green",
    },
  },
};

export const blendedsParams = {
  blendedAmount: {
    id: "blendedAmount",
    label: "Income",
    size: 4,
    min: 0,
    tt: {
      content: "Enter the amount of income to add per year",
      position: "top",
      color: "green",
    },
  },
  blendedCola: {
    id: "blendedCola",
    label: "COLA%",
    suffix: "%",
    min: 0.0,
    max: 100,
    minFractionDigits: 2,
    placeholder: "0.00%",
    size: 2,
    tt: {
      content: "Enter the COLA of the blended income per year",
      position: "top",
      color: "green",
    },
  },
  blendedStart: {
    id: "blendedStart",
    label: "Start Year",
    size: 2,
    min: 1900,
    max: 2200,
    tt: {
      content: "Enter the year to start adding additional income",
      position: "top",
      color: "green",
    },
  },
  blendedEnd: {
    id: "blendedEnd",
    label: "End Year",
    size: 2,
    min: 1900,
    max: 2200,
    tt: {
      content: "Enter the year to end adding the additional income",
      position: "top",
      color: "green",
    },
  },
};
