import React from "react";
import { InputMask } from "primereact/inputmask";

const InputFieldDate = ({
  handler,
  variables: { value, disabled, showError },
  constants: { label, medSize, id, textError, name },
}) => {
  return (
    <div className={`p-field p-col-4 p-md-${medSize}`}>
      <label htmlFor={id}>{label}</label>
      
            <InputMask
              name={name}
              id={id}
              mask='99/99/9999'
              value={value}
              placeholder='mm/dd/yyyy'
              slotChar='mm/dd/yyyy'
              onChange={handler}
              disabled={disabled}
              aria-describedby={`${id}-help`}
            ></InputMask>
       {showError && (
              <small id={`${id}-help`} className='p-invalid p-d-block'>
                {textError}
              </small>
       )}
    </div>
  );
};

export default InputFieldDate;
