import ClaimantUtilities from "./claimants.helpers.utils";
import Dates from "../../utilities/dates";

export const changeClaimantInput = (inputs, param) => {
  if (param.id === "birth-date")
    return ClaimantUtilities.dobAdjustments(inputs, param);
  if (param.id === "age-selector")
    return ClaimantUtilities.selAdjustments(inputs, param);
  if (param.id === "normal-age")
    return ClaimantUtilities.ageAdjustments(inputs, param);
  if (param.id === "rated-age")
    return ClaimantUtilities.ratedAgeAdjustments(inputs, param);
  if (param.id === "first-name" || param.id === "last-name")
    return ClaimantUtilities.nameAdjustments(inputs, param);
  return ClaimantUtilities.changeInputValue(inputs, param);
};

export const clearAllInputs = () => {
  return {
    firstName: { value: "", disabled: false, showError: false },
    middleName: { value: "", disabled: false, showError: false },
    lastName: { value: "", disabled: false, showError: false },
    caseNumber: { value: "", disabled: false, showError: false },
    ageSelector: { value: false, disabled: false, showError: false },
    birthDate: {
      value: Dates.today(),
      disabled: false,
      showError: false,
    },
    age: { value: 0, disabled: true, showError: false },
    ratedAge: { value: 0, disabled: false, showError: false },
  };
};

export const prepareEditInputs = (editParams) => {
  let firstName = {
    value: editParams.firstName,
    disabled: false,
    showError: false,
  };
  let middleName = {
    value: editParams.middleName,
    disabled: false,
    showError: false,
  };
  let lastName = {
    value: editParams.lastName,
    disabled: false,
    showError: false,
  };
  let caseNumber = {
    value: editParams.caseNumber,
    disabled: false,
    showError: false,
  };
  let ageSelector = {
    value: editParams.ageSelector,
    disabled: false,
    showError: false,
  };
  let age = {
    value: editParams.age,
    disabled: !editParams.ageSelector,
    showError: false,
  };
  let ratedAge = {
    value: editParams.ratedAge,
    disabled: false,
    showError: false,
  };
  let birthDate = {
    value: editParams.birthDate,
    disabled: editParams.ageSelector,
    showError: false,
  };
  return {
    firstName,
    lastName,
    middleName,
    caseNumber,
    ageSelector,
    age,
    ratedAge,
    birthDate,
  };
};

export const validateInputs = async (field) => {
  let result = { ...field };
  if (result["firstName"].showError === true) return { isValid: false, result };
  if (result["middleName"].showError === true)
    return { isValid: false, result };
  if (result["lastName"].showError === true) return { isValid: false, result };
  if (result["caseNumber"].showError === true)
    return { isValid: false, result };
  if (result["ageSelector"].showError === true)
    return { isValid: false, result };
  if (result["birthDate"].showError === true) return { isValid: false, result };
  if (result["age"].showError === true) return { isValid: false, result };
  if (result["ratedAge"].showError === true) return { isValid: false, result };

  return { isValid: true, result };
};

export const prepareDataForRedux = async ({ fieldInputs, id }) => {
  let output = {};
  if (id !== undefined) output = { ...output, id: id };

  await Object.keys(fieldInputs).map(async (fieldInput) => {
    output[fieldInput] = fieldInputs[fieldInput].value;
  });
  return output;
};
