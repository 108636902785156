import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";
import Macros from "./macros";
import { formatCurrency } from "../../../../../utilities/numbers";

const NotTaxableIsBlendedParagraph = ({
  startBalance,
  runs,
  pass,
  fail,
  isMoreThanOne,
  allBlendeds,
}) => {
  let blendedString = `${formatCurrency(
    allBlendeds[0].blendedAmount
  )} annually from ${allBlendeds[0].blendedStart} to ${
    allBlendeds[0].blendedEnd
  },`;
  for (let i = 1; i < allBlendeds.length; i++) {
    blendedString += ` as well as ${formatCurrency(
      allBlendeds[i].blendedAmount
    )} annually from ${allBlendeds[i].blendedStart} to ${
      allBlendeds[i].blendedEnd
    },`;
  }

  return (
    <Text
      style={[styles.fontm]}
    >{`To summarize, with a starting balance of ${startBalance} and the analysis performed in a Monte Carlo fashion of ${runs} runs, with a Structured Settlement Benefit paying ${blendedString} it was determined that the likelihood that the market-based portfolio alone would out-perform the Structured Settlement ${Macros.PostPlural(
      isMoreThanOne,
      "annuit"
    )} was ${pass}. In other words, this means the tax-free and guaranteed Structured Settlement Annuity, in conjunction with a market-based investment, out-performed the market-based portfolio alone ${fail} of the time.`}</Text>
  );
};
export default NotTaxableIsBlendedParagraph;
