import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import toast from "../../../utilities/toast";
import SetupView from "./setup-view";
import utils from "./setup-utils";
import AllRuns from "../../../utilities/all-runs";
import { saveRunResults } from "../../../redux/analyzer/analyzer.actions";

const Setup = ({ saveRunResults }) => {
  const toastRef = useRef(null);
  const [isRateDisabled, setIsRateDisabled] = useState(false);
  const [isPercentDisabled, setIsPercentDisabled] = useState(false);
  const [isBlendedOn, setIsBlendedOn] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [inputs, setInputs] = useState(utils.initializeInputs);
  const [draws, setDraws] = useState([]);
  const [inputDraw, setInputDraw] = useState(utils.initializeInputDraw);
  const [incomes, setIncomes] = useState([]);
  const [inputIncome, setInputIncome] = useState(utils.initializeInputIncome);
  const [blendeds, setBlendeds] = useState([]);
  const [inputBlended, setInputBlended] = useState(
    utils.initializeInputBlended
  );
  const { startYear, numberOfYears } = inputs;
  const allInputs = { inputs, inputDraw, inputIncome, inputBlended };
  const analyzerInputs = { inputs, draws, incomes, blendeds };

  useEffect(() => {
    checkCalculatorInputsReady();
  }, [inputs]);

  const checkCalculatorInputsReady = () => {
    setIsValid(utils.isInputReady(inputs));
  };

  const handleReset = () => {
    setIsRateDisabled(false);
    setIsPercentDisabled(false);
    setIsBlendedOn(false);
    setIsValid(false);
    setInputs(utils.initializeInputs);
    setDraws([]);
    setInputDraw(utils.initializeInputDraw);
    setIncomes([]);
    setInputIncome(utils.initializeInputIncome);
    setBlendeds([]);
    setInputBlended(utils.initializeInputBlended);
  };
  const handleInput = (e) => {
    e.target.value = e.target.value === null ? 0 : e.target.value;
    let returnInputs = utils.inputChange(e, allInputs);
    if (returnInputs.inputs !== null) setInputs(returnInputs.inputs);
    if (returnInputs.inputDraw !== null) setInputDraw(returnInputs.inputDraw);
    if (returnInputs.inputIncome !== null)
      setInputIncome(returnInputs.inputIncome);
    if (returnInputs.inputBlended !== null)
      setInputBlended(returnInputs.inputBlended);
  };

  const handleTax = async (e) => {
    if (e.target.id === "yearMarried") {
      return setInputs({ ...inputs, yearMarried: e.value });
    }
    if (e.target.name === "singleToMarried") {
      return inputs.singleToMarried === true
        ? setInputs({
            ...inputs,
            singleToMarried: !inputs.singleToMarried,
            yearMarried: inputs.startYear,
          })
        : setInputs({ ...inputs, singleToMarried: !inputs.singleToMarried });
    }
    return e.target.value.includes("Married")
      ? setInputs({
          ...inputs,
          taxFiling: e.value,
          singleToMarried: false,
          yearMarried: inputs.startYear,
        })
      : setInputs({ ...inputs, taxFiling: e.value });
  };
  const handleGain = (e) => {
    if (e.target.name === "sp500_vbtix") {
      setIsRateDisabled(true);
      setIsPercentDisabled(false);
      return setInputs({ ...inputs, gainValue: e.target.name, bondRate: 0 });
    }
    if (e.target.name.startsWith("sp500_vbtix_60")) {
      setIsRateDisabled(true);
      setIsPercentDisabled(true);
      return setInputs({
        ...inputs,
        gainValue: e.target.name,
        bondRate: 0,
        percentBonds: 0,
      });
    }
    setIsRateDisabled(false);
    setIsPercentDisabled(false);
    setInputs({ ...inputs, gainValue: e.target.name });
  };
  const handleInputDraw = (e) => {
    setInputDraw({ ...inputDraw, [e.target.name]: e.target.value });
  };
  const handleInputIncome = (e) => {
    setInputIncome({ ...inputIncome, [e.target.name]: e.target.value });
  };
  const handleInputBlended = (e) => {
    setInputBlended({ ...inputBlended, [e.target.name]: e.target.value });
  };

  const handleAddDraw = () => {
    let res = utils.checkDraw(inputDraw, startYear, numberOfYears);
    if (!res.isValid) return toast.showToast(toastRef, res.toastMessage);
    setDraws([...draws, utils.drawToAdd(inputDraw, draws)]);
    setInputDraw(utils.initializeDraw(startYear, numberOfYears));
  };

  const handleDeleteDraw = (row) => {
    setDraws(utils.deleteDraw(draws, row.id));
  };
  const handleAddIncome = () => {
    let res = utils.checkIncome(inputIncome, startYear, numberOfYears);
    if (!res.isValid) return toast.showToast(toastRef, res.toastMessage);
    setIncomes([...incomes, utils.incomeToAdd(inputIncome, incomes)]);
    setInputIncome(utils.initializeIncome(startYear, numberOfYears));
  };

  const handleDeleteIncome = (row) => {
    setIncomes(utils.deleteIncome(incomes, row.id));
  };
  const handleAddBlended = () => {
    let res = utils.checkBlended(inputBlended, startYear, numberOfYears);
    if (!res.isValid) return toast.showToast(toastRef, res.toastMessage);
    setIsBlendedOn(true);
    setInputs({
      ...inputs,
      percentBonds: 0.0,
      bondRate: 0.0,
      gainValue: "sp500_adjusted",
    });
    setBlendeds([...blendeds, utils.blendedToAdd(inputBlended, blendeds)]);
    setInputBlended(utils.initializeBlended(startYear, numberOfYears));
  };

  const handleDeleteBlended = (row) => {
    if (blendeds.length <= 1) {
      setIsBlendedOn(false);
      setIsRateDisabled(false);
      setIsPercentDisabled(false);
    }
    let newBlendeds = utils.deleteBlended(blendeds, row.id);
    setBlendeds(newBlendeds);
  };

  const handleCalculate = (e) => {
    let res = utils.checkDrawsArray(draws, startYear, numberOfYears);
    if (!res.isValid) return toast.showToast(toastRef, res.toastMessage);
    res = utils.checkIncomesArray(incomes, startYear, numberOfYears);
    if (!res.isValid) return toast.showToast(toastRef, res.toastMessage);
    res = utils.checkBlendedsArray(blendeds, startYear, numberOfYears);
    if (!res.isValid) return toast.showToast(toastRef, res.toastMessage);

    const allRuns = new AllRuns(analyzerInputs);
    setInputs({ ...inputs, startBalance: analyzerInputs.inputs.settlement });
    saveRunResults(allRuns.results);
  };

  return (
    <>
      <Toast ref={toastRef} />
      <SetupView
        allInputs={allInputs}
        inputArrays={{ draws, incomes, blendeds }}
        handlers={{
          handleInput,
          handleTax,
          handleGain,
          handleInputDraw,
          handleInputIncome,
          handleInputBlended,
          handleAddDraw,
          handleAddIncome,
          handleAddBlended,
          handleDeleteDraw,
          handleDeleteIncome,
          handleDeleteBlended,
          handleCalculate,
        }}
        handleReset={handleReset}
        isValid={isValid}
        isRateDisabled={isRateDisabled}
        isPercentDisabled={isPercentDisabled}
        isBlendedOn={isBlendedOn}
      />
    </>
  );
};

export default connect(null, {
  saveRunResults,
})(Setup);
