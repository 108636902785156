import React from "react";
import { Text, View } from "@react-pdf/renderer";

import styles from "../quote-page.styles";

const P4Footer = ({ loc, name, title }) => {
  return (
    <View style={loc}>
      <Text
        style={[styles.text12, styles.right, styles.bold, styles.underline]}
      >
        Presented By:
      </Text>
      <Text style={[styles.text12, styles.right]}>{name}</Text>
      {title !== "" && (
        <Text style={[styles.text12, styles.right]}>{title}</Text>
      )}
    </View>
  );
};

export default P4Footer;
