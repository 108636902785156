import React, { Fragment } from "react";
import { Checkbox } from "primereact/checkbox";

const InputFieldCheckbox = ({
  handler,
  variables: { value, disabled },
  constants: { id, label, medSize, name },
}) => {
  return (
    <Fragment>
      <div className={`p-field p-col-${medSize}`}>
        <label htmlFor={id} className='p-d-block p-text-center'>
          {label}
        </label>
        <div className='p-inputtext p-d-flex p-jc-center no-border'>
          <Checkbox
            name={name}
            id={id}
            disabled={disabled}
            onChange={handler}
            checked={value}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default InputFieldCheckbox;
