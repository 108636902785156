const analyzerPieOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.5,
  plugins: {
    legend: {
      labels: {
        color: "#000000",
      },
      position: "bottom",
    },
  },
};

export default analyzerPieOptions;
