import axios from "axios";
import { QuoteActionTypes } from "./quote.types";
import { dbToRedux, dbToReduxArray } from "./quote.action-helpers";

const proposalsURL = "/api/v1/proposals/";
const quotesURL = "/api/v1/quotes/";
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

//////////////////////////////////////////////////////////////
// called from SavedQuotes
//////////////////////////////////////////////////////////////
export const getQuotes = () => async (dispatch) => {
  try {
    let res = await axios.get(proposalsURL, config);
    let proposals = dbToReduxArray(res.data.data);
    return dispatch({
      type: QuoteActionTypes.GET_QUOTES_PASS,
      payload: proposals,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.GET_QUOTES_FAIL,
    });
  }
};

export const loadQuote = (id) => async (dispatch) => {
  try {
    return dispatch({
      type: QuoteActionTypes.LOAD_QUOTE_PASS,
      payload: id,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.LOAD_QUOTE_FAIL,
    });
  }
};

export const copyQuote = (id) => async (dispatch) => {
  try {
    let res = await axios.get(`${proposalsURL}${id}/copy`, config);
    const proposals = dbToReduxArray(res.data.data);
    return dispatch({
      type: QuoteActionTypes.COPY_QUOTE_PASS,
      payload: proposals,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.COPY_QUOTE_FAIL,
    });
  }
};

export const deleteQuote = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${proposalsURL}${id}`, config);
    sessionStorage.removeItem("ssProposal-details");
    sessionStorage.removeItem("ssProposal-benefit");
    return dispatch({
      type: QuoteActionTypes.DELETE_QUOTE_PASS,
      payload: res.data.data._id,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.DELETE_QUOTE_FAIL,
    });
  }
};

////////////////////////////////////////////////////////////////
// called from QuoteDetails
////////////////////////////////////////////////////////////////
export const addQuote = (quoteDetails) => async (dispatch) => {
  try {
    const res = await axios.post(proposalsURL, quoteDetails.output, config);
    let details = dbToRedux(res.data.data);

    return dispatch({
      type: QuoteActionTypes.ADD_QUOTE_PASS,
      payload: details,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.ADD_QUOTE_FAIL,
    });
  }
};

export const saveQuote = (quoteDetails) => async (dispatch) => {
  let id = quoteDetails.output.id;
  try {
    const res = await axios.patch(
      `${proposalsURL}${id}`,
      quoteDetails.output,
      config
    );
    let details = dbToRedux(res.data.data);
    return dispatch({
      type: QuoteActionTypes.SAVE_QUOTE_PASS,
      payload: details,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.SAVE_QUOTE_FAIL,
    });
  }
};

export const editQuote = () => async (dispatch) => {
  return dispatch({
    type: QuoteActionTypes.START_EDIT_QUOTE_PASS,
  });
};
export const newQuote = () => async (dispatch) => {
  return dispatch({
    type: QuoteActionTypes.NEW_QUOTE_PASS,
  });
};
export const cancelQuote = () => async (dispatch) => {
  return dispatch({
    type: QuoteActionTypes.CANCEL_QUOTE_PASS,
  });
};

////////////////////////////////////////////////////////////////
// called from QuoteDetails -other actions
////////////////////////////////////////////////////////////////

export const saveAutomaticBenefits =
  (inBenefits, lifeCompany, id) => async (dispatch) => {
    try {
      const benefits = {
        benefitsToChange: inBenefits,
        lifeCompany: lifeCompany,
      };
      const res = await axios.patch(
        `${proposalsURL}${id}/autofix`,
        benefits,
        config
      );
      const updatedProposal = res.data.data.benefits;

      return dispatch({
        type: QuoteActionTypes.SAVE_AUTO_BENEFITS_PASS,
        payload: updatedProposal,
      });
    } catch (err) {
      return dispatch({
        type: QuoteActionTypes.SAVE_AUTO_BENEFITS_FAIL,
      });
    }
  };

export const setSavedQuoteLoaded = () => async (dispatch) => {
  return dispatch({
    type: QuoteActionTypes.SAVE_QUOTE_LOADED,
  });
};

export const disableBenefitsInPdf = () => (dispatch) => {
  return dispatch({
    type: QuoteActionTypes.DISABLE_BENEFITS_PDF,
  });
};
export const enableBenefitsInPdf = () => (dispatch) => {
  return dispatch({
    type: QuoteActionTypes.ENABLE_BENEFITS_PDF,
  });
};

////////////////////////////////////////////////////////////////
// called from AddBenefit
////////////////////////////////////////////////////////////////
export const addBenefit = (inBenefit) => async (dispatch) => {
  const _id = inBenefit.proposal;
  try {
    const res = await axios.post(
      `${proposalsURL}${_id}/benefit`,
      inBenefit,
      config
    );
    const proposal = res.data.data;
    return dispatch({
      type: QuoteActionTypes.ADD_BENEFIT_PASS,
      payload: proposal,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.ADD_BENEFIT_FAIL,
    });
  }
};

export const saveEditBenefit = (inBenefit) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${proposalsURL}${inBenefit.proposal}/benefit/${inBenefit._id}`,
      inBenefit,
      config
    );
    const proposal = res.data.data;
    return dispatch({
      type: QuoteActionTypes.FINISH_EDIT_BENEFIT_PASS,
      payload: proposal,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.FINISH_EDIT_BENEFIT_FAIL,
    });
  }
};

export const cancelBenefit = () => async (dispatch) => {
  try {
    return dispatch({
      type: QuoteActionTypes.CANCEL_EDIT_BENEFIT_PASS,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.CANCEL_EDIT_BENEFIT_FAIL,
    });
  }
};

////////////////////////////////////////////////////////////////
// called from ViewBenefits
////////////////////////////////////////////////////////////////
export const editBenefit = (benefitEdit) => async (dispatch) => {
  try {
    return dispatch({
      type: QuoteActionTypes.START_EDIT_BENEFIT_PASS,
      payload: benefitEdit,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.START_EDIT_BENEFIT_FAIL,
    });
  }
};

export const deleteBenefit =
  ({ proposal, benefit }) =>
  async (dispatch) => {
    try {
      const res = await axios.delete(
        `${proposalsURL}${proposal}/benefit/${benefit}`,
        config
      );
      return dispatch({
        type: QuoteActionTypes.DELETE_BENEFIT_PASS,
        payload: res.data.data,
      });
    } catch (err) {
      return dispatch({
        type: QuoteActionTypes.DELETE_BENEFIT_FAIL,
      });
    }
  };

export const copyBenefit = (prop, ben) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${proposalsURL}${prop}/benefit/${ben}/copy`,
      config
    );
    const proposal = res.data.data;
    return dispatch({
      type: QuoteActionTypes.COPY_BENEFIT_PASS,
      payload: proposal,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.COPY_BENEFIT_FAIL,
    });
  }
};

export const updateBenefitOrder = (quoteId, benefitIds) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `${proposalsURL}${quoteId}/benefit`,
      benefitIds,
      config
    );
    let updatedProposal = res.data.data;
    return dispatch({
      type: QuoteActionTypes.REORDER_BENEFITS_PASS,
      payload: updatedProposal,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.REORDER_BENEFITS_FAIL,
    });
  }
};

export const updatePageBreak = (id, pb) => async (dispatch) => {
  let pageBreak = { pageBreak: pb };
  try {
    let res = await axios.patch(
      `${proposalsURL}${id}/pagebreak`,
      pageBreak,
      config
    );
    return dispatch({
      type: QuoteActionTypes.SET_PAGE_BREAK,
      payload: res.data.data,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.SET_PAGE_BREAK_FAIL,
    });
  }
};

///////////////////////////////////////////////////////////////
// MISC
///////////////////////////////////////////////////////////////
export const unloadQuote = () => async (dispatch) => {
  return dispatch({
    type: QuoteActionTypes.UNLOAD_QUOTE,
  });
};

//////////////////////////////////////////////////////////////
//  May be used for Admin, but should be re-written
//////////////////////////////////////////////////////////////
export const getAdminQuotes = () => async (dispatch) => {
  try {
    let res = await axios.get(`${quotesURL}admin`, config);
    let proposals = dbToReduxArray(res.data.data);

    return dispatch({
      type: QuoteActionTypes.GET_QUOTES_PASS,
      payload: proposals,
    });
  } catch (err) {
    return dispatch({
      type: QuoteActionTypes.GET_QUOTES_FAIL,
    });
  }
};
