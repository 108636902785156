import Dates from "../../utilities/dates";
export const benefitInputs = {
  lifeCompany: {
    label: "Life Company",
    name: "lifeCompany",
    id: "life-company-2",
    medSize: 3,
    required: true,
    minimum: 1,
    maximum: 99999,
    textError: "Please select a Life Company",
    validate: "text",
  },
  benefitType: {
    label: "Benefit Type",
    name: "benefitType",
    id: "benefit-type",
    medSize: 4,
    required: true,
    minimum: 1,
    maximum: 99999,
    textError: "Please select a Benefit Type",
    validate: "text",
  },
  benefitAmount: {
    label: "Benefit Amount",
    name: "benefitAmount",
    id: "benefit-amount",
    medSize: 3,
    required: false,
    minimum: 1,
    maximum: 99999,
    textError: "Please select a Benefit Amount",
    validate: "number",
  },
  modePeriod: {
    label: "Mode",
    name: "modePeriod",
    id: "mode-period",
    medSize: 2,
    required: false,
    minimum: 1,
    maximum: 99999,
    textError: "Please select a Mode",
    validate: "text",
  },
  startDate: {
    label: "Start Date",
    name: "startDate",
    id: "start-date",
    medSize: 3,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter a Start Date",
    validate: "date",
  },
  endDate: {
    label: "End Date",
    name: "endDate",
    id: "end-date",
    medSize: 3,
    required: false,
    minimum: 0,
    maximum: 99999,
    textError: "Please enter an End Date",
    validate: "date",
  },
  termYears: {
    label: "Years",
    name: "termYears",
    id: "term-years",
    medSize: 2,
    required: false,
    minimum: 0,
    maximum: 52000,
    textError: "Please enter the number of years",
    validate: "date",
  },
  termMonths: {
    label: "Months",
    name: "termMonths",
    id: "term-months",
    medSize: 2,
    required: false,
    minimum: 0,
    maximum: 11,
    textError: "Please enter the number of months",
    validate: "date",
  },
  colaPercent: {
    label: "COLA%",
    name: "colaPercent",
    id: "cola-pecent",
    medSize: 2,
    required: false,
    minimum: 0,
    maximum: 5,
    textError: "Please enter the COLA Percent",
    validate: "date",
  },
  annuityPremium: {
    label: "Annuity Premium",
    name: "annuityPremium",
    id: "annuity-premium",
    medSize: 3,
    required: false,
    minimum: 1,
    maximum: 99999,
    textError: "Please enter an Annuity Premium",
    validate: "date",
  },
  guaranteedBenefit: {
    label: "Guaranteed Benefit",
    name: "guaranteedBenefit",
    id: "guaranteed-benefit",
    medSize: 3,
    required: false,
    minimum: 1,
    maximum: 99999,
    textError: "Please enter a Guaranteed Benefit",
    validate: "date",
  },
  expectedBenefit: {
    label: "Expected Benefit",
    name: "expectedBenefit",
    id: "expected-benfit",
    medSize: 3,
    required: false,
    minimum: 1,
    maximum: 99999,
    textError: "Please enter an Expected Benefit",
    validate: "date",
  },
  benefitTitle: {
    label: "Benefit Title",
    name: "benefitTitle",
    id: "benefit-title",
    medSize: 12,
    required: false,
    minimum: 1,
    maximum: 50,
    textError: "Please enter an Expected Benefit",
    validate: "text",
  },
};

const inp = {
  value: "",
  showError: false,
  disabled: true,
};

const inpDate = {
  value: Dates.today(),
  showError: false,
  disabled: true,
};
const inpDate2 = {
  value: Dates.today(),
  showError: false,
  disabled: true,
};

const inpNumber1 = {
  value: 0,
  showError: false,
  disabled: true,
};
const inpNumber2 = {
  value: 0,
  showError: false,
  disabled: true,
};
const inpNumber3 = {
  value: 0,
  showError: false,
  disabled: true,
};
const inpNumber4 = {
  value: 0,
  showError: false,
  disabled: true,
};
const inpNumber5 = {
  value: 0,
  showError: false,
  disabled: true,
};
const inpNumber6 = {
  value: 0,
  showError: false,
  disabled: true,
};
const inpNumber7 = {
  value: 0,
  showError: false,
  disabled: true,
};
export const benefitInitInputs = {
  lifeCompany: inp,
  benefitType: inp,
  benefitAmount: inpNumber1,
  modePeriod: inp,
  startDate: inpDate,
  endDate: inpDate2,
  termYears: inpNumber2,
  termMonths: inpNumber3,
  colaPercent: inpNumber4,
  annuityPremium: inpNumber5,
  guaranteedBenefit: inpNumber6,
  expectedBenefit: inpNumber7,
  benefitTitle: inp,
  lifeCompanyOptions: [],
  benefitTypeOptions: [],
  modePeriodOptions: [],
};

export const benefitAddButtons = {
  cancel: {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-raised p-button-warning",
  },
  save: {
    label: "Save Changes",
    icon: "pi pi-plus",
    className: "p-button-raised",
  },
  clear: {
    label: "Clear",
    icon: "pi pi-times",
    className: "p-button-raised p-button-warning",
  },
  add: {
    label: "Add Benefit",
    icon: "pi pi-plus",
    className: "p-button-raised",
  },
};
