import React, { Fragment } from "react";

import AddBroker from "./add-broker.component";
import ViewBrokers from "./view-brokers.component";
import Unsubscribe from "../unsubscribe/unsubscribe.component";

const Brokers = () => {
  return (
    <Fragment>
      <div className='broker-page-container p-shadow-12'>
        <AddBroker />
        <div className='p-m-4' />
        <ViewBrokers />
      </div>
      <Unsubscribe />
    </Fragment>
  );
};

export default Brokers;
