import React from "react";
import {
  FixedContainer,
  InnerContainer,
  Container,
  Message,
} from "./unsubscribe.styles";

const Unsubscribe = ({ fixedBottom }) => {
  return (
    <>
      {fixedBottom ? (
        <FixedContainer>
          <InnerContainer>
            <Message>Contact admin@setanalyzer.com to unsubscribe</Message>
          </InnerContainer>
        </FixedContainer>
      ) : (
        <Container>
          <Message>Contact admin@setanalyzer.com to unsubscribe</Message>
        </Container>
      )}
    </>
  );
};

export default Unsubscribe;
