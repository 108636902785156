import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import axios from "axios";
import AddUser from "./add-user.component";

const ShowUsers = ({ admin }) => {
  const toast = useRef(null);
  const [users, setUsers] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (admin.isNewUserAdded === 1) getAllUsers();
  }, [admin.isNewUserAdded]);

  const getAllUsers = async (e) => {
    try {
      const res = await axios.get("/api/v1/users", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      setUsers(res.data.data.data);
    } catch (err) {}
  };

  const handleSeeder = async () => {
    try {
      const res = await axios.patch("/api/v1/seeders", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
    } catch (err) {}
  };

  const header = (
    <div className='p-d-flex p-jc-between'>
      <p className='m-0 fontl'>Users</p>
      <AddUser />
      {/* <Button label='Run Seeder' onClick={handleSeeder} /> */}
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  const yesOrNo = (rowData) => {
    return rowData.isEmailConfirmed === true ? "Yes" : "No";
  };

  const handleRole = async (row) => {
    let role = "user";
    if (row.role === "user") role = "employee";
    try {
      const res = await axios.patch(
        `/api/v1/users/${row._id}/admin-role`,
        { role: role },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      return;
    }
    let user = users.findIndex((user) => user._id === row._id);
    let tempUsers = [...users];
    tempUsers[user]["role"] = role;
    setUsers(tempUsers);
  };

  const handleConfirm = async (row) => {
    try {
      const res = await axios.patch(
        `/api/v1/users/${row._id}/admin-confirm`,
        { isEmailConfirmed: true },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      return;
    }
    let user = users.findIndex((user) => user._id === row._id);
    let tempUsers = [...users];
    tempUsers[user]["isEmailConfirmed"] = true;
    setUsers(tempUsers);
  };

  const confirmButton = (rowData) => {
    if (
      rowData.isEmailConfirmed === true ||
      rowData.subscriberStatus === "incomplete"
    )
      return "";
    return (
      <div>
        <Button
          className='p-button-help p-button-sm'
          label='Confirm'
          onClick={() => handleConfirm(rowData)}
        />
      </div>
    );
  };
  const roleButton = (rowData) => {
    if (rowData.role === "admin") return "";

    return (
      <Button
        className={
          rowData.role === "employee"
            ? "p-button-success p-button-sm"
            : "p-button-info p-button-sm"
        }
        label={rowData.role === "employee" ? "Paid User" : "Employee"}
        onClick={() => handleRole(rowData)}
      />
    );
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className='p-shadow-12'>
        <Card className='small-table' id='admin-user'>
          <DataTable
            value={users}
            dataKey='_id'
            paginator={users.length > 10}
            paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            header={header}
            globalFilter={globalFilter}
          >
            <Column
              field='email'
              header='Email'
              sortable={true}
              style={{ width: "25%" }}
            />

            <Column
              field='subscriberStatus'
              header='Subscriber Status'
              sortable={true}
              style={{ width: "15%" }}
            />
            <Column
              field='isEmailConfirmed'
              header='Email Confirmed'
              body={yesOrNo}
              sortable={true}
              style={{ width: "15%" }}
            />
            <Column
              field='role'
              header='Role'
              sortable={true}
              style={{ width: "15%" }}
            />
            <Column
              body={confirmButton}
              header='Confirm Email'
              style={{ width: "15%" }}
            />
            <Column
              body={roleButton}
              header='Change Roll'
              style={{ width: "15%" }}
            />
          </DataTable>
        </Card>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, null)(ShowUsers);

//   const handleResendInvite = async (e) => {
//     const res = await axios.get(
//       `/api/v1/users/resend-invite/${rowData._id}`,
//       {
//         headers: { "Content-Type": "application/json" },
//         withCredentials: true,
//       }
//     );
//     toast.current.show({
//       severity: "success",
//       summary: "Invite was sent",
//       detail: `An email invite was sent to ${rowData.email}`,
//       life: 3000,
//     });
//   };
//   if (resend === true)
//     return (
//       <Button
//         className='p-button-raised p-button-success p-mr-2 p-button-sm'
//         onClick={handleResendInvite}
//         label='Resend Invite'
//       />
//     );
// };
