import React, { Fragment } from "react";
import { Page, View, Image } from "@react-pdf/renderer";
import styles from "./quote-page.styles";
import CustomizedHeader from "./customized-header.component";
import P2Header from "./views/p2-header.view";
import P2Guarantee from "./views/p2-guarantee.view";
import P2Irr from "./views/p2-irr.view";
import P2Table from "./views/p2-table.view";

const QuotePage2 = ({ details, pagebreak }) => {
  return (
    <Fragment>
      {(details !== null) & (details.brokerName !== null) && (
        <Page size='letter' object-fit='fill' style={styles.page}>
          <View style={styles.section} wrap={true}>
            {details.brokerName.colors !== undefined && (
              <CustomizedHeader
                addOn='1'
                colors={details.brokerName.colors}
                style={styles.canvasHeader}
              />
            )}
            <P2Header
              broker={details.brokerName}
              claimant={details.claimantsName}
              loc={styles.header_page2}
            />
            <P2Guarantee
              life_companies={details.lifeCompany}
              disclaimer={details.brokerName.firmDisclaimer}
              proposalNotes={details.proposalNotes}
              name={details.brokerName.brokerName}
              title={details.brokerName.brokerTitle}
              loc={styles.guarantee}
            />
            {details.irrPercent > 0 && (
              <P2Irr irr={details.irrPercent} loc={styles.presented2} />
            )}
            {pagebreak > 0 && pagebreak < details.benefits.length ? (
              <P2Table
                loc={styles.table_location}
                benefits={details.benefits.filter(
                  (value, index) => index < pagebreak
                )}
                skipTotal={true}
                skipHeader={false}
                skipPremium={!details.showPremium}
              />
            ) : (
              <P2Table
                loc={styles.table_location}
                benefits={details.benefits}
                // skipTotal={false}
                total={details.benefits}
                skipTotal={!details.showTotal}
                skipHeader={false}
                skipPremium={!details.showPremium}
              />
            )}
            <Image
              style={styles.logo_small}
              src={`/img/logos/${details.brokerName.logo}`}
              cache={false}
            />
          </View>
        </Page>
      )}
    </Fragment>
  );
};

export default QuotePage2;
