import Dates from "./dates";

export const findObjectInArray = (array, itemInArray, item) => {
  return array.find((c) => c[itemInArray] === item);
};

export const configWithCred = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

export const pdfDisplayedFilingStatusWithMarried = (stat, year) => {
  if (stat === "no tax") return "N/A";
  if (stat === "Trust") return "Trust";
  let displayed = stat.includes("federal")
    ? "Federal Tax Rates "
    : stat.includes("ltcg")
    ? "LTCG "
    : "Hybrid ";
  if (year === 9999) {
    displayed += stat.includes("Single") ? "(Single)" : "(Married)";
    return displayed;
  }
  displayed += `(Single and changing to Married in year ${year})`;
  return displayed;
};
export const displayedFilingStatusWithMarried = (stat, year) => {
  if (stat === "no tax") return "N/A";
  if (stat === "Trust") return "Trust";
  let displayed = stat.includes("federal")
    ? "Federal "
    : stat.includes("ltcg")
    ? "LTCG "
    : "Hybrid";
  if (year === 9999) {
    displayed += stat.includes("Single") ? "Single " : "Married ";
    return displayed;
  }
  displayed += `S-M in ${year}`;
  return displayed;
};

export const dataOrUndefined = (data) => {
  if (data === undefined) return "";
  if (Dates.isDataADate(data)) return new Date(data);
  return data;
};
export const dataOrZero = (data) => {
  if (data === undefined) return 0;
  return data;
};
export const fillArray = (count, value) => {
  return Array(count).fill(value);
};
export const fillTwoDimensionalArray = (count1, count2, value) => {
  return Array(count1)
    .fill()
    .map(() => Array(count2).fill(value));
};

export const createAllDraws = (draws, startYear, numberOfYears) => {
  let allDraws = fillTwoDimensionalArray(numberOfYears, draws.length, 0);
  for (let i = 0; i < draws.length; i++) {
    let start = draws[i]["drawStart"] - startYear;
    let end = draws[i]["drawEnd"] - startYear;
    for (let year = start; year <= end; year++) {
      if (year === start) allDraws[year][i] = draws[i].drawAmount;
      else
        allDraws[year][i] =
          allDraws[year - 1][i] +
          (allDraws[year - 1][i] * draws[i].drawCola) / 100;
    }
  }
  return allDraws;
};
export const createAllIncomes = (incomes, startYear, numberOfYears) => {
  let allIncomes = fillTwoDimensionalArray(numberOfYears, incomes.length, 0);
  for (let i = 0; i < incomes.length; i++) {
    let start = incomes[i]["incomeStart"] - startYear;
    let end = incomes[i]["incomeEnd"] - startYear;
    for (let year = start; year <= end; year++) {
      allIncomes[year][i] = incomes[i].incomeAmount;
    }
  }
  return allIncomes;
};
export const createAllBlendeds = (blendeds, startYear, numberOfYears) => {
  let allIncomes = fillTwoDimensionalArray(numberOfYears, blendeds.length, 0);
  for (let i = 0; i < blendeds.length; i++) {
    let start = blendeds[i]["blendedStart"] - startYear;
    let end = blendeds[i]["blendedEnd"] - startYear;
    for (let year = start; year <= end; year++) {
      allIncomes[year][i] = blendeds[i].blendedAmount;
      if (year === start) allIncomes[year][i] = blendeds[i].blendedAmount;
      else
        allIncomes[year][i] =
          allIncomes[year - 1][i] +
          (allIncomes[year - 1][i] * blendeds[i].blendedCola) / 100;
    }
  }
  return allIncomes;
};

export const createYearlyAmounts = (allAmounts, numberOfYears) => {
  let total = fillArray(numberOfYears, 0);
  for (let i = 0; i < numberOfYears; i++) {
    total[i] = allAmounts[i].reduce((sum, num) => {
      return sum + num;
    }, 0);
  }
  return total;
};
