import React, { Fragment } from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";

const TextItem = ({ label, value, width, horizontal = false }) => {
  return (
    <Fragment>
      {horizontal === true ? (
        <View>
          <Text style={[styles.fonts, styles.left, { width: width }]}>
            {label}: {value}
          </Text>
        </View>
      ) : (
        <View>
          <Text
            style={[styles.fonts, styles.bold, styles.center, { width: width }]}
          >
            {label}
          </Text>
          <Text
            style={[styles.fonts, styles.mtop, styles.center, { width: width }]}
          >
            {value}
          </Text>
        </View>
      )}
    </Fragment>
  );
};

export default TextItem;
