import React from "react";
import { InputText } from "primereact/inputtext";

const EmailInput = ({ email, handleChange, isValid }) => {
  return (
    <div className='p-field k-mb-2r'>
      <span className='p-float-label'>
        <InputText
          name='email'
          value={email}
          onChange={handleChange}
          className={!isValid && "p-invalid"}
        />
        <label htmlFor='email'>Email Address</label>
      </span>
    </div>
  );
};

export default EmailInput;
