import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { decimal2 } from "../../../utilities/numbers";
import styles from "../quote-page.styles";

const P2Irr = ({ loc, irr }) => {
  const ShowIrr = () => {
    const irrString = decimal2(irr);
    return `IRR: ${irrString}%`;
  };
  return (
    <View style={loc}>
      <Text style={[styles.text12, styles.center, styles.margin0_bottom]}>
        <ShowIrr />
      </Text>
    </View>
  );
};

export default P2Irr;
