import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";
import Macros from "./macros";

const OpeningParagraph = ({ isMoreThanOne }) => {
  return (
    <Text style={[styles.fontm]}>
      On the next two pages you will find your customized Structured Settlement
      Analysis (SSA). This analysis compares what
      {Macros.PrePlural(isMoreThanOne)}Structured Settlement{" "}
      {Macros.PostPlural(isMoreThanOne, "annuit")} can guarantee versus the
      likelihood of a market-based portfolio being able to match the{" "}
      {Macros.PostPlural(isMoreThanOne, "annuit")} given market volatility and
      additional investment expenses.
    </Text>
  );
};
export default OpeningParagraph;
