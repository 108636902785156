import React from "react";
import { Checkbox } from "primereact/checkbox";

const CheckboxInputGroup = ({ id, label, value, handler }) => {
  return (
    <div className='p-col-12 p-mb-3'>
      <Checkbox
        name={id}
        value={id}
        onChange={handler}
        checked={value === id}
      />
      <label className='check-label bold-label' htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default CheckboxInputGroup;
