import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";

const CalculateButton = ({ analyzer, isValid, handleCalculate }) => {
  const [readyString, setReadyString] = useState("");
  const { selectedClaimant, selectedBroker } = analyzer;

  useEffect(() => {
    createReadyString();
    // eslint-disable-next-line
  }, [selectedClaimant, selectedBroker, isValid]);

  const createReadyString = () => {
    let params = [];
    if (selectedBroker === null) params.push("a Broker");
    if (selectedClaimant === null) params.push("a Claimant");
    if (!isValid) params.push("Valid Parameters");
    if (params.length === 0) return setReadyString("");
    if (params.length === 1) return setReadyString(`Enter ${params[0]}`);
    if (params.length === 2)
      return setReadyString(`Enter ${params[0]} and ${params[1]}`);
    if (params.length === 3)
      return setReadyString(
        `Enter ${params[0]}, ${params[1]} and ${params[2]}`
      );
  };

  return (
    <div className='k-text-center p-fluid'>
      {isValid === true &&
      selectedClaimant !== null &&
      selectedBroker !== null ? (
        <Button
          onClick={handleCalculate}
          label='Start Analyzer Run'
          icon='pi pi-caret-right'
          className='p-button-lg br-9 bg-accent200'
        />
      ) : (
        <div className='p-button-lg br-9 bg-secondary100'>
          <p className='fake-button-text br-9'>{readyString}</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  analyzer: state.analyzer,
});
export default connect(mapStateToProps, null)(CalculateButton);
