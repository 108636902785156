import React from "react";
import { InputNumber } from "primereact/inputnumber";
import TooltipAddon from "./tooltip-addon";

const NumberInput = ({ constants, value, handler }) => {
  const { id, label, size, min, max, tt } = constants;
  return (
    <div className={`p-field p-col-${size}`}>
      <label id={`label-${id}`} className='big-bold-label' htmlFor={id}>
        {label}
      </label>
      {tt != undefined && <TooltipAddon tt={tt} element={`#label-${id}`} />}
      <InputNumber
        name={id}
        id={id}
        min={min}
        max={max}
        value={value}
        onValueChange={handler}
        useGrouping={false}
      />
    </div>
  );
};

export default NumberInput;
