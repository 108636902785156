import Dates from "../../utilities/dates";

export const changeInputValue = (inputs, param) => {
  return {
    ...inputs,
    [param.name]: {
      ...inputs[param.name],
      value: param.value,
    },
  };
};

export const dateAdjustments = (input, param) => {
  let newState = { ...input };
  newState[param.name].value = param.value;
  newState[param.name].showError =
    !Dates.isStringADateFormat(param.value) ||
   
    !Dates.isStringAValidDate(param.value)
      ? true
      : false;
  return newState;
};

export const showTotalAdjustments = (input, param) => {
  let newState = {
    ...input,
    [param.name]: { value: param.checked, showError: false, disabled: false },
  };
  return newState;
};
