import React, { Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CurrencyTemplate from "../../../../lib/currency-template";
import DeleteTemplate from "../../../../lib/delete-template";
import Percent2Template from "../../../../lib/percent-2-template";

const BlendedTable = ({ tableData, handleDelete }) => {
  return (
    <Fragment>
      <DataTable
        value={tableData}
        dataKey='id'
        className='p-datatable-sm reduced-height-table'
        id='variable-blendeds-table'
      >
        <Column
          body={CurrencyTemplate}
          style={{ width: "33.33%" }}
          field='blendedAmount'
          header='Blended'
        />
        <Column
          body={Percent2Template}
          style={{ width: "16.67%" }}
          field='blendedCola'
          header='COLA%'
        />
        <Column
          style={{ width: "16.67%" }}
          field='blendedStart'
          header='Start Year'
        />
        <Column
          style={{ width: "16.67%" }}
          field='blendedEnd'
          header='End Year'
        ></Column>
        <Column
          style={{ width: "16.67%" }}
          body={(rowData) => {
            return (
              <DeleteTemplate
                rowData={rowData}
                color='blended100'
                handler={handleDelete}
              />
            );
          }}
        ></Column>
      </DataTable>
    </Fragment>
  );
};

export default BlendedTable;
