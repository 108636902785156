export const labelsArray = [
  "Life Only",
  "Life with Certain",
  "Joint & Survivor",
  "Installment Refund",
  "Temporary Life",
  "Cash Refund",
  "Upfront Cash",
  "Period Certain",
  "Endowment Lump Sum",
  "Guaranteed Lump Sum",
];
// Blue, Green, Yellow, Red,Purple,Gray,Orange,Brown,teal,antiquewhite
export const colorsArray = [
  ["#0000ff", "#1e90ff", "#00008b"],
  ["#008000", "#006400", "#2e8b57"],
  ["#ffff00", "#ffd700", "#adff2f"],
  ["#ff0000", "#800000", "#dc143c"],
  ["#800080", "#9370db", "#663399"],
  ["#c0c0c0", "#708090", "#b0e0e6"],
  ["#ffa500", "#ff4500", "#ffa07a"],
  ["#a52a2a", "#d2691e", "#ff7f50"],
  ["#008080", "#40e0d0", "#00ffff"],
  ["#fffacd", "#f0e68c", "#ffdead"],
];
