import React, { Fragment } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";

import {
  FullSpacerDiv,
  SpacerDiv,
  ColorDiv,
} from "./header-color-picker.styles";

const HeaderColorPicker = ({
  colors,
  colors: { color0, color1, color2, format },
  handler,
}) => {
  return (
    <Fragment>
      <div className='p-grid'>
        <div className='p-col-4 p-shadow-12'>
          <Card title='PDF Color Format'>
            <FullSpacerDiv className='p-field-radiobutton'>
              <RadioButton
                inputId='solid'
                name='format'
                value='solid'
                onChange={handler}
                checked={format === "solid"}
              />
              <label htmlFor='dolid'>Solid</label>
            </FullSpacerDiv>

            <FullSpacerDiv className='p-field-radiobutton'>
              <RadioButton
                inputId='horizontal'
                name='format'
                value='horizontal'
                onChange={handler}
                checked={format === "horizontal"}
              />
              <label htmlFor='horizontal'>Horizontal</label>
            </FullSpacerDiv>
            <FullSpacerDiv className='p-field-radiobutton'>
              <RadioButton
                inputId='vertical'
                name='format'
                value='vertical'
                onChange={handler}
                checked={format === "vertical"}
              />
              <label htmlFor='vertical'>Vertical</label>
            </FullSpacerDiv>
          </Card>
        </div>

        <div className='p-col-4 p-shadow-12'>
          <Card title='PDF Color Pickers'>
            <div className='p-grid'>
              <SpacerDiv className='p-col-4' />
              {format === "horizontal" && <SpacerDiv className='p-col-4' />}
              {format === "horizontal" && <SpacerDiv className='p-col-4' />}
              {format !== "solid" ? (
                <ColorPicker
                  name='color0'
                  tooltip={`Color is #${color0}`}
                  className='p-col-4'
                  value={color0}
                  onChange={handler}
                />
              ) : (
                <SpacerDiv className='p-col-4' />
              )}
              {format === "vertical" && <SpacerDiv className='p-col-4' />}
              {format === "vertical" && <SpacerDiv className='p-col-4' />}
              {format === "solid" && <SpacerDiv className='p-col-4' />}
              {format === "solid" && <SpacerDiv className='p-col-4' />}
              {format === "solid" ? (
                <ColorPicker
                  name='color1'
                  tooltip={`Color is #${color1}`}
                  className='p-col-4'
                  value={color1}
                  onChange={handler}
                />
              ) : (
                <SpacerDiv className='p-col-4' />
              )}
              {format === "vertical" && <SpacerDiv className='p-col-4' />}
              {format === "vertical" && <SpacerDiv className='p-col-4' />}
              {format !== "solid" ? (
                <ColorPicker
                  name='color2'
                  tooltip={`Color is #${color2}`}
                  className='p-col-4'
                  value={color2}
                  onChange={handler}
                />
              ) : (
                <SpacerDiv className='p-col-4' />
              )}
              {format !== "vertical" && <SpacerDiv className='p-col-4' />}
              {format !== "vertical" && <SpacerDiv className='p-col-4' />}
            </div>
          </Card>
        </div>
        <div className='p-col-4 p-shadow-12'>
          <Card title='PDF Color Text Inputs'>
            <div className='p-grid'>
              <SpacerDiv className='p-col-4' />
              {format === "horizontal" && <SpacerDiv className='p-col-4' />}
              {format === "horizontal" && <SpacerDiv className='p-col-4' />}
              {format !== "solid" ? (
                <InputText
                  size='3'
                  name='color3'
                  onChange={handler}
                  value={color0}
                  maxLength='6'
                  keyfilter='hex'
                />
              ) : (
                <SpacerDiv className='p-col-4' />
              )}
              {format === "vertical" && <SpacerDiv className='p-col-4' />}
              {format === "vertical" && <SpacerDiv className='p-col-4' />}
              {format === "solid" && <SpacerDiv className='p-col-4' />}
              {format === "solid" && <SpacerDiv className='p-col-4' />}
              {format === "solid" ? (
                <InputText
                  size='3'
                  name='color4'
                  onChange={handler}
                  value={color1}
                  maxLength='6'
                  keyfilter='hex'
                />
              ) : (
                <SpacerDiv className='p-col-4' />
              )}
              {format === "vertical" && <SpacerDiv className='p-col-4' />}
              {format === "vertical" && <SpacerDiv className='p-col-4' />}
              {format !== "solid" ? (
                <InputText
                  size='3'
                  name='color5'
                  onChange={handler}
                  value={color2}
                  maxLength='6'
                  keyfilter='hex'
                />
              ) : (
                <SpacerDiv className='p-col-4' />
              )}
              {format !== "vertical" && <SpacerDiv className='p-col-4' />}
              {format !== "vertical" && <SpacerDiv className='p-col-4' />}
            </div>
          </Card>
        </div>

        <div className='p-col-12 p-shadow-12'>
          <Card title='PDF Color Display'>
            <ColorDiv colors={colors}></ColorDiv>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default HeaderColorPicker;
