import axios from "axios";
import { validateInputs } from "./validators";

const apiRegister = "/api/v1/bookings/signup";
const apiForgot = "/api/v1/users/forgot";
const apiReset = "/api/v1/users/reset";

export const handleApiRegister = async ({ userIn }) => {
  let apiUrl = apiRegister;
  try {
    const res = await axios.post(apiUrl, userIn, {
      headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    return {
      bypass: "An error occurred, please contact admin@setanalyzer.com",
    };
  }
};

export const handleApiForgot = async ({ userIn, setValid, setLoading }) => {
  const { emailValid, passwordValid, passwordConfirmValid } =
    validateInputs(userIn);
  setValid({
    isEmailValid: emailValid,
    isPasswordValid: passwordValid,
    isPasswordConfirmValid: passwordConfirmValid,
  });
  if (!emailValid || !passwordValid || !passwordConfirmValid) return;
  setLoading(true);

  let apiUrl = apiForgot;
  let res;
  try {
    res = await axios.post(apiUrl, userIn, {
      headers: { "Content-Type": "application/json" },
    });
    setLoading(false);
    return res;
  } catch (err) {
    res = { data: { status: "Failed" } };
  }
  setLoading(false);
  return res;
};

export const handleApiChangePassword = async ({
  userIn,
  token,
  setValid,
  setLoading,
}) => {
  const { passwordValid, passwordConfirmValid } = validateInputs(userIn);
  setValid({
    isEmailValid: true,
    isPasswordValid: passwordValid,
    isPasswordConfirmValid: passwordConfirmValid,
  });
  if (!passwordValid || !passwordConfirmValid) return;
  setLoading(true);

  let apiUrl = apiReset;
  try {
    const res = await axios.patch(`${apiUrl}/${token}`, userIn, {
      headers: { "Content-Type": "application/json" },
    });

    setLoading(false);
    return res.data;
  } catch (err) {
    setLoading(false);
    throw err;
  }
};
