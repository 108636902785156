import React from "react";
import { InputNumber } from "primereact/inputnumber";
import TooltipAddon from "./tooltip-addon";

const DecimalInput = ({ constants, value, handler, isDisabled }) => {
  const {
    id,
    label,
    suffix,
    min,
    max,
    minFractionDigits,
    placeholder,
    size,
    tt,
  } = constants;

  return (
    <div className={`p-field p-col-${size}`}>
      <label id={`label-${id}`} className='big-bold-label' htmlFor={id}>
        {label}
      </label>
      {tt != undefined && <TooltipAddon tt={tt} element={`#label-${id}`} />}
      <InputNumber
        name={id}
        id={id}
        value={value}
        onValueChange={handler}
        suffix={suffix}
        min={min}
        max={max}
        minFractionDigits={minFractionDigits}
        mode='decimal'
        placeholder={placeholder}
        disabled={isDisabled !== undefined && isDisabled === true}
      />
    </div>
  );
};

export default DecimalInput;
