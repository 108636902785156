import React from "react";
import { InputNumber } from "primereact/inputnumber";
import TooltipAddon from "./tooltip-addon";

const NumberInputOffset = ({ constants, value, handler, isDisabled }) => {
  const { id, label, size, offset, min, max, tt } = constants;
  return (
    <div className={`p-field p-col-${size} p-offset-${offset}`}>
      <label id={`label-${id}`} className='big-bold-label' htmlFor={id}>
        {label}
      </label>
      {tt != undefined && <TooltipAddon tt={tt} element={`#label-${id}`} />}
      <InputNumber
        name={id}
        id={id}
        min={min}
        max={max}
        value={value}
        onValueChange={handler}
        useGrouping={false}
        disabled={isDisabled}
      />
    </div>
  );
};

export default NumberInputOffset;
