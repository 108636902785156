import { UserActionTypes } from "./user.types";

const INITIAL_STATE = {
  isLoggedIn: false,
  email: "",
  role: "",
  notConfirmed: false,
  incorrectLogin: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        notConfirmed: false,
        incorrectLogin: false,
      };
    case UserActionTypes.USER_LOADED:
      return {
        ...state,
        isLoggedIn: true,
        email: action.payload.email,
        role: action.payload.role,
        notConfirmed: false,
        incorrectLogin: false,
      };
    case UserActionTypes.LOGIN_NOT_CONFIRMED:
      return {
        ...state,
        notConfirmed: true,
      };
    case UserActionTypes.LOGIN_FAIL:
    case UserActionTypes.AUTH_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        email: "",
        role: "",
        incorrectLogin: true,
        notConfirmed: false,
      };
    case UserActionTypes.LOGOUT_FAIL:
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        email: "",
        role: "",
        notConfirmed: false,
        incorrectLogin: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default userReducer;
