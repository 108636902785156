import React, { Fragment, useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import analyzerPieOptions from "./analyzer-pie-options";
import { formatPercentNoSign, delay } from "../../../../utilities/numbers";

const AnalyzerPie = ({ results, setPieUrl }) => {
  const [isReady, setIsReady] = useState(false);
  const [pieData, setPieData] = useState(null);

  useEffect(() => {
    initializePlot();
    // eslint-disable-next-line
  }, [results]);

  useEffect(() => {
    if (isReady === true) makeImage();
    // eslint-disable-next-line
  }, [isReady]);

  const makeImage = async () => {
    await delay(1000);
    let canvas2 = document.querySelector("#pie-chart>canvas");
    canvas2.toBlob(async (blob) => {
      const dataURI2 = canvas2.toDataURL();
      setPieUrl(dataURI2);
    });
  };

  const initializePlot = () => {
    if (results === null || results === undefined) return;
    const { passing, failing } = results;
    let pass = passing.count;
    let fail = failing.count;
    let passRate = Number(formatPercentNoSign(pass / (pass + fail)));
    let failRate = Number(formatPercentNoSign(fail / (pass + fail)));

    let pData = {
      labels: ["Run Results"],
      datasets: [
        {
          label: "Pass",
          data: [passRate],
          backgroundColor: "#228B22",
          hoverBackgroundColor: "#22BB22",
        },
        {
          label: "Fail",
          data: [failRate],
          backgroundColor: "#8b2200",
          hoverBackgroundColor: "#bb2200",
        },
      ],
    };
    setPieData(pData);
    setIsReady(true);
  };

  return (
    <Fragment>
      <div className='p-grid p-text-center'>
        <div className='p-col-6 p-offset-3'>
          <h1>Analyzer Pass/Fail Results</h1>
          {isReady === true && (
            <Chart
              id='pie-chart'
              // type='pie'
              type='bar'
              data={pieData}
              options={analyzerPieOptions}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AnalyzerPie;
