// export const life = [
//   {
//     label: "American",
//     value: "AG",
//   },
// ];
export const lifeCompanies = [
  {
    id: 1,
    name: "American General",
    label: "American General",
    value: "AG",
    jurisdiction: "Texas",
    rating: "A",
    benefitTypeOptions: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    modePeriodOptions: [false, true, true, true, true, true],
  },
  {
    id: 2,
    name: "Berkshire Hathaway",
    label: "Berkshire Hathaway",
    value: "BHG",
    jurisdiction: "Nebraska",
    rating: "A++",
    benefitTypeOptions: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    modePeriodOptions: [false, true, true, true, true, true],
  },
  {
    id: 3,
    name: "Independent Life",
    label: "Independent Life",
    value: "IL",
    jurisdiction: "Florida",
    rating: "Not Rated",
    benefitTypeOptions: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    modePeriodOptions: [false, true, true, true, true, true],
  },
  {
    id: 4,
    name: "Metropolitan Life",
    label: "Metropolitan Life",
    value: "MET",
    jurisdiction: "Delaware",
    rating: "A+",
    benefitTypeOptions: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    modePeriodOptions: [false, true, true, true, true, true],
  },
  {
    id: 5,
    name: "Mutual of Omaha",
    label: "Mutual of Omaha",
    value: "MOO",
    jurisdiction: "Connecticut",
    rating: "A+",
    benefitTypeOptions: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    modePeriodOptions: [false, false, true, true, true, true],
  },
  {
    id: 6,
    name: "New York Life",
    label: "New York Life",
    value: "NYL",
    jurisdiction: "New York",
    rating: "A++",
    benefitTypeOptions: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    modePeriodOptions: [false, false, true, true, true, true],
  },

  {
    id: 7,
    name: "Pacific Life",
    label: "Pacific Life",
    value: "PAC",
    jurisdiction: "Colorado",
    rating: "A+",
    benefitTypeOptions: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    modePeriodOptions: [false, false, true, true, true, true],
  },
  {
    id: 8,
    name: "Prudential Life",
    label: "Prudential Life",
    value: "PRU",
    jurisdiction: "New Jersey",
    rating: "A+",
    benefitTypeOptions: [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
    modePeriodOptions: [false, false, true, true, true, true],
  },
  {
    id: 9,
    name: "USAA Life",
    label: "USAA Life",
    value: "USAA",
    jurisdiction: "Texas",
    rating: "A++",
    benefitTypeOptions: [
      true,
      true,
      true,
      true,
      true,
      false,
      true,
      true,
      false,
      true,
    ],
    modePeriodOptions: [false, false, true, true, true, true],
  },
];

export const benefitTypeOptions = [
  {
    id: 1,
    value: "LO",
    name: "Life Only",
    fields: { years: false, months: false, endDate: false, cola: true },
    designer:
      "Life Only Annuity - XXXbenefitAmountXXX for life, payable XXXmodePeriodXXX, beginning on XXXstartDateXXXYYYcolaPercentYYY, increasing at a rate of XXXcolaPercentXXX compounded annuallyYYY.",
  },
  {
    id: 2,
    value: "LWC",
    name: "Life with Certain",
    fields: { years: true, months: true, endDate: true, cola: true },
    designer:
      "Life with Certain Period Annuity - XXXbenefitAmountXXX for life, payable XXXmodePeriodXXX, guaranteed for XXXtermYearsMonthsXXX, beginning on XXXstartDateXXXYYYcolaPercentYYY, increasing at a rate of XXXcolaPercentXXX compounded annuallyYYY, with the last guaranteed payment on XXXendDateXXX.",
  },
  {
    id: 3,
    value: "PC",
    name: "Period Certain",
    fields: { years: true, months: true, endDate: true, cola: true },
    designer:
      "Period Certain Annuity - XXXbenefitAmountXXX payable XXXmodePeriodXXX, guaranteed for XXXtermYearsMonthsXXX, beginning on XXXstartDateXXXYYYcolaPercentYYY, increasing at a rate of XXXcolaPercentXXX compounded annuallyYYY, with the last guaranteed payment on XXXendDateXXX.",
  },
  {
    id: 4,
    value: "GLS",
    name: "Guaranteed Lump Sum",
    fields: { years: false, months: false, endDate: false, cola: false },
    designer:
      "Guaranteed Lump Sum - XXXbenefitAmountXXX paid on XXXstartDateXXX",
  },
  {
    id: 5,
    value: "IR",
    name: "Installment Refund",
    fields: { years: true, months: true, endDate: true, cola: true },
    designer:
      "Installment Refund Annuity - XXXbenefitAmountXXX for life, payable XXXmodePeriodXXX, guaranteed for XXXtermYearsMonthsXXX, beginning on XXXstartDateXXXYYYcolaPercentYYY, increasing at a rate of XXXcolaPercentXXX compounded annuallyYYY, with the last guaranteed payment on XXXendDateXXX. Total payments will be at least equal to the single premium.",
  },
  {
    id: 6,
    value: "TL",
    name: "Temporary Life",
    fields: { years: true, months: true, endDate: true, cola: true },
    designer:
      "Temporary Life Annuity - XXXbenefitAmountXXX paid if living, payable XXXmodePeriodXXX, beginning on XXXstartDateXXX for a maximum of XXXtermYearsMonthsXXXYYYcolaPercentYYY, increasing at a rate of XXXcolaPercentXXX compounded annuallyYYY.",
  },
  {
    id: 7,
    value: "JS",
    name: "Joint & Survivor",
    fields: { years: true, months: true, endDate: true, cola: true },
    designer:
      "Joint Life with Certain Annuity - XXXbenefitAmountXXX for life, payable XXXmodePeriodXXX, guaranteed for XXXtermYearsMonthsXXX, beginning on XXXstartDateXXX. Payable jointly to Mr and Mrs (add names and dob)YYYcolaPercentYYY, increasing at a rate of XXXcolaPercentXXX compounded annuallyYYY, with the last guaranteed payment on XXXendDateXXX. Payments continue at 100% to the survivor.",
  },
  {
    id: 8,
    value: "UC",
    name: "Upfront Cash",
    fields: { years: false, months: false, endDate: false, cola: false },
    designer: "Cash Up Front - XXXbenefitAmountXXX",
  },
  {
    id: 9,
    value: "ELS",
    name: "Endowment Lump Sum",
    fields: { years: false, months: false, endDate: false, cola: false },
    designer:
      "Guaranteed Lump Sum - XXXbenefitAmountXXX paid, if living, on XXXstartDateXXX",
  },
  {
    id: 10,
    value: "CR",
    name: "Cash Refund",
    fields: { years: false, months: false, endDate: false, cola: true },
    designer:
      "Cash Refund Annuity - XXXbenefitAmountXXX for life, payable XXXmodePeriodXXX, beginning on XXXstartDateXXXYYYcolaPercentYYY, increasing at a rate of XXXcolaPercentXXX compounded annuallyYYY. If the payee dies prior to receiving total payments equal to the single premium, a guaranteed single payment of the difference will be paid to the beneficiary.",
  },
];
export const modePeriodOptions = [
  { id: 1, value: "O", name: "O", period: "One Time Only" },
  { id: 2, value: "W", name: "W", period: "weekly" },
  { id: 3, value: "M", name: "M", period: "monthly" },
  { id: 4, value: "Q", name: "Q", period: "quarterly" },
  { id: 5, value: "S", name: "S", period: "semi-annually" },
  { id: 6, value: "A", name: "A", period: "annually" },
];
