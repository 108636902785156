import React, { Fragment } from "react";
import AnalyzerSummaryField from "./analyzer-summary-field.component";
import { formatCurrency, formatPercent } from "../../../../utilities/numbers";

const AnalyzerSummaryPassing = ({ passing }) => {
  return (
    <Fragment>
      <div className='p-shadow-12 p-mb-4 br-9'>
        <div className='card analyzer-passing  pad-top-half'>
          <p className='font-subtitle my-p5'>Passing Runs</p>
          <div className='k-flex-between px-2'>
            <AnalyzerSummaryField
              size={3}
              label='Count'
              value={passing.count}
            />
            <AnalyzerSummaryField
              size={3}
              label='Percent'
              value={formatPercent(passing.percent)}
            />
            <AnalyzerSummaryField
              size={6}
              label='Total Taxes'
              value={formatCurrency(passing.totalTaxes)}
            />
            <AnalyzerSummaryField
              size={12}
              label='Avg Final Balance'
              value={formatCurrency(passing.averageBalance)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AnalyzerSummaryPassing;
