export const AnalyzerActionTypes = {
  SAVE_RUN_SUCCESS: "SAVE_RUN_SUCCESS",
  DELETE_RUN_SUCCESS: "DELETE_RUN_SUCCUSS",
  SET_BROKER_PASS: "SET_BROKER_PASS",
  SET_CLAIMANT_PASS: "SET_CLAIMANT_PASS",
  UNLOAD_ANALYZER: "UNLOAD_ANALYZER",
  // SAVE_FILING_SUCCESS: "SAVE_FILING_SUCCESS",
  // SAVE_SINGLE_SUCCESS: "SAVE_SINGLE_SUCCESS",
  // SAVE_MARRIED_SUCCESS: "SAVE_MARRIED_SUCCESS",
  // SAVE_TRUST_SUCCESS: "SAVE_TRUST_SUCCESS",
  // SAVE_RUN_FAIL: "SAVE_RUN_FAIL",
  // SAVE_ANNUITY_FAIL: "SAVE_ANNUITY_FAIL",
  // SAVE_ANNUITY_SUCCESS: "SAVE_ANNUITY_SUCCESS",
  // DELETE_RUN_FAIL: "DELETE_RUN_FAIL",
};
