import React from "react";
import { Image } from "primereact/image";
import BrokerAndClaimant from "./broker-and-claimant/broker-and-claimant";
import CalculatorAndGain from "./calculator-and-gain/calculator-and-gain";
import DrawsAndIncome from "./draws-and-income/draws-and-income";
import CalculateButton from "./calculate-button/calculate-button";
import LOGO from "../../../assets/images/thumb_SA.png";

const SetupView = ({
  allInputs,
  inputArrays,
  handlers,
  handleReset,
  isValid,
  isRateDisabled,
  isPercentDisabled,
  isBlendedOn,
}) => {
  const { inputs, inputDraw, inputIncome, inputBlended } = allInputs;
  const {
    handleInput,
    handleTax,
    handleGain,
    handleInputDraw,
    handleInputIncome,
    handleInputBlended,
    handleAddDraw,
    handleAddIncome,
    handleAddBlended,
    handleDeleteDraw,
    handleDeleteIncome,
    handleDeleteBlended,
    handleCalculate,
  } = handlers;
  return (
    <div className='settlement-analyzer-setup p-shadow-12'>
      <div className='p-grid'>
        <div className='p-col-3'>
          <Image src={LOGO} width='75%' className='opacity-50' />
        </div>
        <div className='p-col-6'>
          <div className='p-shadow-12 br-9'>
            <BrokerAndClaimant />
          </div>
        </div>
        <div className='p-col-3' />
        <div className='p-col-6'>
          <CalculatorAndGain
            inputs={inputs}
            handlers={{ handleInput, handleTax, handleGain }}
            handleReset={handleReset}
            isRateDisabled={isRateDisabled}
            isPercentDisabled={isPercentDisabled}
            isBlendedOn={isBlendedOn}
          />
        </div>
        <div className='p-col-6'>
          <DrawsAndIncome
            inputs={{ inputDraw, inputIncome, inputBlended }}
            inputArrays={inputArrays}
            handlers={{
              handleInputDraw,
              handleInputIncome,
              handleInputBlended,
              handleAddDraw,
              handleAddIncome,
              handleAddBlended,
              handleDeleteDraw,
              handleDeleteIncome,
              handleDeleteBlended,
            }}
          />
        </div>
        <div className='p-offset-4 p-shadow-12 br-9 p-col-4 p-mt-4 p-mb-2'>
          <CalculateButton
            isValid={isValid}
            handleCalculate={handleCalculate}
          />
        </div>
      </div>
    </div>
  );
};

export default SetupView;
