import {
  benefitTypeOptions,
  lifeCompanies,
  modePeriodOptions,
} from "../../assets/data/products.data";

import {
  enableInputs,
  changeInputValue,
  benefitTypeAdjustments,
  lifeCompanyAdjustments,
  modePeriodAdjustments,
  startDateAdjustments,
  termTimeAdjustments,
  guaranteedAdjustments,
  premiumAdjustments,
  benefitAdjustments,
} from "./add-benefit.helpers.utils";

import Dates from "../../utilities/dates";

export const prepareLifeCompanyOptions = (field, quote) => {
  if (quote.proposal === null) return [];
  const lc = quote.proposal.lifeCompany.map((abbrev) =>
    lifeCompanies.find((obj) => obj.value === abbrev)
  );

  field["lifeCompanyOptions"] = lc;
  const res = enableInputs(field, ["lifeCompany", "benefitTitle"]);
  return res;
};

export const changeBenefitInput = (inputs, param) => {
  if (param.name === "lifeCompany")
    return lifeCompanyAdjustments(inputs, param);
  if (param.name === "benefitType")
    return benefitTypeAdjustments(inputs, param);
  if (param.name === "modePeriod") return modePeriodAdjustments(inputs, param);
  if (param.name === "startDate") return startDateAdjustments(inputs, param);
  // if (param.id === "end-date") return endDateAdjustments(inputs, param);
  
  if (param.name === "termYears" && param.value === null) param.value = 0;
  if (param.name === "termMonths" && param.value === null) param.value = 0;
  if (param.name === "colaPercent" && param.value === null) param.value = 0;
  if (param.name === "benefitAmount" && param.value === null) param.value = 0;
  if (param.name === "annuityPremium" && param.value === null) param.value = 0;
  if (param.name === "guaranteedBenefit" && param.value === null)
    param.value = 0;
  if (param.name === "expectedBenefit" && param.value === null) param.value = 0;
  if (param.name === "termYears" || param.name === "termMonths")
    return termTimeAdjustments(inputs, param);
  if (param.name === "guaranteedBenefit")
    return guaranteedAdjustments(inputs, param);
  if (param.name === "annuityPremium") return premiumAdjustments(inputs, param);
  if (param.name === "benefitAmount") return benefitAdjustments(inputs, param);     
  return changeInputValue(inputs, param);
};

export const clearBenefits = (lifeOptions) => {
  return {
    lifeCompanyOptions: lifeOptions,
    benefitTypeOptions: [],
    modePeriodOptions: [],
    lifeCompany: {
      value: "",
      showError: false,
      disabled: false,
    },
    benefitType: {
      value: "",
      showError: false,
      disabled: true,
    },
    modePeriod: { value: "", showError: false, disabled: true },
    termYears: {
      value: 0,
      showError: false,
      disabled: true,
    },
    termMonths: {
      value: 0,
      showError: false,
      disabled: true,
    },
    endDate: {
      value: Dates.today(),
      showError: false,
      disabled: true,
    },
    colaPercent: {
      value: 0,
      showError: false,
      disabled: true,
    },
    benefitAmount: {
      value: 0,
      showError: false,
      disabled: true,
    },
    startDate: {
      value: Dates.today(),
      showError: false,
      disabled: true,
    },
    annuityPremium: {
      value: 0,
      showError: false,
      disabled: true,
    },
    guaranteedBenefit: {
      value: 0,
      showError: false,
      disabled: true,
    },
    expectedBenefit: {
      value: 0,
      showError: false,
      disabled: true,
    },
    benefitTitle: {
      value: "",
      showError: false,
      disabled: false,
    },
  };
};

export const prepareDataForRedux = async ({ fieldInputs, _id, proposal }) => {
  let output = { proposal: proposal };
  if (_id !== undefined) output = { ...output, _id: _id };
  output["lifeCompany"] = fieldInputs.lifeCompany.value;
  output["benefitType"] = fieldInputs.benefitType.value;
  output["benefitAmount"] = fieldInputs.benefitAmount.value;
  output["modePeriod"] = fieldInputs.modePeriod.value;
  output["startDate"] = fieldInputs.startDate.value;
  output["endDate"] = fieldInputs.endDate.value;
  output["termYears"] = fieldInputs.termYears.value;
  output["termMonths"] = fieldInputs.termMonths.value;
  output["colaPercent"] = fieldInputs.colaPercent.value;
  output["annuityPremium"] = fieldInputs.annuityPremium.value;
  output["guaranteedBenefit"] = fieldInputs.guaranteedBenefit.value;
  output["expectedBenefit"] = fieldInputs.expectedBenefit.value;
  output["benefitTitle"] = fieldInputs.benefitTitle.value;
  return output;
};

export const validateInputs = async (field) => {
  let res = { ...field };
  let isValid = true;
  if (!["O", "W", "M", "Q", "S", "A"].includes(res.modePeriod.value)) {
    res.modePeriod.showError = true;
    isValid = false;
  } else res.modePeriod.showError = false;
  if (res.lifeCompany.value === "") {
    res.lifeCompany.showError = true;
    isValid = false;
  } else res.lifeCompany.showError = false;
  if (res.benefitType.value === "") {
    res.benefitType.showError = true;
    isValid = false;
  } else res.benefitType.showError = false;
  if (
    res.startDate.showError ||
    res.endDate.showError ||
    res.termMonths.showError
  )
    isValid = false;
  return { isValid: isValid, result: res };
};


export const loadInputsToEdit = ({quote,benefit}) => {
  const lco = quote.lifeCompany.map((abbrev) =>
    lifeCompanies.find((obj) => obj.value === abbrev)
  );
  let bto=[]
  let mpo=[]
  let bt=lifeCompanies.find(obj =>obj.value===benefit.lifeCompany)
  for (const [index, opt] of bt.benefitTypeOptions.entries()) {
    if (opt) bto.push(benefitTypeOptions[index])
  }
  for (const [index, opt] of bt.modePeriodOptions.entries()) {if (opt) mpo.push(modePeriodOptions[index])}
  
  let dis=false
  if (benefit.modePeriod==='O') {
    dis=true
    mpo=[{ id: 1, value: "O", name: "O", period: "One Time Only" }]
  }
  let field=benefitTypeOptions.find(ben=>ben.value===benefit.benefitType).fields

  let res = {
    lifeCompanyOptions:  lco,
    benefitTypeOptions:  bto,
    modePeriodOptions:  mpo,
    lifeCompany: {
      value: benefit.lifeCompany,
      showError: false,
      disabled: false,
    },
    benefitType: {
      value: benefit.benefitType,
      showError: false,
      disabled: false,
    },
    modePeriod: { value: benefit.modePeriod, showError: false, disabled: dis  },
    termYears: {
      value: benefit.termYears,
      showError: false,
      disabled: !field.years,
    },
    termMonths: {
      value: benefit.termMonths,
      showError: false,
      disabled: !field.months,
    },
    endDate: {
      //value: getFormattedDateFromString(benefit.endDate),
      value: benefit.endDate,
      showError: false,
      disabled: true,
    },
    colaPercent: {
      value: benefit.colaPercent,
      showError: false,
      disabled: !field.cola,
    },
    benefitAmount: {
      value: benefit.benefitAmount,
      showError: false,
      disabled: false,
    },
    startDate: {
      // value: getFormattedDateFromString(benefit.startDate),
      value: benefit.startDate,
      showError: false,
      disabled: false,
    },
    annuityPremium: {
      value: benefit.annuityPremium,
      showError: false,
      disabled: false,
    },
    guaranteedBenefit: {
      value: benefit.guaranteedBenefit,
      showError: false,
      disabled: false,
    },
    expectedBenefit: {
      value: benefit.expectedBenefit,
      showError: false,
      disabled: false,
    },
    benefitTitle: {
      value: benefit.benefitTitle,
      showError: false,
      disabled: false,
    },
  };
  if (res.benefitType.value==='LO' || res.benefitType.value==='IR' || res.benefitType.value==='TL') res.guaranteedBenefit.disabled=true
  if (res.benefitType.value==='PC') res.expectedBenefit.disabled=true
  if (
    res.benefitType.value === "GLS" ||
    res.benefitType.value === "ELS" ||
    res.benefitType.value === "UC"
  ) {
    res.guaranteedBenefit.disabled = true;
    res.expectedBenefit.disabled = true;
  }
  if (res.benefitType.value==='UC') res.annuityPremium.disabled=true
  return res;
  
};