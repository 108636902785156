import React, { Fragment, useState, useEffect } from "react";
import { Page, View, Image } from "@react-pdf/renderer";
import styles from "../pdf-analyzer.styles";
import COVER from "../../../../../assets/images/black-line-background.jpg";
import Macros from "../texts/macros";
import { pdfDisplayedFilingStatusWithMarried } from "../../../../../utilities/misc";
import {
  formatCurrency,
  formatPercentInteger,
} from "../../../../../utilities/numbers";
import OpeningParagraph from "../texts/opening-paragraph";
import AnnualGrowthParagraph from "../texts/annual-growth-paragraph";
import IsIncomeParagraph from "../texts/is-income-paragraph";
import IsBlendedParagraph from "../texts/is-blended-paragraph";
import IsDrawsParagraph from "../texts/is-draws-paragraph";
import ContinuingParagraph from "../texts/continuing-paragraph";
import TaxesAndFeesParagraph from "../texts/taxes-and-fees-paragraph";
import IsTaxableIsBlendedParagraph from "../texts/is-taxable-is-blended-paragraph";
import IsTaxableNotBlendedParagraph from "../texts/is-taxable-not-blended-paragraph";
import NotTaxableIsBlendedParagraph from "../texts/not-taxable-is-blended-paragraph";
import NotTaxableNotBlendedParagraph from "../texts/not-taxable-not-blended-paragraph";

const Page2 = ({ claimant, summary, broker }) => {
  const [isMoreThanOne, setIsMoreThanOne] = useState(false);

  useEffect(() => {
    if (
      summary.conditions.allIncomes.length +
        summary.conditions.allDraws.length >
      1
    )
      setIsMoreThanOne(true);
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <Page size='letter' object-fit='fill'>
        <View wrap={true}>
          <Image src={COVER} style={[styles.pageImage2]} />
        </View>
        <View style={styles.pageMargins}>
          <Macros.FirstName name={claimant.firstName} />
          <Macros.SkipLine />

          <OpeningParagraph isMoreThanOne={isMoreThanOne} />
          <Macros.SkipLine />

          <AnnualGrowthParagraph
            percentBonds={summary.conditions.percentBonds}
            bondRate={summary.conditions.bondRate}
            gainCalculationType={summary.conditions.gainCalculationType}
          />
          <Macros.SkipLine />
          {/* (IF Taxable is Chosen) should this be additional incomes? */}
          {summary.conditions.allIncomes.length > 0 && (
            <>
              <IsIncomeParagraph allIncomes={summary.conditions.allIncomes} />
              <Macros.SkipLine />
            </>
          )}
          {/* (IF Blended Income Exists) */}
          {summary.conditions.allBlendeds.length > 0 && (
            <>
              <IsBlendedParagraph
                allBlendeds={summary.conditions.allBlendeds}
              />
              <Macros.SkipLine />
            </>
          )}
          {/* (Should this part be only for draws */}
          {summary.conditions.allDraws.length > 0 && (
            <>
              <IsDrawsParagraph allDraws={summary.conditions.allDraws} />
              <Macros.SkipLine />
            </>
          )}

          {/* This is the paragraph that starts with Continuing */}
          <ContinuingParagraph
            startYear={summary.conditions.startYear}
            endYear={summary.conditions.endYear}
          />
          <Macros.SkipLine />
          {/* Taxes and Fees Paragraph */}
          <TaxesAndFeesParagraph
            filingState={summary.conditions.filingState}
            filingStatus={pdfDisplayedFilingStatusWithMarried(
              summary.conditions.filingStatus,
              summary.conditions.yearMarried
            )}
            fees={summary.conditions.fees}
          />
          <Macros.SkipLine />

          {/* (Not Taxable Not Blended ) */}
          {!summary.conditions.isTaxable &&
            summary.conditions.allBlendeds.length < 1 && (
              <NotTaxableNotBlendedParagraph
                startBalance={formatCurrency(summary.conditions.startBalance)}
                runs={summary.conditions.runs}
                pass={formatPercentInteger(summary.passing.percent)}
                fail={formatPercentInteger(summary.failing.percent)}
                isMoreThanOne={isMoreThanOne}
              />
            )}

          {/* (Not Taxable Is Blended ) */}
          {!summary.conditions.isTaxable &&
            summary.conditions.allBlendeds.length >= 1 && (
              <NotTaxableIsBlendedParagraph
                startBalance={formatCurrency(summary.conditions.startBalance)}
                runs={summary.conditions.runs}
                pass={formatPercentInteger(summary.passing.percent)}
                fail={formatPercentInteger(summary.failing.percent)}
                isMoreThanOne={isMoreThanOne}
                allBlendeds={summary.conditions.allBlendeds}
              />
            )}

          {/* (Is Taxable Not Blended ) */}
          {summary.conditions.isTaxable &&
            summary.conditions.allBlendeds.length < 1 && (
              <IsTaxableNotBlendedParagraph
                startBalance={formatCurrency(summary.conditions.startBalance)}
                runs={summary.conditions.runs}
                pass={formatPercentInteger(summary.passing.percent)}
                fail={formatPercentInteger(summary.failing.percent)}
                isMoreThanOne={isMoreThanOne}
              />
            )}

          {/* (Is Taxable Is Blended ) */}
          {summary.conditions.isTaxable &&
            summary.conditions.allBlendeds.length >= 1 && (
              <IsTaxableIsBlendedParagraph
                startBalance={formatCurrency(summary.conditions.startBalance)}
                runs={summary.conditions.runs}
                pass={formatPercentInteger(summary.passing.percent)}
                fail={formatPercentInteger(summary.failing.percent)}
                isMoreThanOne={isMoreThanOne}
                allBlendeds={summary.conditions.allBlendeds}
              />
            )}

          <Macros.SkipLine />
          <Macros.ThankYou />
          <Macros.SkipLine />
          <Macros.BrokerSign name={broker.brokerName} />
        </View>
      </Page>
    </Fragment>
  );
};

export default Page2;
