import React from "react";
import { Svg, Defs, LinearGradient, Stop, Rect } from "@react-pdf/renderer";

const CustomizedHeader = ({ colors, addOn }) => {
  let startColor, endColor, x2, y2;
  switch (colors.format) {
    case "solid":
      startColor = colors.color1;
      endColor = colors.color1;
      x2 = 1;
      y2 = 1;
      break;
    case "horizontal":
      startColor = colors.color0;
      endColor = colors.color2;
      x2 = 1;
      y2 = 0;
      break;
    case "vertical":
      startColor = colors.color0;
      endColor = colors.color2;
      x2 = 0;
      y2 = 1;
      break;
    case "diagonal":
      startColor = colors.color0;
      endColor = colors.color2;
      x2 = 1;
      y2 = 1;
      break;
    case "default":
      startColor = colors.color1;
      endColor = colors.color1;
      x2 = 1;
      y2 = 1;
      break;
  }

  return (
    <Svg width='588' height='588'>
      <Defs>
        <LinearGradient
          id={`headerLinearGradient-${addOn}`}
          x1='0'
          x2={`${x2}`}
          y1='0'
          y2={`${y2}`}
        >
          <Stop offset='0%' stopColor={`#${startColor}`} />
          <Stop offset='100%' stopColor={`#${endColor}`} />
        </LinearGradient>
      </Defs>
      <Rect
        x='0'
        y='0'
        width='588'
        height='100'
        fill={`url('#headerLinearGradient-${addOn}')`}
      />
    </Svg>
  );
};

export default CustomizedHeader;
