import React from 'react';
import { Text, View } from "@react-pdf/renderer";
import styles from "../quote-page.styles";

const P2_Header = ({ loc, claimant }) => {
  const ShowAge = () => {
    let returnString = "";
    const dob = claimant.birthDate;
    returnString = claimant.ageSelector
      ? `Age: ${claimant.age}`
      : `DOB: ${dob}`;
    if (claimant.ratedAge > claimant.age)
      returnString += ` - Rated Age: ${claimant.ratedAge}`;
    return returnString;
  };

  return (
    <View style={loc}>
      <Text style={[styles.text14, styles.center, styles.underline]}>
        Structured Settlement Proposal
      </Text>
      <Text style={[styles.text14, styles.center]}>for</Text>
      <Text style={[styles.text16, styles.center, styles.bold]}>
        {claimant.caseNumber === undefined || claimant.caseNumber === ""
          ? claimant.name
          : `${claimant.name} - ${claimant.caseNumber}`}
      </Text>
      <Text style={[styles.text16, styles.center, styles.bold]}>
        <ShowAge />
      </Text>
    </View>
  );
};

export default P2_Header;
