///////////////////////////////////////////////////////
// 2022 tax rates
////////////////////////////////////////////////////////

export const federalSingle = [
  { bracket: 0, rate: 0.1, accumulated: 0 },
  { bracket: 10275, rate: 0.12, accumulated: 1027.5 },
  { bracket: 41775, rate: 0.22, accumulated: 4807.5 },
  { bracket: 89075, rate: 0.24, accumulated: 15213.5 },
  { bracket: 170050, rate: 0.32, accumulated: 34647.5 },
  { bracket: 215950, rate: 0.35, accumulated: 49335.5 },
  { bracket: 539900, rate: 0.37, accumulated: 162718.0 },
];

export const federalMarried = [
  { bracket: 0, rate: 0.1, accumulated: 0 },
  { bracket: 20550, rate: 0.12, accumulated: 2055 },
  { bracket: 83550, rate: 0.22, accumulated: 9615 },
  { bracket: 178150, rate: 0.24, accumulated: 30427 },
  { bracket: 340100, rate: 0.32, accumulated: 69295 },
  { bracket: 431900, rate: 0.35, accumulated: 98671 },
  { bracket: 647850, rate: 0.37, accumulated: 174253.5 },
];

////////////////////////////////////////////////////////////////
// 2021 Tax rates
////////////////////////////////////////////////////////////////

export const federalSingle_2021 = [
  { bracket: 0, rate: 0.1, accumulated: 0 },
  { bracket: 9950, rate: 0.12, accumulated: 995 },
  { bracket: 40526, rate: 0.22, accumulated: 4664.12 },
  { bracket: 86376, rate: 0.24, accumulated: 14751.12 },
  { bracket: 164926, rate: 0.32, accumulated: 33603.12 },
  { bracket: 209426, rate: 0.35, accumulated: 47843.12 },
  { bracket: 523601, rate: 0.37, accumulated: 157804.4 },
];

export const federalMarried_2021 = [
  { bracket: 0, rate: 0.1, accumulated: 0 },
  { bracket: 19900, rate: 0.12, accumulated: 1990 },
  { bracket: 81050, rate: 0.22, accumulated: 9328 },
  { bracket: 172750, rate: 0.24, accumulated: 29502 },
  { bracket: 329850, rate: 0.32, accumulated: 67206 },
  { bracket: 418850, rate: 0.35, accumulated: 95686 },
  { bracket: 628300, rate: 0.37, accumulated: 168993.5 },
];

//////////////////////////////////////////////////////////
// 2020 Tax rates
//////////////////////////////////////////////////////////

export const federalSingle_2020 = [
  { bracket: 0, rate: 0.1, accumulated: 0 },
  { bracket: 9875, rate: 0.12, accumulated: 987.5 },
  { bracket: 40125, rate: 0.22, accumulated: 4617.5 },
  { bracket: 85525, rate: 0.24, accumulated: 14605.5 },
  { bracket: 163300, rate: 0.32, accumulated: 33271.5 },
  { bracket: 207350, rate: 0.35, accumulated: 47367.5 },
  { bracket: 518400, rate: 0.37, accumulated: 156235.0 },
];

export const federalMarried_2020 = [
  { bracket: 0, rate: 0.1, accumulated: 0 },
  { bracket: 19750, rate: 0.12, accumulated: 1975 },
  { bracket: 80250, rate: 0.22, accumulated: 9235 },
  { bracket: 171050, rate: 0.24, accumulated: 29211 },
  { bracket: 326600, rate: 0.32, accumulated: 66543 },
  { bracket: 414700, rate: 0.35, accumulated: 94735 },
  { bracket: 622050, rate: 0.37, accumulated: 167307.5 },
];

///////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////

export const taxTrust = [
  { bracket: 0, rate: 0.1, accumulated: 0 },
  { bracket: 2650, rate: 0.24, accumulated: 265 },
  { bracket: 9950, rate: 0.35, accumulated: 2017 },
  { bracket: 13050, rate: 0.37, accumulated: 3102 },
];

export const ltcgSingle = [
  { bracket: 0, rate: 0.0, accumulated: 0 },
  { bracket: 41676, rate: 0.15, accumulated: 0 },
  { bracket: 459750, rate: 0.2, accumulated: 62711.11 },
];

export const ltcgMarried = [
  { bracket: 0, rate: 0.0, accumulated: 0 },
  { bracket: 83351, rate: 0.15, accumulated: 0 },
  { bracket: 517201, rate: 0.2, accumulated: 65077.5 },
];

export const stateTaxPA = 0.0307;
