import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem;
`;
export const FixedContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: -10;
  width: 100vw;
`;
export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem;
`;

export const Message = styled.p`
  font-size: 1.25rem;
  text-align: center;
  font-weight: bold;
`;
