import React from "react";
import { Button } from "primereact/button";

const ButtonFields = ({
  constants: { label, icon, className },
  disabled,
  handler,
}) => {
  return (
    <Button
      label={label}
      icon={icon}
      className={className}
      disabled={disabled}
      onClick={handler}
    />
  );
};

export default ButtonFields;
