import React, { Fragment } from "react";
import AddClaimant from "./add-claimant.component";
import ViewClaimants from "./view-claimants.component";
import Unsubscribe from "../unsubscribe/unsubscribe.component";

const Claimants = () => {
  return (
    <Fragment>
      <div className='claimant-page-container p-shadow-12'>
        <AddClaimant />
        <div className='p-m-4' />
        <ViewClaimants />
      </div>
      <Unsubscribe />
    </Fragment>
  );
};

export default Claimants;
