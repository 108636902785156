import React, { Fragment,useRef,useState,useEffect } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";

import { connect } from "react-redux";
import { editBenefit, deleteBenefit,copyBenefit,updateBenefitOrder,disableBenefitsInPdf,enableBenefitsInPdf,updatePageBreak } from "../../redux/quote/quote.actions";
import {benefitTypeOptions} from '../../assets/data/products.data'
import { decimal2 } from "../../utilities/numbers";

const ViewBenefits = ({
  quote,
  editBenefit,
  deleteBenefit,
  copyBenefit,
  updateBenefitOrder,
  disableBenefitsInPdf,
  enableBenefitsInPdf,
  updatePageBreak,
}) => {
  const toast = useRef(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (quote.proposal !== null) benefitsChanged();
    else removeBenefitsView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  const removeBenefitsView = () => {
    setProducts([]);
  };

  const benefitsChanged = async () => {
    await setProducts(quote.proposal.benefits);
  };
  const handlePageBreak = async (e) => {
    let pb = e.target.value;
    await disableBenefitsInPdf();
    await updatePageBreak(quote.proposal._id, pb);
    await enableBenefitsInPdf();
  };

  const actionButtons = (rowData) => {
    const handleCopy = async (e) => {
      await disableBenefitsInPdf();
      await copyBenefit(quote.proposal._id, rowData._id);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: `${rowData.benefitTitle} was copied`,
        life: 3000,
      });
      await enableBenefitsInPdf();
    };
    const handleEdit = async (e) => {
      await disableBenefitsInPdf();
      await editBenefit(rowData);
      await enableBenefitsInPdf();
    };
    const handleDelete = async (e) => {
      await disableBenefitsInPdf();
      confirmDialog({
        message: "Do you want to delete this benefit?",
        header: "Confirm Delete",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        baseZIndex: 1000,
        accept: async () => {
          await deleteBenefit({
            proposal: quote.proposal._id,
            benefit: rowData._id,
          });
          toast.current.show({
            severity: "warn",
            summary: "Confirmed",
            detail: "The benefit was deleted",
            life: 3000,
          });
          //setPageBreak(0);
          await enableBenefitsInPdf();
        },
        reject: async () => await enableBenefitsInPdf(),
      });

      // await deleteBenefit(rowData.id);
      // await enableBenefitsInPdf();
    };

    return (
      <Fragment>
        <Button
          icon='pi pi-copy'
          className='p-button-raised p-button-primary p-mr-2'
          onClick={handleCopy}
          disabled={quote.isBenefitEdit}
          tooltip='Copy benefit'
          tooltipOptions={{ position: "top" }}
        />
        <Button
          icon='pi pi-pencil'
          className='p-button-raised p-button-warning p-mr-2'
          onClick={handleEdit}
          disabled={quote.isBenefitEdit}
          tooltip='Edit benefit'
          tooltipOptions={{ position: "top" }}
        />
        <Button
          icon='pi pi-trash'
          className='p-button-raised p-button-danger'
          onClick={handleDelete}
          disabled={quote.isBenefitEdit}
          tooltip='Delete benefit'
          tooltipOptions={{ position: "top" }}
        />
      </Fragment>
    );
  };
  // const percentBodyTemplate = (rowData, { field }) => {
  //   return (rowData[field] === undefined || rowData[field] === null) ? "" : `${rowData[field]}%`;
  // };

  const priceBodyTemplate = (rowData, { field }) => {
    return formatCurrency(rowData[field]);
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const onRowReorder = async (e) => {
    setProducts(e.value);
    let benefitIds = [];
    await disableBenefitsInPdf();
    for (let benefit of e.value) {
      benefitIds.push(benefit._id);
    }

    toast.current.show({
      severity: "success",
      summary: "Benefits Reordered",
      life: 3000,
    });
    await updateBenefitOrder(quote.proposal.id, benefitIds);
    await enableBenefitsInPdf();
  };
  const showYears = (rowData) => {
    if (rowData.modePeriod === "W") return `*${rowData.termYears}`;
    return benefitTypeOptions.find((ben) => ben.value === rowData.benefitType)
      .fields.years
      ? rowData.termYears
      : "";
  };
  const showMonths = (rowData) => {
    if (rowData.modePeriod === "W") return "Weeks";
    return benefitTypeOptions.find((ben) => ben.value === rowData.benefitType)
      .fields.months
      ? rowData.termMonths
      : "";
  };
  const showStartDate = (rowData) => {
    return rowData.startDate;
  };
  const showEndDate = (rowData) => {
    return benefitTypeOptions.find((ben) => ben.value === rowData.benefitType)
      .fields.endDate
      ? rowData.endDate
      : "";
  };
  // const showStartDate = (rowData) => {
  //   let viewDate = new Date(rowData.startDate);
  //   return viewDate.toLocaleDateString();
  // };
  // const showEndDate = (rowData) => {
  //   let viewDate = new Date(rowData.endDate);
  //   return benefitTypeOptions.find((ben) => ben.value === rowData.benefitType)
  //     .fields.endDate
  //     ? viewDate.toLocaleDateString()
  //     : "";
  // };
  const showCola = (rowData) => {
    let colaString = decimal2(rowData.colaPercent);
    return benefitTypeOptions.find((ben) => ben.value === rowData.benefitType)
      .fields.cola
      ? `${colaString}%`
      : "";
  };

  return (
    <Fragment>
      <Toast ref={toast}></Toast>
      <div className='p-shadow-12 p-mt-4'>
        <Card title='View Benefits'>
          <DataTable
            value={products}
            onRowReorder={onRowReorder}
            dataKey='id'
            //className='p-datatable-striped'
          >
            <Column rowReorder style={{ width: "3em" }} />
            <Column
              field='lifeCompany'
              header='Life Company'
              style={{ width: "8%" }}
            ></Column>
            <Column
              field='benefitType'
              header='Benefit Type'
              style={{ width: "8%" }}
            ></Column>
            <Column
              field='benefitAmount'
              header='Benefit Amount'
              body={priceBodyTemplate}
              style={{ width: "12%" }}
            ></Column>
            <Column
              field='modePeriod'
              header='Mode'
              style={{ width: "5%" }}
            ></Column>
            <Column
              body={showStartDate}
              header='Start Date'
              style={{ width: "10%" }}
            ></Column>
            <Column
              body={showYears}
              header='Years'
              style={{ width: "5%" }}
            ></Column>
            <Column
              body={showMonths}
              header='Months'
              style={{ width: "5%" }}
            ></Column>
            <Column
              body={showEndDate}
              header='End Date'
              style={{ width: "10%" }}
            ></Column>
            <Column
              header='COLA%'
              style={{ width: "5%" }}
              body={showCola}
            ></Column>
            <Column
              field='annuityPremium'
              header='Annuity Premium'
              style={{ width: "10%" }}
              body={priceBodyTemplate}
            ></Column>
            <Column
              field='guaranteedBenefit'
              header='Guaranteed Benefit'
              style={{ width: "10%" }}
              body={priceBodyTemplate}
            ></Column>
            <Column
              field='expectedBenefit'
              header='Expected Benefit'
              style={{ width: "10%" }}
              body={priceBodyTemplate}
            ></Column>
            <Column body={actionButtons} style={{ width: "15%" }} />
          </DataTable>
          {quote.isProposalLoaded && (
            <div className='p-grid p-fluid'>
              <div className='p-field p-col-1 p-md-1'>
                <label htmlFor='pageBreak'>Page Break</label>
                <InputNumber
                  id='pageBreak'
                  name='pageBreak'
                  value={quote.proposal.pageBreak}
                  onValueChange={handlePageBreak}
                  tooltip='A page break will be inserted after the benefit number is entered.'
                />
              </div>
              <div className='p-field p-col-5 p-md-5'>
                <h5>
                  {(quote.proposal.pageBreak > 0) &
                  (quote.proposal.pageBreak < products.length)
                    ? `A page break will occur after benefit position ${quote.proposal.pageBreak}`
                    : `There are no page breaks set`}{" "}
                </h5>
              </div>
            </div>
          )}
        </Card>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  quote: state.quote,
});
export default connect(mapStateToProps, {
  editBenefit,
  deleteBenefit,
  copyBenefit,
  updateBenefitOrder,
  disableBenefitsInPdf,
  enableBenefitsInPdf,
  updatePageBreak
})(ViewBenefits);
