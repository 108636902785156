import React, { Fragment } from "react";

import NotificationFieldText from "../input-fields/notification-field-text";
import InputFieldCheckbox from "../input-fields/input-field-checkbox";
import InputFieldText from "../input-fields/input-field-text";
import InputFieldTextarea from "../input-fields/input-field-textarea";
import InputFieldDropdown from "../input-fields/input-field-dropdown";
import InputFieldDropdown2 from "../input-fields/input-field-dropdown2";
import InputFieldDate from "../input-fields/input-field-date";
import InputFieldMultiselect from "../input-fields/input-field-multiselect";
import InputFieldPercentage from "../input-fields/input-field-percentage";
import ThreeStateButtons from "../button-fields/threeStateButtons.component";

import { lifeCompanies } from "../../assets/data/products.data";
import { detailInputs, detailAddButtons } from "../../assets/data/details.data";

const QuoteDetailsView = ({
  quote,
  broker,
  claimant,
  fieldInputs,
  isError,
  handleInputChange,
  buttonHandlers,
}) => {
  return (
    <Fragment>
      <div className='p-grid'>
        <div className='p-col-7'>
          <div className='p-fluid p-grid p-formgrid'>
            <InputFieldText
              variables={fieldInputs.quoteName}
              constants={detailInputs.quoteName}
              handler={handleInputChange}
            />
            <InputFieldPercentage
              variables={fieldInputs.irrPercent}
              constants={detailInputs.irrPercent}
              handler={handleInputChange}
            />
          </div>
        </div>
        <div className='p-col-5'>
          <div className='p-shadow-12'>
            <div className='p-fluid p-grid p-formgrid'>
              <NotificationFieldText
                loaded={quote.isProposalLoaded}
                edit={quote.isProposalEdit}
                isError={isError}
              />
            </div>
          </div>
        </div>

        <div className='p-col-7'>
          <div className='p-fluid p-grid p-formgrid'>
            <InputFieldDropdown2
              variables={fieldInputs.brokerName}
              constants={detailInputs.brokerName}
              handler={handleInputChange}
              options={broker.brokers}
            />
            <InputFieldMultiselect
              variables={fieldInputs.lifeCompany}
              constants={detailInputs.lifeCompany}
              handler={handleInputChange}
              options={lifeCompanies}
            />

            <InputFieldDropdown
              variables={fieldInputs.claimantsName}
              constants={detailInputs.claimantsName}
              handler={handleInputChange}
              options={claimant.claimants}
            />
          </div>
        </div>
        <div className='p-col-5'>
          <div className='p-fluid p-grid p-formgrid'>
            <InputFieldDate
              variables={fieldInputs.quoteDate}
              constants={detailInputs.quoteDate}
              handler={handleInputChange}
            />
            <InputFieldDate
              variables={fieldInputs.expirationDate}
              constants={detailInputs.expirationDate}
              handler={handleInputChange}
            />
            <InputFieldDate
              variables={fieldInputs.purchaseDate}
              constants={detailInputs.purchaseDate}
              handler={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className='p-grid'>
        <div className='p-col-4'>
          <div className='p-fluid p-grid p-form-grid'>
            <InputFieldCheckbox
              variables={fieldInputs.showTotal}
              constants={detailInputs.showTotal}
              handler={handleInputChange}
            />
            <InputFieldCheckbox
              variables={fieldInputs.showPremium}
              constants={detailInputs.showPremium}
              handler={handleInputChange}
            />
            <InputFieldCheckbox
              variables={fieldInputs.showPlots}
              constants={detailInputs.showPlots}
              handler={handleInputChange}
            />
          </div>
        </div>
        <div className='p-col-8'>
          <div className='p-fluid p-grid p-form-grid'>
            <InputFieldTextarea
              variables={fieldInputs.proposalNotes}
              constants={detailInputs.proposalNotes}
              handler={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className='p-d-flex p-jc-evenly'>
        <ThreeStateButtons
          isQuoteEdit={quote.isProposalEdit}
          isQuoteLoaded={quote.isProposalLoaded}
          addButtons={detailAddButtons}
          handlers={buttonHandlers}
        />
      </div>
    </Fragment>
  );
};

export default QuoteDetailsView;
