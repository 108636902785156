import React from "react";
import { connect } from "react-redux";
import Setup from "./setup/setup";
import Results from "./results/results";
import Unsubscribe from "../unsubscribe/unsubscribe.component";

const SettlementAnalyzer = ({ analyzer }) => {
  return (
    <>
      <Setup />
      {analyzer.results.length > 0 && <Results />}
      <Unsubscribe />
    </>
  );
};

const mapStateToProps = (state) => ({
  analyzer: state.analyzer,
});
export default connect(mapStateToProps, null)(SettlementAnalyzer);
