import React, { Fragment } from "react";
import { Text, View } from "@react-pdf/renderer";
import TextItem from "./text-item.view";
import TextDraws from "./text-draws.view";
import styles from "../pdf-analyzer.styles";
import {
  formatCurrency,
  addPercentSign,
} from "../../../../../utilities/numbers";
import { displayedFilingStatusWithMarried } from "../../../../../utilities/misc";

const PdfConditions = ({
  conditions,
  coordinates: { top, left, width, height },
}) => {
  return (
    <Fragment>
      <View
        style={[
          styles.tinted,
          styles.rounded_border,
          styles.absolute,
          { top: top },
          { left: left },
          { width: width },
          { height: height },
        ]}
      >
        <Text style={[styles.fontm, styles.mrow, styles.bold, styles.center]}>
          Conditions
        </Text>
        <View style={[styles.flex_row_sb, styles.mrow]}>
          <TextItem
            label='Start Year'
            value={conditions.startYear}
            width={width / 2}
          />
          <TextItem
            label='End Year'
            value={conditions.endYear}
            width={width / 2}
          />
        </View>
        <View style={[styles.flex_row_sb, styles.mrow]}>
          <TextItem
            label='Settlement'
            value={formatCurrency(conditions.settlement)}
            width={width / 2}
          />
          <TextItem
            label='Start Balance'
            value={formatCurrency(conditions.startBalance)}
            width={width / 2}
          />
        </View>
        <View style={[styles.flex_row_sb, styles.mrow]}>
          <TextItem
            label='% Bonds'
            value={
              conditions.gainCalculationType.startsWith("sp500_vbtix_60")
                ? addPercentSign(40)
                : addPercentSign(conditions.percentBonds)
            }
            width={width / 2}
          />
          <TextItem
            label='Bond Rate'
            value={
              conditions.gainCalculationType === "sp500_adjusted"
                ? addPercentSign(conditions.bondRate)
                : conditions.bondRate
            }
            width={width / 2}
          />
        </View>
        <View style={[styles.flex_row_sb, styles.mrow]}>
          <TextItem
            label='Filing Status'
            value={displayedFilingStatusWithMarried(
              conditions.filingStatus,
              conditions.yearMarried
            )}
            width={width / 2}
          />
          <TextItem
            label='Filing State'
            value={conditions.filingState}
            width={width / 2}
          />
        </View>
        <View style={[styles.flex_row_sb, styles.mrow]}>
          <TextItem
            label='Fees'
            value={addPercentSign(conditions.fees)}
            width={width / 2}
          />
          <TextItem
            label='# of Runs'
            value={conditions.runs}
            width={width / 2}
          />
        </View>

        <TextDraws
          draws={conditions.allDraws}
          incomes={conditions.allIncomes}
          blendeds={conditions.allBlendeds}
          width={width}
        />
      </View>
    </Fragment>
  );
};

export default PdfConditions;
