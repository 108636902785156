import React from "react";
import NumberInputOffset from "../../../../lib/number-input-offset";
import CheckboxInputGroup from "../../../../lib/checkbox-input-group";
import CheckboxInputNoGroup from "../../../../lib/checkbox-input-no-group";
import { calculatorParams } from "../setup-params";

const TaxFilingView = ({ handler, taxFilingParameters }) => {
  const { taxFiling, singleToMarried, marriedYear } = taxFilingParameters;
  return (
    <div className='p-field border-secondary-1 p-ml-3 br-6'>
      <div className='p-text-center p-py-2 big-bold-label'>Filing Status</div>
      <div className='p-grid p-p-3'>
        <CheckboxInputGroup
          id='federalSingle'
          label='Federal Single'
          value={taxFiling}
          handler={handler}
        />
        <CheckboxInputGroup
          id='federalMarried'
          label='Federal Married'
          value={taxFiling}
          handler={handler}
        />
        <CheckboxInputGroup
          id='ltcgSingle'
          label='LTCG Single'
          value={taxFiling}
          handler={handler}
        />
        <CheckboxInputGroup
          id='ltcgMarried'
          label='LTCG Married'
          value={taxFiling}
          handler={handler}
        />
        <CheckboxInputNoGroup
          id='singleToMarried'
          label='Single to Married'
          value={singleToMarried}
          handler={handler}
          isDisabled={taxFiling.includes("Married")}
        />
        <NumberInputOffset
          constants={calculatorParams.marriedYear}
          value={marriedYear}
          handler={handler}
          isDisabled={!singleToMarried}
        />
      </div>
    </div>
  );
};
export default TaxFilingView;
